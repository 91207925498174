import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

import getLangs from "src/utils/languages";
import genFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";
import { ICompanyRecord, IMonalytUser } from "src/models/general_models";
import { IInfluencer } from "src/models/monalytics_models";

const languages = getLangs();
const appFxs = genFxs();

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PrintListOfInfluencers = (
  _connUser: IMonalytUser,
  _connComp: ICompanyRecord,
  _list: IInfluencer[]
) => {
  const docDefininitions = {
    content: [
      {
        text: "Tabular Report",
        style: "header",
      },
      {
        text: "Generated on: " + new Date().toLocaleDateString(),
        style: "subheader",
      },
      {
        style: "tableExample",
        table: {
          headerRows: 1,
          widths: ["40%", "30%", "30%",],
          body: [
            [
                languages.name, 
                languages.email, 
                {
                    text: languages.phoneNbr,
                    alignment: 'right',
                },
            ],
            ..._list.map(o => ([`${o.firstName} ${o.lastName}`, o.inflEmail, {text: o.mobilePhone ?? '', alignment:'right'}])),
          ],
        },
        layout: "lightHorizontalLines",
      },
    ],
    defaultStyle: {
        fontSize: 10
    },
    footer: (currentPage: number, pageCount: number) => {
        return [
            {text:'Account Nbr: 121454215454', alignment: 'center', bold: true},
            {
                text: `Page ${currentPage}/${pageCount}`,
                alignment: 'right',
                margin: [0, 8, 8, 0],
                fontSize: 10
            }
        ]
    },
    header: (currentPage: number, pageCount: number) => {
        return [
            {
                margin: [40, 20, 8, 0],
                columns: [
                {width: '50%', text: 'here lies alll'},
                {width: '50%', table: {
                    body: [
                        {text: _connComp.fullAddress},
                    ],
                }}

            ]}
        ]
    } 
    
  };

  //pdfMake.createPdf(docDefininitions).download('list_influencers.pdf');
};

const PrintingSystem = {
  PrintListOfInfluencers,
};

export default PrintingSystem;
