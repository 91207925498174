import React, { useMemo, useState } from 'react';

import { Button, Heading } from '@radix-ui/themes';

import getLang from 'src/utils/languages';
import generalFxs from 'src/utils/general_fx';

import { IFormError } from 'src/models/smarttypes';
import WiseTextInput from 'src/components/WiseTextInput';
import { Link, useNavigate } from 'react-router-dom';

import { LinkRouter, SeparatorWithText } from 'src/components/small_comps';
import ButtonLinkWithIcon from 'src/components/ButtonLinkWithIcon';
import NetServices from 'src/utils/netservices';
import { ICompanyRecord, IMonalytUser } from 'src/models/general_models';
import appDeclarations from 'src/utils/declarations';
import CompaniesLstVign from 'src/components/CompaniesLstVign';

const languages = getLang();
const appFxs = generalFxs();

const AcctLoginPage = () => {
  document.title = `${languages.login} | StockMan`;
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [loginStep, setLoginStep] = useState(0);
  const [errorForm, setErrorForm] = useState<IFormError>({});
  const [serverInUse, setServerInUse] = useState(false);
  const [lstDomains, setLstDomains] = useState<ICompanyRecord[]>([]);


  const isEmailOk = useMemo(() => {
    return appFxs.isEmailValid(formData.email);
  }, [formData.email]);

  const sendLoginPassword = async () => {
    const errors: IFormError = {};

    if (!formData.email || formData.email.length === 0) {
      errors.email = languages.fldRequired;
    } else if (!appFxs.isEmailValid(formData.email)) {
      errors.email = languages.emailFormat;
    }

    if (!formData.password || formData.password.length === 0) {
      errors.password = languages.fldRequired;
    }

    setErrorForm(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);
    const oOper = await NetServices.requestPost("account/loginwithemailpw", {
      userEmail: formData.email,
      userPassword: formData.password,
    });

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setServerInUse(false);
      return;
    }

    if (oOper.typeReturn === 1) {
      onLoginToApp(
        oOper.accessKey!,
        oOper.connectedUser!,
        oOper.connectedCompany!
      );
    } else {
      setLstDomains(oOper.userCompanies!);
      setLoginStep(2);
    }
    setServerInUse(false);
  };

  const onLoginToApp = (
    accessKey: string,
    connectedUser: IMonalytUser,
    connectedCompany: ICompanyRecord
  ) => {
    appFxs.setLocalStorageByKey(appDeclarations.userAccessKey, accessKey);
    appFxs.setLocalStorageByKey(appDeclarations.connectionStatus, "1");

    appFxs.setLocalStorageByKey(
      appDeclarations.connectedUser,
      JSON.stringify(connectedUser)
    );

    appFxs.setLocalStorageByKey(
      appDeclarations.connectedCompany,
      JSON.stringify(connectedCompany)
    );

    navigate("/app");
  };

  const onSelectedDomain = async (e: ICompanyRecord) => {
    setServerInUse(true);
    const oOper = await NetServices.requestPost(
      "account/loginwithemailpwdomain",
      {
        userEmail: formData.email,
        userPassword: formData.password,
        clientDomainKey: e.companyKey,
      }
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setServerInUse(false);
      return;
    }

    onLoginToApp(
      oOper.accessKey!,
      oOper.connectedUser!,
      oOper.connectedCompany!
    );
    setServerInUse(false);
  };



  return (
    <div className='w-full max-w-md shadow-lg py-3 px-5 bg-white rounded-md'>
      <div className='w-full flex flex-col space-y-6'>
        <Heading className='text-center text-base' size="1" >{languages.signIn}</Heading>
        {loginStep === 0 && <div>
          <div className='mb-6'>
            <WiseTextInput fldName='email'
              fldType='email'
              placeholder={languages.email}
              errorText={errorForm.email}
              value={formData.email}
              onChange={(f, v) => setFormData({ ...formData, [f]: v })}
              graphicType='email'
            />
          </div>

          <div>
            <WiseTextInput fldName='password'
              fldType='password'
              placeholder={languages.password}
              errorText={errorForm.password}
              value={formData.password}
              onChange={(f, v) => setFormData({ ...formData, [f]: v })}
              graphicType='key'
            />
          </div>
        </div>}
        {loginStep === 2 && (
          <div className="relative">
            {serverInUse && (
              <div className="absolute top-0 left-0 w-full h-full bg-black/30 z-10"></div>
            )}
            {serverInUse && (
              <div className="absolute top-0 left-0 w-full h-full bg-black/30 z-10 flex justify-center items-center">
                <i className="fa fa-spinner fa-spin fa-3x text-white"></i>
              </div>
            )}
            <CompaniesLstVign
              companies={lstDomains}
              onSelected={(e) => onSelectedDomain(e)}
              onGoBack={() => setLoginStep(0)}
            />
          </div>
        )}

        {loginStep === 0 && <div className='flex flex-col space-y-6'>
          {isEmailOk && <div className='text-center'>
            <Link to={`/account/forgotpw/${window.btoa(formData.email)}`}
              className='text-blue-600 hover:text-blue-800 font-semibold text-sm'>
              {languages.forgotPassWord}
            </Link>
          </div>}
          <Button size="3" 
            variant="soft" 
            onClick={() => sendLoginPassword()} 
            className='cursor-pointer w-full flex items-center'
            disabled={serverInUse}>
            {serverInUse && <i className='fa fa-spin fa-spinner'></i>}
            <span className='text-sm font-semibold'>{languages.login}</span>
          </Button>
          <div className='text-center text-sm'>
            <span>{languages.dtHaveAcct}&nbsp;<LinkRouter href='/account/register' label={languages.register} /></span>
          </div>
          <SeparatorWithText label={languages.or} />
          <div className='w-full'>
            <ButtonLinkWithIcon buttonType='facebook' isFull={true} onClick={() => { }} />
          </div>
          <div className='w-full pb-7'>
            <ButtonLinkWithIcon buttonType='google' isFull={true} onClick={() => { }} />
          </div></div>}
      </div>
    </div>
  )
}

export default AcctLoginPage