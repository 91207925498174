/* eslint-disable react-hooks/exhaustive-deps */

import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from "react";

import geLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import {
  CalendarViewerComp,
  ErrorMsgDisplayer,
  NoRecordMsgDisplayer,
  WiseCircularActionBtn,
} from "../small_comps";
import {
  IMonalytCampaignInfl,
  IMonalytInflCampEvent,
  IMonalytInflCampSms,
  ISMSSetting,
} from "src/models/monalytics_models";
import NetServices from "src/utils/netservices";
import LoadinInterfaceComp from "../LoadinInterfaceComp";
import { ModalWiseEvent, ModalWiseSMS } from "../modal_hiring_tools";
import { Badge } from "@radix-ui/themes";
import { IHiringActiActionsRef, IHiringCompVals } from "src/models/smarttypes";

const languages = geLang();
const appFxs = generalFxs();

const emptySmsRec: IMonalytInflCampSms = {
  smsOperKey: "",
  hireOperKey: "",
  campaignKey: "",
  companyKey: "",
  influencerKey: "",

  smsSettingName: "",
  smsMessage: "",
  receipientPhoneNbr: "",
  sentStatus: 0,

  msgSentDate: new Date(),
  operDate: new Date(),
  sentBy: "",
};

const ListOfWiseSms = forwardRef((
  props: IHiringCompVals,
  ref: Ref<IHiringActiActionsRef>
) => {
  const [dlgOpen, setDlgOpen] = useState(false);
  const [dlgStatus, setDlgStatus] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const [openReady, setOpenReady] = useState(false);

  const [dialogRecord, setDialogRecord] = useState<IMonalytInflCampSms>({
    ...emptySmsRec,
  });

  const [lstOfWiseSms, setLstOfWiseSms] = useState<IMonalytInflCampSms[]>([]);
  const [lstOfCompSmsSettings, setLstOfCompSmsSettings] = useState<
    ISMSSetting[]
  >([]);
  const [baseHiringOps, setBaseHiringOps] =
    useState<IMonalytCampaignInfl | null>(null);

  const onAddOrPreviewSms = (event?: IMonalytInflCampSms) => {
    const oRec: IMonalytInflCampSms = event
      ? {
          ...event,
          msgSentDate: new Date(event.msgSentDate),
          operDate: new Date(event.operDate),
        }
      : {
          ...emptySmsRec,
          campaignKey: baseHiringOps!.campaignKey,
          companyKey: baseHiringOps!.companyKey,
          hireOperKey: baseHiringOps!.recordKey,
          influencerKey: baseHiringOps!.influencerKey,
          operDate: new Date(),
          msgSentDate: new Date(),
        };
    setDialogRecord(oRec);
    setDlgStatus(event ? 1 : 0);
    setDlgOpen(true);
  };
  const populateData = async () => {
    setLoading(true);
    setMsgError("");
    setHasError(false);

    const oOper = await NetServices.requestGet(
      `hiretools/getlistsms/${props.hiringOperationKey}`
    );

    if (!oOper.bReturn) {
      setMsgError(oOper.msgBody);
      setHasError(true);
      setLoading(false);
      return;
    }
    setLstOfWiseSms(oOper.lstOfWiseSms!);
    setLstOfCompSmsSettings(oOper.lstOfCompSmsSettings!);
    setBaseHiringOps(oOper.baseHiringOps!);
    setMsgError("");
    setHasError(false);
    setLoading(false);
  };

  const onCloseDialog = (o: any) => {
    if (o) {
      const lst = o as IMonalytInflCampSms[];
      setLstOfWiseSms(lst);
    }

    setDlgOpen(false);
  };

  const onSmsDelete = async (k: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "hiretools/deletehiringsmsrecord",
      {
        hireSmsKey: k,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setLstOfWiseSms(oOper.lstOfWiseSms!);
  };

  const onEventPreview = async (k: IMonalytInflCampSms) => {
    onAddOrPreviewSms(k);
  };

  const onOpenDialog = () => {
    if(!baseHiringOps){
      setOpenReady(true);
    }else{
      onAddOrPreviewSms();
    }
  }

  useImperativeHandle(ref, () => ({
    onOpenDialog
  }));

  useEffect(() => {
    populateData();
  }, []);

  useEffect(() => {
    if(openReady){
      onOpenDialog();
    };    
  }, [baseHiringOps]);

  return (
    <>
      <ModalWiseSMS
        isOpen={dlgOpen}
        dataRecord={dialogRecord}
        dlgTitle={languages.addPhoneLog}
        onClose={onCloseDialog}
        dlgStatus={dlgStatus}
        lstAccts={lstOfCompSmsSettings}
      />
      <div className="flex flex-col">
        {/* Header */}
        <div className="flex items-center space-x-2">
          <WiseCircularActionBtn
            type="add"
            onAction={() => onAddOrPreviewSms()}
          />
          <span
            onClick={() => onAddOrPreviewSms()}
            className="text-sm cursor-pointer text-blue-500 hover:text-blue-700"
          >
            {languages.addEvent}
          </span>
        </div>
        {/* Body */}
        <div className="flex flex-col mt-4">
          {loading && <LoadinInterfaceComp />}
          {!loading && !hasError && lstOfWiseSms.length === 0 && (
            <NoRecordMsgDisplayer
              msg={languages.noSmsPosted}
              onAction={() => onAddOrPreviewSms()}
              actionTitle={languages.sendSms}
              btnIcon={<i className="fa fa-send"></i>}
            />
          )}
          {!loading && hasError && (
            <ErrorMsgDisplayer
              msg={msgError}
              onAction={() => populateData()}
              actionTitle={languages.refresh}
            />
          )}
          {!loading &&
            !hasError &&
            lstOfWiseSms.length > 0 &&
            lstOfWiseSms.map((o, idx) => (
              <WiseSmsElt
                key={idx}
                data={o}
                onDelete={(k) => onSmsDelete(k)}
                onPreview={(k) => onEventPreview(k)}
              />
            ))}
        </div>
      </div>
    </>
  );
})

export default ListOfWiseSms;
interface IWiseSmsElt {}
const WiseSmsElt = ({
  data,
  onDelete,
  onPreview,
}: {
  data: IMonalytInflCampSms;
  onDelete: (k: string) => void;
  onPreview: (k: IMonalytInflCampSms) => void;
}) => {
  return (
    <div className="flex items-start mb-3 border-b">
      <div className="mr-3">
        <CalendarViewerComp calDate={new Date(data.operDate)} />
      </div>
      <div className="flex-1 ">
        <div className="tbl-item-text-sub-item">
          <div className="flex items-center space-x-3">
            <div
              onClick={() => onPreview(data)}
              className="data-[status=done]:line-through text-blue-400 font-semibold cursor-pointer"
            >
              {data.smsMessage}
            </div>
            <span
              onClick={() => onDelete(data.smsOperKey)}
              className="cursor-pointer"
              title={languages.delete}
            >
              <i className="fa fa-trash text-red-500"></i>
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between py-3">
          <div className="flex items-center space-x-3">
            <span className="text-sm">
              {appFxs.formatDateTimeAlt(new Date(data.operDate))}
            </span>
          </div>
        </div>
      </div>
      <div>
      <Badge color={data.sentStatus === 1 ? "green":"gray"}>{data.sentStatus === 1 ? languages.sent: languages.notSent}</Badge>
      </div>
    </div>
  );
};
