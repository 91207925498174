import React, { useEffect, useMemo, useState } from "react";
import dateAndTime from "date-and-time";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

import NetServices from "src/utils/netservices";
import { BootstrapInput } from "src/components/WiseModalTextInput";
import { Button, DropdownMenu } from "@radix-ui/themes";
import { CalendarIcon, DropdownMenuIcon } from "@radix-ui/react-icons";
import { IMonalytCampaign } from "src/models/monalytics_models";
import { useNavigate } from "react-router-dom";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";
import logoImg from "src/assets/images/campaign_logo.png";
import fcountries from "src/assets/resources/country_tel_codes.json";
import { ICountry, ICountyWithCtr } from "src/models/smarttypes";

const languages = getLang();
const appFxs = generalFxs();
const lstMonths = appFxs.wdMonthsLng;

const todayDate = new Date();
const todayNextMonth = dateAndTime.addMonths(todayDate, 1);
const countries = fcountries as ICountyWithCtr;

const AnalysisPage = () => {
  const [loading, setLoading] = useState(true);
  const [country, setCountry] = useState("");
  const [searchType, setSearchType] = useState(0);
  const [selectedTag, setSelectedTag] = useState("");
  const [lstCampaigns, setLstCampaigns] = useState<IMonalytCampaign[]>([]);
  const [selectedCamp, setSelctedCamp] = useState<IMonalytCampaign | null>(
    null
  );
  const [startDate, setStartDate] = useState(todayDate);
  const [endDate, setEndDate] = useState(todayNextMonth);

  const navigate = useNavigate();

  const [selectedPeriod, setSelectedPeriod] = useState(
    todayDate.getMonth() + 1
  );
  const monthName = useMemo(() => {
    return lstMonths[selectedPeriod - 1];
  }, [selectedPeriod]);

  const populateData = async () => {
    setLoading(true);

    const oOper = await NetServices.requestGet(
      `monalytics/getlistofcampaignbyperiod/${selectedPeriod}`,
      false
    );

    if (!oOper.bReturn) {
      const bAsk = await appFxs.showAlertConfirm(oOper.msgTitle, oOper.msgBody);
      if (bAsk.isDismissed) {
        navigate("/app/dashboard");
      }
      return;
    }

    setLstCampaigns(oOper.lstCampaigns!);
    setLoading(false);
  };

  const onChangeMonth = (iMonth: number) => {
    setSelectedPeriod(iMonth);
    setSelctedCamp(null);
    setSelectedTag("");
    setCountry("");
  };

  const onChangeSearchType = (iType: number) => {
    setSearchType(iType);
    setSelctedCamp(null);
    setSelectedTag("");
    setCountry(""); 
  };

  const isAnalysisReady = useMemo(() => {
    const bDate = endDate.getTime() >= startDate.getTime();
    return bDate && selectedTag.length > 0;
  }, [selectedTag, startDate, endDate]);

  const countyObject:ICountry | null = useMemo(() => {
    if(!country) return null;
    const ctry = countries.Countries.find(o => o.code === country);
    return ctry ?? null;
  }, [country])

  const startAnalysis = async () => {
    const oOper = await NetServices.requestPost(
      "socialaccess/getsocialfbooktagdata",
      {
        socialDataRec: {
          country,
          endDate,
          startDate,
          tag: selectedTag,
        },
      },
      true
    );

    console.log("oOper", oOper);
  };

  useEffect(() => {
    populateData();
  }, [selectedPeriod]);

  return (
    <div className="flex flex-col">
      <div className="flex items-center flex-wrap space-y-2 space-x-3 px-3 py-2">
        {/* Month */}
        <div className="mt-2">
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button>
                <CalendarIcon /> {monthName} <DropdownMenuIcon />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              {lstMonths.map((o, idx) => (
                <DropdownMenu.Item
                  className="cursor-pointer"
                  onClick={() => onChangeMonth(idx + 1)}
                  key={idx}
                >
                  <span
                    data-active={idx === selectedPeriod - 1}
                    className="data-[active=true]:font-bold"
                  >
                    {o}
                  </span>
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>

        {/* Search type */}
        <div>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button className="w-[120px]" color="amber">
                {searchType === 0 ? languages.campaign : languages.hashtag}
                &nbsp;
                <i className="fa fa-chevron-down text-xs"></i>
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              <DropdownMenu.Item
                className="cursor-pointer"
                onClick={() => onChangeSearchType(0)}
              >
                <div className="flex items-center space-x-2">
                  <i className="fa fa-image"></i>
                  <span>{languages.byCampaign}</span>
                </div>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className="cursor-pointer"
                onClick={() => onChangeSearchType(1)}
              >
                <div className="flex items-center space-x-2">
                  <i className="fa fa-tags"></i>
                  <span>{languages.byHashtag}</span>
                </div>
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>

        {/* Hashtag field */}
        {searchType === 1 && (
          <div>
            <BootstrapInput
              fieldName="selectedTag"
              fldType="text"
              label=""
              value={selectedTag}
              placeholder={languages.hashtag}
              onChange={(f, v) => setSelectedTag(String(v))}
            />
          </div>
        )}

        {/* Campaigns Hashtag field */}
        {searchType === 0 && (
          <div>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button className="min-w-[120px]" color="cyan">
                  {!selectedCamp && (
                    <div className="flex items-center">
                      {languages.noSelection}
                    </div>
                  )}
                  {selectedCamp && (
                    <div className="flex items-center space-x-3">
                      <img
                        className="inline-block w-6 h-6 rounded-full"
                        alt=""
                        src={
                          selectedCamp.campaignLogo
                            ? `${appDecl.campaignRes}/${
                                selectedCamp!.companyKey
                              }/${selectedCamp!.campaignLogo}`
                            : logoImg
                        }
                      />
                      <span>
                        {appFxs.shortenText(selectedCamp.title, 15, true)}
                      </span>
                    </div>
                  )}
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {lstCampaigns.map((o, idx) => (
                  <DropdownMenu.Item
                    className="cursor-pointer"
                    onClick={() => setSelctedCamp(o)}
                    key={idx}
                  >
                    <div className="flex items-center space-x-2">
                      <img
                        className="inline-block w-5 h-5 rounded-full"
                        alt=""
                        src={
                          o.campaignLogo
                            ? `${appDecl.campaignRes}/${o!.companyKey}/${
                                o!.campaignLogo
                              }`
                            : logoImg
                        }
                      />
                      <span>{o.title}</span>
                    </div>
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </div>
        )}

        {/* Tags from campaign */}
        {searchType === 0 && selectedCamp && (
          <div>
            <DropdownMenu.Root>
              <DropdownMenu.Trigger>
                <Button className="min-w-[120px]" color="cyan">
                  {!selectedTag && (
                    <div className="flex items-center">{languages.noTag}</div>
                  )}
                  {selectedTag && (
                    <div className="flex items-center space-x-1">
                      <span>#{selectedTag}</span>
                    </div>
                  )}
                </Button>
              </DropdownMenu.Trigger>
              <DropdownMenu.Content>
                {selectedCamp.hashtag.map((o, idx) => (
                  <DropdownMenu.Item
                    className="cursor-pointer"
                    onClick={() => setSelectedTag(o)}
                    key={idx}
                  >
                    <div className="flex items-center">
                      <span>#{o}</span>
                    </div>
                  </DropdownMenu.Item>
                ))}
                {selectedTag && (
                  <DropdownMenu.Item
                    className="cursor-pointer bg-blue-500 text-white font-semibold"
                    onClick={() => setSelectedTag("")}
                  >
                    <div className="flex items-center">
                      <span>{languages.reset}</span>
                    </div>
                  </DropdownMenu.Item>
                )}
              </DropdownMenu.Content>
            </DropdownMenu.Root>
          </div>
        )}


        {/* countries */}
        <div>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button className="min-w-[120px]" color="cyan">
                {!countyObject && (
                  <div className="flex items-center">{languages.noCountry}</div>
                )}
                {countyObject && (
                  <div className="flex items-center space-x-1">
                    <i className="fa fa-flag"></i> <span>{countyObject.name}</span>
                  </div>
                )}
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              {countries.Countries.map((o, idx) => (
                <DropdownMenu.Item
                  className="cursor-pointer"
                  onClick={() => setCountry(o.code)}
                  key={idx}
                >
                  <div className="flex items-center">
                    <span>{o.name}</span>
                  </div>
                </DropdownMenu.Item>
              ))}
              {selectedTag && (
                <DropdownMenu.Item
                  className="cursor-pointer bg-blue-500 text-white font-semibold"
                  onClick={() => setCountry("")}
                >
                  <div className="flex items-center">
                    <span>{languages.reset}</span>
                  </div>
                </DropdownMenu.Item>
              )}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>

        {/* Start date */}
        <div>
          <BootstrapInput
            fieldName="startDate"
            value={appFxs.getDateForInput(startDate)}
            fldType="date"
            label=""
            onChange={(f, v) => setStartDate(new Date(v))}
          />
        </div>

        {/* End date */}
        <div>
          <BootstrapInput
            fieldName="endDate"
            value={appFxs.getDateForInput(endDate)}
            fldType="date"
            label=""
            onChange={(f, v) => setEndDate(new Date(v))}
          />
        </div>

        <div>
          <Button onClick={() => startAnalysis()} disabled={!isAnalysisReady}>
            {languages.launchAnalys}
          </Button>
        </div>
      </div>
      <div>{loading && <LoadinInterfaceComp />}</div>
    </div>
  );
};

export default AnalysisPage;
