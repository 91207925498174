/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';

import generalFxs from 'src/utils/general_fx';
import appLanguages from 'src/utils/languages';
import NetServices from 'src/utils/netservices';
import WiseTextInput from 'src/components/WiseTextInput';
import { IFormError } from 'src/models/smarttypes';
import { Button, Heading } from '@radix-ui/themes';

const appFxs = generalFxs();
const languages = appLanguages();

interface IFormRecord {
  password: string,
  confPassword: string,
}

const AcctResetPwPage = () => {
  document.title = `${languages.setNewPw} | Archivix`;
  const { resetKey } = useParams();
  const navigate = useNavigate();

  const [formData, setFormData] = useState<IFormRecord>({
    confPassword: '',
    password: ''
  });
  const [formErrors, setFormErrors] = useState<IFormError>({});
  const [serverInUse, setServerInUse] = useState(false);

  const onChange = (fldName: string, fldValue: string) => {
    setFormData({ ...formData, [fldName]: fldValue });
  }
  const onResetPassword = async () => {
    const errors: IFormError = {}
    if (!formData.password || formData.password.length === 0) {
      errors.password = languages.fldRequired;
    } else if (!appFxs.isPasswordGood(formData.password)) {
      errors.password = languages.passwordErrorSec;
    }

    if (!formData.confPassword || formData.confPassword.length === 0) {
      errors.confirmPassword = languages.fldRequired;
    } else if (formData.confPassword !== formData.password) {
      errors.confirmPassword = languages.confirmPaswordError;
    }

    console.log('errors', errors)

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);
    const oOper = await NetServices.requestPost('account/finalresetpassword', {
      resetPasswordKey: resetKey,
      password: formData.password,
      confirmPassword: formData.confPassword,
    });

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setServerInUse(false);
      return;
    }


    setServerInUse(false);

    await appFxs.showAlert(languages.resetPassword, languages.resetPasswordSuccessMsg);

    navigate('/account/login');
  }



  return (
    <div className='w-full max-w-md shadow-lg py-3 px-5 bg-white rounded-md'>
      <div className='w-full flex flex-col space-y-6'>
        <Heading className='text-center text-base' size="1" >{languages.setNewPassword}</Heading>
        <p className="text-sm">{languages.resetNewPwMsg}</p>

        <WiseTextInput
          fldName="password"
          fldType="password"
          label={languages.password}
          placeholder={languages.newPassword.toLowerCase()}
          graphicType="lock"
          value={formData.password}
          onChange={onChange}
          errorText={formErrors.password}
          disabled={serverInUse}
          checkPassword={true}
        />

        <WiseTextInput
          fldName="confPassword"
          fldType="password"
          label={languages.confirmPassword}
          placeholder={languages.confirmPassword.toLowerCase()}
          graphicType="lock"
          value={formData.confPassword}
          onChange={onChange}
          errorText={formErrors.confirmPassword}
          disabled={serverInUse}
        />

        <div className="mt-6">
          <Button size="3" variant="soft"
            onClick={() => onResetPassword()}
            className='cursor-pointer w-full flex items-center'
            disabled={serverInUse}>
            <span className="flex flex-row items-center gap-2">
              {serverInUse && <i className="fa fa-spin fa-spinner"></i>}
              <span>
                {serverInUse && <span>{`${languages.loading}...`}</span>}
                {!serverInUse && <span>{languages.reset}</span>}
              </span>
            </span>
          </Button>
        </div>

        <div className="flex justify-end items-center">
          <Link
            className="text-blue-600 hover:text-blue-800"
            to={"/account/login"}
          >
            {languages.signInAnyway}
          </Link>
        </div>

      </div>
    </div>
  )
}

export default AcctResetPwPage