import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import wiseDate from "date-and-time";
import { Button, Switch } from "@radix-ui/themes";

import getLang from "src/utils/languages";
import getFxs from "src/utils/general_fx";
import NetServices from "src/utils/netservices";

import { IMonalytCampaign } from "src/models/monalytics_models";
import { IFormError } from "src/models/smarttypes";
import WiseModalTextInput, {
  ChannelsTargetField,
} from "src/components/WiseModalTextInput";
import WiseTagsInput from "src/components/WiseTagsInput";

import logoImg from "src/assets/images/campaign_logo.png";
import wallImg from "src/assets/images/wall_image.jpg";

const appFxs = getFxs();
const languages = getLang();
const todayDate = new Date();

const defaultRec: IMonalytCampaign = {
  campaignKey: "",
  companyKey: "",
  active: true,

  postedDate: todayDate,
  fiscalYear: todayDate.getFullYear(),
  fiscalPeriod: todayDate.getMonth() + 1,
  title: "",
  hashtag: [],
  startDate: todayDate,
  endDate: wiseDate.addDays(todayDate, 30),
  description: "",
  campaignLogo: "",
  campaignWall: "",
  influencerChannels: [],
  createdBy: "",
  budget: 0,
  canAmountAbove: false,
};

const CampaignNewPage = () => {
  document.title = `${languages.newCampaign} - ${languages.appName}`;

  const navigate = useNavigate();

  const [dataForm, setDataForm] = useState<IMonalytCampaign>({ ...defaultRec });
  const [formErrors, setFormErrors] = useState<IFormError>({});
  const [saving, setSaving] = useState<boolean>(false);

  const [imageWallFile, setImageWallFile] = useState<File | null>(null);
  const [doctImageWall, setDoctImageWall] = useState<string>(wallImg);

  const [imageLogoFile, setImageLogoFile] = useState<File | null>(null);
  const [doctImageLogo, setDoctImageLogo] = useState<string>(logoImg);

  const { periodMonth } = useParams();
  const fileWallSelect = useRef<HTMLInputElement>(null);
  const fileLogoSelect = useRef<HTMLInputElement>(null);

  const onChange = (f: string, v: string | number) => {
    if (f === "startDate" || f === "endDate") {
      const d = new Date(v);
      setDataForm({ ...dataForm, [f]: d });
    } else {
      setDataForm({ ...dataForm, [f]: v });
    }
  };

  const onTagsChange = (f: string, v: string[]) => {
    setDataForm({ ...dataForm, [f]: v });
  };

  const onChannelChanged = (f: string, v: string) => {
    const vValues = [...dataForm.influencerChannels];
    const fValues = vValues.includes(v)
      ? vValues.filter((o) => o !== v)
      : [...vValues, v];

    setDataForm({ ...dataForm, [f]: fValues });
  };

  const onImageWallChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files![0]!;
    const reader = new FileReader();
    setImageWallFile(file);

    reader.onload = () => {
      setDoctImageWall(reader.result as string);
    };

    reader.readAsDataURL(file);
  };
  const onImageLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files![0]!;
    const reader = new FileReader();
    setImageLogoFile(file);

    reader.onload = () => {
      setDoctImageLogo(reader.result as string);
    };

    reader.readAsDataURL(file);
  };
  const onRemoveWallImg = () => {
    setImageWallFile(null);
    setDoctImageWall(wallImg);
    fileWallSelect!.current!.value = "";
  };

  const onRemoveLogoImg = () => {
    setImageLogoFile(null);
    setDoctImageLogo(logoImg);
    fileLogoSelect!.current!.value = "";
  };

  const startCampaignRecord = async () => {
    const errors: IFormError = {};

    if (!dataForm.title || dataForm.title.length === 0) {
      errors.title = languages.fldRequired;
    }
    if (!dataForm.description || dataForm.description.length === 0) {
      errors.description = languages.fldRequired;
    }
    if (dataForm.hashtag.length === 0) {
      errors.hashtag = languages.fldRequired;
    }
    if (dataForm.influencerChannels.length === 0) {
      errors.channels = languages.fldRequired;
    }

    if (!imageLogoFile) {
      errors.logo = languages.campaignImgError;
    }

    const dStart = dataForm.startDate.getTime(),
      dEnd = dataForm.endDate.getTime();

    if (dEnd < dStart) {
      errors.end = languages.dateFldConfigError;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    dataForm.fiscalPeriod = Number(window.atob(periodMonth!));

    const routePath = !imageLogoFile
      ? "monalytics/savecampaignrecordwithfile"
      : "monalytics/savecampaignrecordwithfile";
    const oDataform = new FormData();

    const profileData = JSON.stringify(dataForm);

    oDataform.append("compaignRecord", profileData);
    if (imageLogoFile) {
      oDataform.append("campaignLogo", imageLogoFile);
    }

    if (imageWallFile) {
      oDataform.append("campaignWall", imageWallFile);
    }

    setSaving(true);

    const saveOper = await NetServices.requestPostWithFileAxios(
      routePath,
      oDataform,
      false
    );

    if (!saveOper.bReturn) {
      appFxs.showAlert(saveOper.msgTitle, saveOper.msgBody);
      setSaving(false);
      return;
    }

    navigate("/app/campaign");
  };

  return (
    <div className="p-4">
      <div className="w-full max-w-3xl mx-auto border shadow p-4">
        <input
          type="file"
          className="hidden"
          ref={fileWallSelect}
          onChange={onImageWallChange}
          accept="image/*"
        />
        <input
          type="file"
          className="hidden"
          ref={fileLogoSelect}
          onChange={onImageLogoChange}
          accept="image/*"
        />

        <div className="flex flex-col">
          <div className="flex items-center justify-between mb-4">
            <h3 className="w-med-heading">{languages.newCampaign}</h3>
            <button type="button" className="btn-wise">
              <i className="fa fa-save"></i>
            </button>
          </div>

          <div className="flex flex-col">
            <div className="flex flex-col">
              <div className="w-full h-[280px] md:w-[450px] mx-auto border rounded flex items-center justify-center relative">
                <img
                  className="z-[1] w-full h-full absolute object-cover"
                  alt=""
                  src={doctImageWall}
                />
                {imageWallFile && (
                  <Button
                    color="red"
                    className="rounded-md top-2 right-2 z-10 absolute"
                    title={languages.removeWall}
                    onClick={onRemoveWallImg}
                  >
                    <i className="fa fa-trash"></i>
                  </Button>
                )}
                <div className="shadow-lg border-8 rounded-full overflow-hidden w-[150px] h-[150px] z-10 bg-white relative">
                  {imageLogoFile && (
                    <Button
                      color="red"
                      className="rounded-md top-4 right-8 z-10 absolute"
                      title={languages.removeLogo}
                      onClick={onRemoveLogoImg}
                    >
                      <i className="fa fa-trash"></i>
                    </Button>
                  )}
                  <img
                    className="z-[1] w-full h-full absolute object-cover"
                    alt=""
                    src={doctImageLogo}
                  />
                </div>
              </div>
              {formErrors.logo && (
                <span className="text-red-500 text-sm">{formErrors.logo}</span>
              )}

              <div className="mt-5 py-2 flex items-center justify-end space-x-3">
                {!imageLogoFile && (
                  <Button
                    title={languages.uploadLogo}
                    onClick={() => fileLogoSelect.current?.click()}
                  >
                    <i className="fa fa-image"></i> {languages.logo}
                  </Button>
                )}

                {!imageWallFile && (
                  <Button
                    color="blue"
                    title={languages.uploadWall}
                    onClick={() => fileWallSelect.current?.click()}
                  >
                    <i className="fa fa-image"></i> {languages.wall}
                  </Button>
                )}
              </div>
            </div>

            <WiseModalTextInput
              fieldName="title"
              value={dataForm.title}
              label={languages.title}
              fldType="text"
              placeholder={languages.title.toLowerCase()}
              errorText={formErrors.title}
              maxLength={150}
              graphicType="file"
              onChange={onChange}
              disabled={saving}
              showLabel={true}
            />
            <div className="flex items-center justify-between flex-wrap mb-2">
              <div className="w-full md:w-[49%]">
                <WiseModalTextInput
                  fieldName="budget"
                  value={dataForm.budget}
                  label={languages.budget}
                  fldType="text"
                  placeholder={languages.budget.toLowerCase()}
                  errorText={formErrors.budget}
                  graphicType="cash"
                  onChange={onChange}
                  disabled={saving}
                  showLabel={true}
                  isNumeric={true}
                />
              </div>
              <div className="w-full md:w-[49%]">
                <div className="rounded-md py-3 px-2 border flex items-center justify-between md:justify-end space-x-2">
                  <span>{languages.exceedBudget}</span>
                  <Switch checked={dataForm.canAmountAbove} onCheckedChange={b => setDataForm({ ...dataForm, "canAmountAbove": b })} />
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between flex-wrap">
              <div className="w-full md:w-[49%]">
                <WiseModalTextInput
                  fieldName="startDate"
                  value={appFxs.getDateForInput(dataForm.startDate)}
                  label={languages.start}
                  fldType="date"
                  placeholder={languages.start.toLowerCase()}
                  errorText={formErrors.start}
                  graphicType="calendar"
                  onChange={onChange}
                  disabled={saving}
                  dateValue={dataForm.startDate}
                  showLabel={true}
                />
              </div>
              <div className="w-full md:w-[49%]">
                <WiseModalTextInput
                  fieldName="endDate"
                  value={appFxs.getDateForInput(dataForm.endDate)}
                  label={languages.end}
                  fldType="date"
                  placeholder={languages.end.toLowerCase()}
                  errorText={formErrors.end}
                  graphicType="calendar"
                  onChange={onChange}
                  disabled={saving}
                  showLabel={true}
                />
              </div>
            </div>

            <WiseTagsInput
              fieldName="hashtag"
              label={languages.hashtag}
              errorText={formErrors.hashtag}
              value={dataForm.hashtag ?? []}
              disabled={saving}
              placeholder={languages.hashtag}
              showIcon={true}
              onChange={onTagsChange}
              spaceNotAllowed={true}
              showLabel={true}
            />

            <WiseModalTextInput
              fieldName="description"
              value={dataForm.description}
              label={languages.description}
              fldType="textarea"
              placeholder={languages.description.toLowerCase()}
              errorText={formErrors.description}
              graphicType="notes"
              onChange={onChange}
              disabled={saving}
              showLabel={true}
              maxLength={1500}
            />
            <ChannelsTargetField
              fieldName="influencerChannels"
              values={dataForm.influencerChannels}
              onChanged={onChannelChanged}
              errorText={formErrors.channels}
            />

            <div className="flex items-center justify-end mt-6">
              <Button
                size="2"
                onClick={() => startCampaignRecord()}
                disabled={saving}
              >
                <i
                  className={`fa ${saving ? "fa-spinner fa-spin" : "fa-save"}`}
                ></i>{" "}
                {saving ? `${languages.saving}...` : languages.startCampaign}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignNewPage;
