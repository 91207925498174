import { ISMSSetting } from "src/models/monalytics_models";
import { WiseModal } from "./modal_comps";
import { useEffect, useState } from "react";
import { BootstrapInput } from "./WiseModalTextInput";

import getLanguages from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import { IFormError } from "src/models/smarttypes";
import { WiseSmallButton } from "./ButtonLinkWithIcon";
import NetServices from "src/utils/netservices";

const languages = getLanguages();
const appFxs = generalFxs();

interface IModalSmsSetting {
  isOpen: boolean;
  onClose: (retData: any) => void;
  dlgTitle: string;
  dlgStatus: number;
  recordData: ISMSSetting;
}

export const ModalSmsSetting = ({
  isOpen,
  onClose,
  dlgTitle,
  recordData,
  dlgStatus,
}: IModalSmsSetting) => {
  const [dataForm, setDataForm] = useState({ ...recordData });
  const [saving, setSaving] = useState(false);
  const [formErrors, setFormErrors] = useState<IFormError>({});

  const onChange = (f: string, v: string | number) => {
    setDataForm({ ...dataForm, [f]: v });
  };
  const onSaveSettings = async () =>  {
    const errors:IFormError = {}

    if(!dataForm.accountName || dataForm.accountName.length === 0){
        errors.accountName = languages.fldRequired;
    }
    if(!dataForm.accountPassword || dataForm.accountPassword.length === 0){
        errors.accountPassword = languages.fldRequired;
    }
    if(!dataForm.accountPhoneNumber || dataForm.accountPhoneNumber.length === 0){
        errors.accountPhoneNumber = languages.fldRequired;
    }else if(dataForm.accountPhoneNumber.length < 12){
        errors.accountPhoneNumber = languages.phonenbrLengthMsg;
    }
    if(!dataForm.accountDisplayName || dataForm.accountDisplayName.length === 0){
        errors.accountDisplayName = languages.fldRequired;
    }

    setFormErrors(errors);
    if(Object.keys(errors).length > 0) return;

    setSaving(true);
    const oOper = await NetServices.requestPost('general/savesmssetting', {
        smsSettingRecord: dataForm
    });    

    if(!oOper.bReturn){
        appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
        setSaving(false);
        return;
    }

    onClose(oOper.lstOfCompSmsSettings!);
  }

  useEffect(() => {
    const fData = { ...recordData };
    setDataForm(fData);
    setFormErrors({});
    setSaving(false);
  }, [isOpen]);

  return (
    <WiseModal
      hasFooter={false}
      isOpen={isOpen}
      onClose={onClose}
      titleBox={dlgTitle}
      width={450}
    >
      <div className="flex flex-col p-2 space-y-3">
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-full md:w-[49%]">
            <BootstrapInput
              fieldName="accountName"
              label={languages.acctName}
              fldType="text"
              value={dataForm.accountName}
              disabled={saving || dlgStatus !== 0}
              errorText={formErrors.accountName}
              placeholder={languages.acctName}
              showLabel={true}
              onChange={onChange}
            />
          </div>
          <div className="w-full md:w-[49%]">
            <BootstrapInput
              fieldName="accountPassword"
              label={languages.password}
              fldType="password"
              value={dataForm.accountPassword}
              disabled={saving || dlgStatus !== 0}
              errorText={formErrors.accountPassword}
              placeholder={languages.password}
              showLabel={true}
              onChange={onChange}
            />
          </div>
        </div>
        <div className="flex items-center justify-between flex-wrap">
          <div className="w-full md:w-[49%]">
            <BootstrapInput
              fieldName="accountPhoneNumber"
              label={languages.phoneNbr}
              fldType="text"
              value={dataForm.accountPhoneNumber}
              disabled={saving || dlgStatus !== 0}
              errorText={formErrors.accountPhoneNumber}
              placeholder={languages.acctName}
              showLabel={true}
              onChange={onChange}
              isNumeric={true}
              maxLength={12}
            />
          </div>
          <div className="w-full md:w-[49%]">
            <BootstrapInput
              fieldName="accountDisplayName"
              label={languages.displayName}
              fldType="text"
              value={dataForm.accountDisplayName}
              disabled={saving || dlgStatus !== 0}
              errorText={formErrors.accountDisplayName}
              placeholder={languages.displayName}
              showLabel={true}
              onChange={onChange}
            />
          </div>
        </div>

        {/* Footer */}
        <div className="flex items-center justify-end space-x-3 py-3 px-2 border-t">
            {dlgStatus === 0 && (
              <WiseSmallButton
                color="blue"
                label={saving ?  languages.saving: languages.save}
                icon={
                  <i
                    className={`fa ${
                      saving ? "fa-spin fa-spinner" : "fa-save"
                    } `}
                  ></i>
                }
                onAction={() => onSaveSettings()}
                disabled={saving}
              />
            )}
            <WiseSmallButton
              label={languages.cancel}
              onAction={() => onClose(null)}
              disabled={saving}
            />
          </div>
      </div>
    </WiseModal>
  );
};
