/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  IInfluencer,
  IMonalytCampaign,
  IMonalytCampaignInfl,
} from "src/models/monalytics_models";
import fcountries from "src/assets/resources/country_tel_codes.json";

import NetServices from "src/utils/netservices";
import GeneralFxs from "src/utils/general_fx";
import GetLangs from "src/utils/languages";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";
import { Box, DropdownMenu, Tabs } from "@radix-ui/themes";
import {
  CommTypeOper,
  DropDownMenuAction,
  ICountyWithCtr,
  IHiringActiActionsRef,
  IMenuDropDownAct,
} from "src/models/smarttypes";
import { SmallButtonRounded } from "src/components/small_comps";
import ListOfWiseNotes from "src/components/specialcomps/ListOfWiseNotes";
import ListOfWiseEmails from "src/components/specialcomps/ListOfWiseEmails";
import ListOfWisePhoneCalls from "src/components/specialcomps/ListOfWisePhoneCalls";
import ListOfWiseTodos from "src/components/specialcomps/ListOfWiseTodos";
import ListOfWiseEvents from "src/components/specialcomps/ListOfWiseEvents";
import ListOfWiseFiles from "src/components/specialcomps/ListOfWiseFiles";
import ListOfWiseSms from "src/components/specialcomps/ListOfWiseSms";
import FinanceDocumentsViewer from "src/components/specialcomps/FinanceDocumentsViewer";

const languages = GetLangs();
const appFxs = GeneralFxs();
const countries = fcountries as ICountyWithCtr;

const CampaignHireInflOper = () => {
  const { operationKey } = useParams();
  const navigate = useNavigate();

  const [loading, setloading] = useState(true);
  //const [hiredInfluencerOps, setHiredInfluencerOps] =
  useState<IMonalytCampaignInfl | null>(null);
  const [relatedInfluencer, setRelatedInfluencer] =
    useState<IInfluencer | null>(null);
  const [campaignRecord, setCampaignRecord] = useState<IMonalytCampaign | null>(
    null
  );
  const [tabAction, setTabAction] = useState<CommTypeOper>("note");

  const prepareData = async () => {
    setloading(true);

    const oOper = await NetServices.requestGet(
      `monalytics/gethiredinfluencerbyoperation/${operationKey}`
    );

    if (!oOper.bReturn) {
      const bAsk = await appFxs.showAlertConfirm(oOper.msgTitle, oOper.msgBody);
      if (bAsk.isDismissed) {
        navigate("/app/campaign");
      }
      return;
    }

    //setHiredInfluencerOps(oOper.hiredInfluencerOps!);
    setRelatedInfluencer(oOper.relatedInfluencer!);
    setCampaignRecord(oOper.campaignRecord!);

    setloading(false);
  };

  const updateTabsComp = (action: DropDownMenuAction) => {
    switch (action) {
      case "send_note":
        setTabAction("note");
        break;
      case "send_email":
        setTabAction("email");
        break;
      case "upload_file":
        setTabAction("file");
        break;
      case "todo":
        setTabAction("todo");
        break;
      case "event":
        setTabAction("event");
        break;
      case "make_call":
        setTabAction("phone");
        break;
      case "send_sms":
        setTabAction("sms");
        break;
    }
  };

  useEffect(() => {
    prepareData();
  }, []);

  if (loading) return <LoadinInterfaceComp />;

  return (
    <div className="flex flex-col bg-[#f8f9fc] py-3 p-4">
      {/* Header */}
      <div className="flex items-center justify-between py-3">
        <div className="flex-1">
          <h3 className="text-2xl font-semibold">{`${relatedInfluencer?.firstName} ${relatedInfluencer?.lastName}`}</h3>
          <div className="text-base ">
            <Link
              className="text-indigo-500 hover:text-indigo-800"
              to={`/app/campaign/editor/${campaignRecord?.campaignKey}`}
            >
              {campaignRecord?.title}
            </Link>
          </div>
        </div>
        <div>
          <SmallButtonRounded label={languages.edit} />
        </div>
      </div>

      {/* Body */}
      <div className="flex flex-row flex-wrap">
        <div className="w-full md:w-2/3">
          <div className="flex flex-col space-y-5">
            <FinanceDocumentsViewer
              hiringOperationKey={operationKey!}
              updateTabsComp={updateTabsComp}
            />
            <OperationToolsDataViewer
              hiringOperationKey={operationKey!}
              influencer={relatedInfluencer!}
              action={tabAction}
            />
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <InfluencerTableView influencer={relatedInfluencer!} />
        </div>
      </div>
    </div>
  );
};

export default CampaignHireInflOper;

const InfluencerTableView = ({ influencer }: { influencer: IInfluencer }) => {
  const appMenus: IMenuDropDownAct[] = [
    {
      actionType: "send_sms",
      label: languages.send_sms,
      icon: "fa fa-envelope-o",
    },
    {
      actionType: "placecall",
      label: languages.make_call,
      icon: "fa fa-phone",
    },
  ];

  return (
    <div className="p-3 flex flex-col cover-info">
      <h2 className="mb-4 text-base uppercase font-semibold">
        <span>{languages.infl}</span>:&nbsp;
        {`${influencer.firstName} ${influencer.lastName}`}
      </h2>
      <table>
        <tbody>
          <tr>
            <td className="td-label">{languages.name}</td>
            <td className="td-value">
              <span className="text-blue-500 hover:text-blue-700 cursor-pointer">{`${influencer.firstName} ${influencer.lastName}`}</span>
            </td>
          </tr>
          <tr>
            <td className="td-label">{languages.email}</td>
            <td className="td-value">
              {influencer.inflEmail && (
                <span className="text-blue-500 hover:text-blue-700 cursor-pointer">
                  <i className="fa fa-envelope"></i>&nbsp;
                  {influencer.inflEmail}
                </span>
              )}
            </td>
          </tr>
          <tr>
            <td className="td-label">{languages.phoneNbr}</td>
            <td className="td-value">
              {influencer.mobilePhone && (
                <DropdownMenu.Root>
                  <DropdownMenu.Trigger>
                    <span className="text-blue-500 hover:text-blue-700 cursor-pointer">
                      <i className="fa fa-phone"></i>&nbsp;
                      {influencer.mobilePhone}
                    </span>
                  </DropdownMenu.Trigger>
                  <DropdownMenu.Content>
                    {appMenus.map((o, idx) => (
                      <DropdownMenu.Item key={idx} className="cursor-pointer ">
                        <i className={o.icon}></i>&nbsp;{o.label}
                      </DropdownMenu.Item>
                    ))}
                  </DropdownMenu.Content>
                </DropdownMenu.Root>
              )}
            </td>
          </tr>

          <tr>
            <td className="td-label">{languages.country}</td>
            <td className="td-value">
              <span className="">
                {countries.Countries.find((f) => f.code === influencer.country)
                  ? countries.Countries.find(
                      (f) => f.code === influencer.country
                    )?.name
                  : languages.notSpecified}
              </span>
            </td>
          </tr>
          <tr>
            <td className="td-label">{languages.city}</td>
            <td className="td-value">
              <span className="">{influencer?.city}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

interface IOperationToolsDataViewer {
  hiringOperationKey: string;
  influencer: IInfluencer;
  action: CommTypeOper;
}

const OperationToolsDataViewer = ({
  hiringOperationKey,
  influencer,
  action,
}: IOperationToolsDataViewer) => {
  const [defaultTab, setDefaultTab] = useState<string>("notes");

  const notesHiringActionRef = useRef<IHiringActiActionsRef>(null);
  const emailsHiringActionRef = useRef<IHiringActiActionsRef>(null);
  const filesHiringActionRef = useRef<IHiringActiActionsRef>(null);
  const todosHiringActionRef = useRef<IHiringActiActionsRef>(null);
  const eventsHiringActionRef = useRef<IHiringActiActionsRef>(null);
  const phonesHiringActionRef = useRef<IHiringActiActionsRef>(null);
  const smsHiringActionRef = useRef<IHiringActiActionsRef>(null);

  const handleTabChange = (v: string) => {
    setDefaultTab(v);
  };

  const changeAndDisplay = (v: string) => {
    setDefaultTab(v);

    setTimeout(() => {
      openDialogAction(v);
    }, 1000);
  };

  const openDialogAction = (v: string) => {
    switch (v) {
      case "notes":
        notesHiringActionRef.current?.onOpenDialog();
        break;
      case "emails":
        emailsHiringActionRef.current?.onOpenDialog();
        break;
      case "files":
        filesHiringActionRef.current?.onOpenDialog();
        break;
      case "todo":
        todosHiringActionRef.current?.onOpenDialog();
        break;
      case "events":
        eventsHiringActionRef.current?.onOpenDialog();
        break;
      case "events":
        smsHiringActionRef.current?.onOpenDialog();
        break;
      case "phone":
        phonesHiringActionRef.current?.onOpenDialog();
        break;
      case "sms":
        smsHiringActionRef.current?.onOpenDialog();
        break;
    }
  };

  useEffect(() => {
    const tabStr =
      action === "note"
        ? "notes"
        : action === "email"
        ? "emails"
        : action === "file"
        ? "files"
        : action === "todo"
        ? "todo"
        : action === "event"
        ? "events"
        : action === "phone"
        ? "phone"
        : action === "sms"
        ? "sms"
        : "notes";
    changeAndDisplay(tabStr);
  }, [action]);

  return (
    <div className="">
      <Tabs.Root
        defaultValue={defaultTab}
        value={defaultTab}
        onValueChange={handleTabChange}
        onChange={(v) => {}}
      >
        <Tabs.List>
          <Tabs.Trigger value="notes">
            {languages.notes.toUpperCase()}
          </Tabs.Trigger>
          <Tabs.Trigger value="emails">
            {languages.emails.toUpperCase()}
          </Tabs.Trigger>
          <Tabs.Trigger value="files">
            {languages.files.toUpperCase()}
          </Tabs.Trigger>
          <Tabs.Trigger value="todo">
            {languages.todo.toUpperCase()}
          </Tabs.Trigger>
          <Tabs.Trigger value="events">
            {languages.events.toUpperCase()}
          </Tabs.Trigger>
          <Tabs.Trigger value="phone">
            {languages.phone.toUpperCase()}
          </Tabs.Trigger>
          <Tabs.Trigger value="sms">{languages.sms.toUpperCase()}</Tabs.Trigger>
        </Tabs.List>

        <Box pt="3">
          <Tabs.Content value="notes">
            <ListOfWiseNotes
              ref={notesHiringActionRef}
              hiringOperationKey={hiringOperationKey}
            />
          </Tabs.Content>

          <Tabs.Content value="emails">
            <ListOfWiseEmails
              hiringOperationKey={hiringOperationKey}
              influencer={influencer}
              ref={emailsHiringActionRef}
            />
          </Tabs.Content>

          <Tabs.Content value="files">
            <ListOfWiseFiles
              hiringOperationKey={hiringOperationKey}
              ref={filesHiringActionRef}
            />
          </Tabs.Content>

          <Tabs.Content value="todo">
            <ListOfWiseTodos
              hiringOperationKey={hiringOperationKey}
              ref={todosHiringActionRef}
            />
          </Tabs.Content>

          <Tabs.Content value="events">
            <ListOfWiseEvents
              hiringOperationKey={hiringOperationKey}
              ref={eventsHiringActionRef}
            />
          </Tabs.Content>

          <Tabs.Content value="phone">
            <ListOfWisePhoneCalls
              hiringOperationKey={hiringOperationKey}
              ref={phonesHiringActionRef}
            />
          </Tabs.Content>

          <Tabs.Content value="sms">
            <ListOfWiseSms
              hiringOperationKey={hiringOperationKey}
              ref={smsHiringActionRef}
            />
          </Tabs.Content>
        </Box>
      </Tabs.Root>
    </div>
  );
};
