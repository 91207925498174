/* eslint-disable react-hooks/exhaustive-deps */

import React, { forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from "react";

import geLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

import {
  CalendarViewerComp,
  ErrorMsgDisplayer,
  NoRecordMsgDisplayer,
  WiseCircularActionBtn,
} from "../small_comps";
import {
  IMonalytCampaignInfl,
  IMonalytInflCampFile,
} from "src/models/monalytics_models";
import NetServices from "src/utils/netservices";
import LoadinInterfaceComp from "../LoadinInterfaceComp";
import { IHiringActiActionsRef, IHiringCompVals } from "src/models/smarttypes";

const languages = geLang();
const appFxs = generalFxs();

const emptyFileRec: IMonalytInflCampFile = {
  fileKey: "",
  hireOperKey: "",
  campaignKey: "",
  companyKey: "",
  influencerKey: "",
  createdBy: "",
  extension: "",
  fileName: "",
  fileSize: 0,
  operDate: new Date(),
  realName: "",
};

const ListOfWiseFiles = forwardRef((
  props: IHiringCompVals,
  ref: Ref<IHiringActiActionsRef>
) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const fileSelect = useRef<HTMLInputElement>(null);

  const [openReady, setOpenReady] = useState(false);

  const [lstOfWiseFiles, setLstOfWiseFiles] = useState<IMonalytInflCampFile[]>(
    []
  );
  const [baseHiringOps, setBaseHiringOps] =
    useState<IMonalytCampaignInfl | null>(null);

  const populateData = async () => {
    setLoading(true);
    setMsgError("");
    setHasError(false);

    const oOper = await NetServices.requestGet(
      `hiretools/getlistfiles/${props.hiringOperationKey}`
    );

    if (!oOper.bReturn) {
      setMsgError(oOper.msgBody);
      setHasError(true);
      setLoading(false);
      return;
    }
    setLstOfWiseFiles(oOper.lstOfWiseFiles!);
    setBaseHiringOps(oOper.baseHiringOps!);
    setMsgError("");
    setHasError(false);
    setLoading(false);
  };

  const uploadNewFile = () => {
    fileSelect.current?.click();
  };
  const onFileDelete = async (k: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "hiretools/deletehiringfilerecord",
      {
        hireFileKey: k,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setLstOfWiseFiles(oOper.lstOfWiseFiles!);
  };
  const onFileDownload = async (k: IMonalytInflCampFile) => {
    const filePath = `${appDecl.filesResources}/${k.realName}`;
    const bRes = await appFxs.downloadFromFileUrl(filePath, k.fileName);
    console.log('bRes', bRes);
  };

  const onFileSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files![0]!;
    onSaveFileRecord(file);
  };
  const onSaveFileRecord = async (f: File) => {
    const oRecord: IMonalytInflCampFile = {
      ...emptyFileRec,
      fileKey: appFxs.getRandomDigits(35),
      hireOperKey: baseHiringOps!.recordKey,
      campaignKey: baseHiringOps!.campaignKey,
      companyKey: baseHiringOps!.companyKey,
      influencerKey: baseHiringOps!.influencerKey,

      realName: f.name,
      fileName: f.name,
      fileSize: f.size,
      extension: f.name.split(".").pop() ?? "",
      operDate: new Date(),
    };

    const fData = new FormData();
    fData.append("fileRecordData", JSON.stringify(oRecord));
    fData.append("hiringFileMeta", f);

    const saveOper = await NetServices.requestPostWithFileAxios(
      "hiretools/savefilerecord",
      fData,
      true
    );

    if (!saveOper.bReturn) {
      appFxs.showAlert(saveOper.msgTitle, saveOper.msgBody);
      return;
    }

    setLstOfWiseFiles(saveOper.lstOfWiseFiles!);
  };

  const onOpenDialog = () => {
    if(!baseHiringOps){
      setOpenReady(true);
    }else{
      fileSelect.current?.click()
    }
  }

  useImperativeHandle(ref, () => ({
    onOpenDialog
  }));

  useEffect(() => {
    populateData();
  }, []);

  useEffect(() => {
    if(openReady){
      onOpenDialog();
    };    
  }, [baseHiringOps]);

  return (
    <div className="flex flex-col">
      {/* Header */}
      <div className="flex items-center space-x-2">
        <WiseCircularActionBtn type="add" onAction={() => uploadNewFile()} />
        <span
          onClick={() => uploadNewFile()}
          className="text-sm cursor-pointer text-blue-500 hover:text-blue-700"
        >
          {languages.uploadFile}
        </span>
      </div>
      {/* Body */}
      <div className="flex flex-col mt-4">
        <input
          type="file"
          className="hidden"
          ref={fileSelect}
          onChange={onFileSelectChange}
          accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
        />

        {loading && <LoadinInterfaceComp />}
        {!loading && !hasError && lstOfWiseFiles.length === 0 && (
          <NoRecordMsgDisplayer
            msg={languages.noFilePosted}
            onAction={() => uploadNewFile()}
            actionTitle={languages.uploadFile}
            btnIcon={<i className="fa fa-download"></i>}
          />
        )}
        {!loading && hasError && (
          <ErrorMsgDisplayer
            msg={msgError}
            onAction={() => populateData()}
            actionTitle={languages.refresh}
          />
        )}
        {!loading &&
          !hasError &&
          lstOfWiseFiles.length > 0 &&
          lstOfWiseFiles.map((o, idx) => (
            <WiseFileElt
              key={idx}
              data={o}
              onDelete={(k) => onFileDelete(k)}
              onDownload={(k) => onFileDownload(k)}
            />
          ))}
      </div>
    </div>
  );
})

export default ListOfWiseFiles;

const WiseFileElt = ({
  data,
  onDelete,
  onDownload,
}: {
  data: IMonalytInflCampFile;
  onDelete: (k: string) => void;
  onDownload: (k: IMonalytInflCampFile) => void;
}) => {
  return (
    <div className="flex items-start mb-3">
      <div className="mr-3">
        <CalendarViewerComp calDate={new Date(data.operDate)} />
      </div>
      <div className="flex-1 -mt-2">
        <div className="flex items-center space-x-3 mb-1">
          <div>
            <span
              title={languages.download}
              onClick={() => onDownload(data)}
              className="text-blue-400 font-semibold cursor-pointer text-sm"
            >
              {data.fileName}
            </span>
            &nbsp;@&nbsp;
            <span>{appFxs.formatFileBytes(data.fileSize, 2)}</span>
          </div>
          <span
            onClick={() => onDelete(data.fileKey)}
            className="cursor-pointer"
            title={languages.delete}
          >
            <i className="fa fa-trash text-red-500"></i>
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <span className="text-xs">
            {languages.uploaded}&nbsp;
            <span className="font-semibold">{appFxs.formatDateTimeAlt(new Date(data.operDate))}</span>
          </span>
        </div>
      </div>
    </div>
  );
};
