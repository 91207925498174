/* eslint-disable @typescript-eslint/no-explicit-any */

import Swal from "sweetalert2";
import AppDeclarations from "./declarations";
import GetLanguage from "./languages";

import moment from "moment";
import jquery from "jquery";
import loadingAjax from "src/assets/images/loading.png";
import {
  DateOperation,
  HeaderType,
  IPassworderChecker,
  ReturnInfo,
  SourceChannelType,
} from "src/models/smarttypes";
import { mimeTypes } from "./tempo_data";

function generalFx() {
  //const $ = jquery;
  const language = GetLanguage();
  const wdMonthsLng = [
    language.jan_lng,
    language.feb_lng,
    language.mar_lng,
    language.apr_lng,
    language.may_lng,
    language.jun_lng,
    language.jul_lng,
    language.aug_lng,
    language.sept_lng,
    language.oct_lng,
    language.nov_lng,
    language.dec_lng,
  ];
  const wdMonthsShrt = [
    language.jan_shrt,
    language.feb_shrt,
    language.mar_shrt,
    language.apr_lng,
    language.may_lng,
    language.jun_lng,
    language.jul_shrt,
    language.aug_shrt,
    language.sept_shrt,
    language.oct_shrt,
    language.nov_shrt,
    language.dec_shrt,
  ];
  const wdDaysLong = [
    language.monday_lng,
    language.tuesday_lng,
    language.wednday_lng,
    language.thursday_lng,
    language.friday_lng,
    language.saturday_lng,
    language.sunday_lng,
  ];
  const wdDaysSort = [
    language.monday_shrt,
    language.tuesday_shrt,
    language.wednday_shrt,
    language.thursday_shrt,
    language.friday_shrt,
    language.saturday_shrt,
    language.sunday_shrt,
  ];
  const getLocalStorageByKey = (key: string, defaultValue: string) => {
    const elt = localStorage.getItem(key);
    return elt ?? defaultValue;
  };
  const setLocalStorage = (key: string, valueData: string) => {
    localStorage.setItem(key, valueData);
  };
  const generateUUID = (): string => {
    let d = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      (c) => {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return uuid;
  };
  const rearrangeArrToPos = (
    arr: any[],
    curPos: number,
    movt: "prev" | "next"
  ) => {
    const tempoArr = [...arr];
    if (movt === "prev" && curPos === 0) return tempoArr;
    if (movt === "next" && curPos === tempoArr.length - 1) return tempoArr;

    const newIndex = movt === "prev" ? curPos - 1 : curPos + 1;
    const oldIndex = curPos;

    if (newIndex >= tempoArr.length) {
      let i = newIndex - tempoArr.length + 1;
      while (i--) {
        tempoArr.push(undefined);
      }
    }
    tempoArr.splice(newIndex, 0, tempoArr.splice(oldIndex, 1)[0]);
    return tempoArr;
  };
  const showAjaxLoader = (loadingMessage?: string) => {
    const langText = loadingMessage ?? GetLanguage().waitServerResp;

    return Swal.fire({
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      html: `
                  <div class="py-2 flex flex-col items-center justify-center">
                      <img class="animate-spin my-3" src="${loadingAjax}" width="50" height="50" />
                      <span class="py-3">${langText}</span>
                  </div>
              `,
    });
  };
  const showInputAlert = (
    msgTitle: string,
    confirmButtonText: string = "OK"
  ) => {
    return Swal.fire({
      title: msgTitle,
      input: "text",
      confirmButtonText,
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
  };
  const showAlert = (
    msgTitle: string,
    msgBody: string,
    cancelButtonText: string = language.ok,
    isHtml: boolean = false
  ) => {
    if (isHtml) {
      return Swal.fire({
        title: msgTitle,
        html: msgBody,
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText,
        allowEscapeKey: false,
        allowOutsideClick: false,
        customClass: {
          container: "wise-fire-dlg",
        },
      });
    }
    return Swal.fire({
      title: msgTitle,
      text: msgBody,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText,
      allowEscapeKey: false,
      allowOutsideClick: false,
      customClass: {
        container: "wise-fire-dlg",
      },
    });
  };
  const showAlertConfirm = (
    msgTitle: string,
    msgBody: string,
    confirmButtonText: string = language.ok
  ) => {
    return Swal.fire({
      title: msgTitle,
      text: msgBody,
      showConfirmButton: true,
      confirmButtonText,
      allowEscapeKey: false,
      allowOutsideClick: false,
      customClass: { container: "wise-fire-dlg" },
    });
  };
  const showConfirm = (
    msgTitle: string,
    msgBody: string,
    cancelButtonText: string = language.cancel,
    confirmButtonText: string = language.yes
  ) => {
    return Swal.fire({
      title: msgTitle,
      text: msgBody,
      showCancelButton: true,
      showConfirmButton: true,
      cancelButtonText,
      confirmButtonText,
      allowEscapeKey: false,
      allowOutsideClick: false,
      customClass: {
        container: "wise-fire-dlg",
      },
    });
  };

  const chunkString = (str: string, len: number) => {
    return str.match(new RegExp(".{1," + len + "}", "g"));
  };
  const shortenText = (
    str: string,
    len: number = 15,
    bPointed: boolean = true,
    bMiddle: boolean = false
  ): string => {
    if (!str) return "";
    if (str.length <= len) return str;
    let finalText: string = "";

    if (bMiddle) {
      const oPart = Math.floor(str.length / 3);
      const parts = chunkString(str, oPart)!;

      finalText = `${parts[0]}...${parts[2]}`;
    } else {
      const fStr = str.substring(0, len - 1);
      const points = bPointed ? "..." : "";

      finalText = `${fStr}${points}`;
    }

    return finalText;
  };
  const getLocalLanguage = () => {
    return getLocalStorageByKey(AppDeclarations.currentLang, "en");
  };
  const timeLongAgo = (date: Date): string => {
    const cLang = getLocalLanguage();

    const todayDate = new Date();
    const seconds = Math.floor((todayDate.getTime() - date.getTime()) / 1000);
    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
      return cLang === "en"
        ? `${interval} years ago`
        : `il y a ${interval} ans`;
    }

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
      return cLang === "en"
        ? `${interval} months ago`
        : `il y a ${interval} mois`;
    }

    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
      return cLang === "en"
        ? `${interval} days ago`
        : `il y a ${interval} jours`;
    }

    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
      return cLang === "en"
        ? `${interval} hours ago`
        : `il y a ${interval} heures`;
    }

    interval = Math.floor(seconds / 60);
    if (interval > 1) {
      return cLang === "en"
        ? `${interval} minutes ago`
        : `il y a ${interval} minutes`;
    }

    if (seconds < 60) return language.justNow;

    return Math.floor(seconds) + " seconds ago";
  };
  const onlyCapitalLetters = (str: string): string => {
    let newStr = "";

    for (let i = 0; i < str.length; i++) {
      if (str[i].match(/[A-Z]/)) {
        newStr += str[i];
      }
    }
    return newStr;
  };
  const containsOnlyDigits = (str: string) => {
    return /^\d+$/.test(str);
  };

  const onlySmallLetters = (str: string): string => {
    let newStr = "";

    for (let i = 0; i < str.length; i++) {
      if (str[i].match(/[a-z]/)) {
        newStr += str[i];
      }
    }
    return newStr;
  };
  const onlySpecialChars = (
    strSource: string,
    specialChars: string
  ): string => {
    const retArr: string[] = [];
    const arrSource = strSource.split("");
    arrSource.forEach((oSrc) => {
      if (specialChars.includes(oSrc)) {
        retArr.push(oSrc);
      }
    });
    return retArr.join("");
  };
  const getThePassStatus = (password: string): IPassworderChecker => {
    const format = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;

    const capLetters = password
      .split("")
      .filter(
        (o) =>
          o === o.toUpperCase() && !format.test(o) && !containsOnlyDigits(o)
      )
      .join("");

    const smallLetters = password
      .split("")
      .filter(
        (o) =>
          o === o.toLowerCase() && !format.test(o) && !containsOnlyDigits(o)
      )
      .join("");

    const specialLetters = password
      .split("")
      .filter((o) => format.test(o))
      .join("");

    const numberLetters = password
      .split("")
      .filter((o) => containsOnlyDigits(o))
      .join("");

    const capStatus: "poor" | "medium" | "high" =
      capLetters.length === 0
        ? "poor"
        : capLetters.length === 1
        ? "medium"
        : "high";

    const smStatus: "poor" | "medium" | "high" =
      smallLetters.length === 0
        ? "poor"
        : smallLetters.length === 1
        ? "medium"
        : "high";

    const spStatus: "poor" | "medium" | "high" =
      specialLetters.length === 0
        ? "poor"
        : specialLetters.length === 1
        ? "medium"
        : "high";

    const lenStatus: "poor" | "medium" | "high" =
      password.length >= 8 ? "high" : password.length >= 4 ? "medium" : "poor";

    const numStatus: "poor" | "medium" | "high" =
      numberLetters.length >= 2
        ? "high"
        : numberLetters.length === 1
        ? "medium"
        : "poor";

    const capVal = capStatus === "high" ? 15 : capStatus === "medium" ? 10 : 0;
    const smVal = smStatus === "high" ? 15 : smStatus === "medium" ? 10 : 0;
    const spVal = spStatus === "high" ? 15 : spStatus === "medium" ? 10 : 0;
    const numVal = numStatus === "high" ? 15 : numStatus === "medium" ? 10 : 0;
    const lenVal = lenStatus === "high" ? 40 : lenStatus === "medium" ? 20 : 0;

    const allVal = capVal + smVal + spVal + numVal + lenVal;

    const oRet: IPassworderChecker = {
      capLetters: {
        len: capLetters.length,
        status: capStatus,
      },
      smLetters: {
        len: smallLetters.length,
        status: smStatus,
      },
      specChars: {
        len: specialLetters.length,
        status: spStatus,
      },
      numChars: {
        len: numberLetters.length,
        status: numStatus,
      },
      lenChars: {
        len: password.length,
        status: lenStatus,
      },
      percentVal: allVal,
    };

    return oRet;
  };
  const setLocalStorageByKey = (key: string, valueData: string) => {
    localStorage.setItem(key, valueData);
  };
  const cleanLocalStorageByKey = (key: string) => {
    localStorage.setItem(key, "");
  };
  const removeLocalStorageByKey = (key: string) => {
    localStorage.removeItem(key);
  };
  const setLocalLanguage = (lang: string) => {
    return setLocalStorageByKey(AppDeclarations.currentLang, lang);
  };
  const toBoolFromStr = (nbrStr: string): boolean => {
    return nbrStr === "0" ? false : nbrStr === "1" ? true : false;
  };
  const isPasswordGood = (strPw: string): boolean => {
    if (!strPw) return false;
    const strength = passwordStrengthCheck(strPw);
    return strength >= 70;
  };
  const passwordStrengthCheck = (password: string): number => {
    let retFinal = 0;
    const pLength = password.length;
    //const regNbr = new RegExp('^[0-9]+$');
    if (/\d/.test(password)) {
      retFinal += 5;
      const tempoPw = password;
      const allNbrs = tempoPw.replace(/[^0-9]/g, "");
      const lenNbrs = allNbrs.length;
      if (lenNbrs! < 2) {
        retFinal += 5;
      } else if (lenNbrs! === 2) {
        retFinal += 10;
      } else {
        retFinal += 15;
      }
    }
    const regCapLetter = /[A-Z]/;
    if (regCapLetter.test(password)) {
      retFinal += 5;

      const caps = onlyCapitalLetters(password).length;
      if (caps < 2) {
        retFinal += 5;
      } else if (caps === 2) {
        retFinal += 10;
      } else {
        retFinal += 15;
      }
    }
    const regSmallLetter = /[a-z]/;
    if (regSmallLetter.test(password)) {
      retFinal += 5;

      const caps = onlySmallLetters(password).length;
      if (caps < 2) {
        retFinal += 5;
      } else if (caps === 2) {
        retFinal += 10;
      } else {
        retFinal += 15;
      }
    }
    const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    if (specialChars.test(password)) {
      retFinal += 5;
      const caps = onlySpecialChars(
        password,
        "`!@#$%^&*()_+-=[]{};':\"\\|,.<>/?~"
      ).length;
      if (caps < 2) {
        retFinal += 5;
      } else if (caps === 2) {
        retFinal += 10;
      } else {
        retFinal += 15;
      }
    }
    if (pLength >= 8) {
      retFinal += 20;
    }
    return retFinal;
  };
  const openSearchTag = (type: SourceChannelType, tag: string) => {
    if (type === "snapchat") {
      showAlert("Snapcaht", GetLanguage().snapchatTagFtrErr);
      return;
    }
    const path =
      type === "tiktok"
        ? `https://www.tiktok.com/tag/${tag}`
        : type === "facebook"
        ? `https://www.facebook.com/hashtag/${tag}`
        : type === "instagram"
        ? `https://www.instagram.com/hashtag/${tag}`
        : type === "linkedin"
        ? `https://www.linkedin.com/feed/hashtag/?keywords=${tag}`
        : type === "twitter"
        ? `https://x.com/hashtag/${tag}?src=hashtag_click`
        : `https://www.tiktok.com/tag/${tag}`;

    window.open(path, "_blank")?.focus();
  };

  const htmlDecode = (content: string) => {
    const e = document.createElement('div');
    e.innerHTML = content;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue!;
  }
  

  return {
    htmlDecode,
    openSearchTag,
    hasSpaces: (s: string) => {
      return /\s/g.test(s);
    },
    formatNumberFromThousand: (d: string):string => {
      return d.replace(/,/g, "");
    },
    getDateForInput: (d: Date) => {
      const day = ("0" + d.getDate()).slice(-2);
      const month = ("0" + (d.getMonth() + 1)).slice(-2);

      const today = d.getFullYear() + "-" + month + "-" + day;
      return today;
    },
    getDateForInputLocal: (d: Date) => {
      const isoString = d.toISOString();
      const f = isoString.substring(0, (isoString.indexOf("T")|0) + 6|0);
      return f;
    },
    //#region general
    containsOnlyDigits,
    getLocalStorageByKey,
    setLocalStorage,
    setLocalStorageByKey,
    cleanLocalStorageByKey,
    removeLocalStorageByKey,
    getLocalLanguage,
    setLocalLanguage,
    toBoolFromStr,
    onlyCapitalLetters,
    onlySmallLetters,
    onlySpecialChars,
    isPasswordGood,
    getThePassStatus,
    passwordStrengthCheck,
    getManagtDefaultHeaders(): HeaderType {
      const newDate = new Date();
      const accesskey = this.getLocalStorageByKey(
        AppDeclarations.userAccessKey,
        ""
      );
      const lang = this.getLocalLanguage();
      const companyCode = AppDeclarations.company_code;
      const operationDate = this.dateToUtcStr(new Date());

      let year_app = this.getLocalStorageByKey(
        AppDeclarations.currentYearbook,
        newDate.getFullYear().toString()
      );
      const default_sms = this.getLocalStorageByKey(
        AppDeclarations.currentDefaultSmsAcct,
        ""
      );
      const current_devise = this.getLocalStorageByKey(
        AppDeclarations.currentDevise,
        "USD"
      );
      const current_branch = this.getLocalStorageByKey(
        AppDeclarations.currentBranch,
        "HQ"
      );
      const user_email_value = this.getLocalStorageByKey(
        AppDeclarations.userEmail,
        ""
      );
      const user_password_value = this.getLocalStorageByKey(
        AppDeclarations.userPassword,
        ""
      );

      const oDate = new Date();
      const bInt = !Number.isNaN(year_app);
      if (!bInt) {
        year_app = oDate.getFullYear().toString();
      }

      return {
        "user-access-key": accesskey ? btoa(accesskey) : "",
        lang: lang ? btoa(lang) : "",
        "company-code": companyCode ? btoa(companyCode) : "",
        "client-operation-date": btoa(operationDate),
        "year-exercise-app": btoa(year_app),
        "default-sms-account": default_sms ? btoa(default_sms) : "",
        "current-devise-val": btoa(current_devise),
        "current-branch-val": btoa(current_branch),
        "user-email-value": user_email_value ? btoa(user_email_value) : "",
        "user-password-value": user_password_value
          ? btoa(user_password_value)
          : "",
        "app-type": btoa("managt"),
      };
    },
    returnLang(englText: string, frText: string, rwText: string): string {
      const lng = this.getLocalLanguage();
      return lng === "en"
        ? englText
        : lng === "fr"
        ? frText
        : lng === "rw"
        ? rwText
        : englText;
    },
    //#endregion

    //#region alerts dialogs
    showInputAlert,
    showAlert,
    showAlertConfirm,
    showConfirm,
    showAjaxLoader,
    //#endregion

    //#region strings
    formatDateWithHrsSpec(
      oDate: Date,
      format: string = "MMMM DD, YYYY HH:mm"
    ): string {
      if (oDate) {
        const oFinalDate = oDate;
        return moment(oFinalDate).format(format);
      }

      return "";
    },
    shortenText,
    shortenTextFileName: (
      strFileName: string,
      len: number = 15,
      bPointed: boolean = true
    ): string => {
      if (!strFileName) return "";

      const mainName = strFileName.replace(/\.[^/.]+$/, "");
      const ext = strFileName.split(".").pop();
      if (mainName.length <= len) return `${mainName}.${ext}`;

      const fStr = mainName.substring(0, len - 1);
      const points = bPointed ? "..." : "";
      return `${fStr}${points}${ext}`;
    },
    wiseStrings: (str?: string) => {
      return {
        isEmpty: !str || str.length === 0,
      };
    },
    isTelephoneNbrValid(telNbr: string): boolean {
      const phone = telNbr.replace(/[^0-9]/g, "");
      return phone.length === 12;
    },
    isEmailValid(email: string): boolean {
      const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
      return reg.test(email);
    },
    replaceByCharacter(str: string, fChar: string, nChar: string): string {
      return str.split(fChar).join(nChar);
    },
    extractFileNameFromUrl(url: string): string {
      return url.substring(url.lastIndexOf("/") + 1);
    },
    getTextFromHtml: (html: string) => {
      const span = jquery("<span />").html(html);
      return span.text();
    },
    generateUUID,
    generateTextNbr: (length = 8) => {
      const chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let str = "";
      for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return str;
    },
    getRandomDigits(length: number): string {
      const chars = "0123456789";
      let str = "";
      for (let i = 0; i < length; i++) {
        str += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return str;
    },
    getRandomInt(min: number, max: number): number {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    //#endregion

    //#region date and time
    wdDaysLong,
    wdDaysSort,
    wdMonthsLng,
    wdMonthsShrt,
    timeLongAgo,
    isDateToday: (dDate: Date): boolean => {
      const today = new Date();
      const strToDayF = moment(today).format("DD/MM/YY");
      const strDateF = moment(dDate).format("DD/MM/YY");
      return strToDayF === strDateF;
    },
    getYearFromNow(nYears: number): { id: number; label: string }[] {
      const fY = new Date().getFullYear(),
        lst = [] as { id: number; label: string }[];
      for (let i = 0; i < nYears; i++) {
        const _o = fY + i;
        lst.push({ id: _o, label: _o.toString() });
      }

      return lst;
    },
    getMonthsDates(): { id: number; label: string }[] {
      const lst = [] as { id: number; label: string }[];
      const months = wdMonthsShrt;

      for (let i = 0; i < months.length; i++) {
        const mInt =
          (i + 1).toString().length > 1
            ? (i + 1).toString()
            : "0" + (i + 1).toString();
        const _i = mInt + "-" + months[i];
        lst.push({ id: i + 1, label: _i });
      }

      return lst;
    },
    dateToUtcStr(dDate: Date): string {
      return new Date(dDate).toJSON();
    },
    formatDateWithHrs(
      oDate: Date,
      format: string = "DD/MM/YYYY HH:mm"
    ): string {
      if (oDate) {
        const oFinalDate = oDate;
        return moment(oFinalDate).format(format);
      }

      return "";
    },
    formatDate(oDate: Date, format: string = "DD/MM/YYYY"): string {
      if (oDate) {
        return moment(oDate).format(format);
      }

      return "";
    },
    formatDateFr(oDate: Date, bTime: boolean = false): string {
      const format = bTime ? "DD/MM/YYYY hh:mm:ss" : "DD/MM/YYYY";
      if (oDate) {
        return moment(oDate).format(format);
      }

      return "";
    },
    formatDateEn(oDate: Date): string {
      if (oDate) {
        return moment(oDate).format("MM/DD/YYYY");
      }

      return "";
    },
    formatDateTimeAlt(oDate: Date): string {
      const day = oDate.getDay();
      const date = oDate.getDate();
      const month = oDate.getMonth();
      const year = oDate.getFullYear();

      const dayName = wdDaysLong[day];
      const monthName = wdMonthsLng[month];

      if (oDate) {
        return `${dayName} ${monthName} ${date}, ${year} @ ${moment(oDate).format("hh:mm")}`;
      }

      return "";
    },
    addingDate(
      _date: Date,
      _oper: DateOperation,
      _add: number,
      bSub: boolean = false
    ): Date {
      let _dateRet = new Date();

      try {
        _dateRet = new Date(_date.getTime());

        if (_oper === DateOperation.Day) {
          _dateRet.setDate(_dateRet.getDate() + 1 * _add);
        } else if (_oper === DateOperation.Month) {
          _dateRet.setMonth(_dateRet.getMonth() + 1 * _add);
        } else if (_oper === DateOperation.Year) {
          _dateRet.setFullYear(_dateRet.getFullYear() + 1 * _add);
        }
      } catch (e) {
        return new Date();
      }
      if (bSub === true) {
        _dateRet.setDate(_dateRet.getDate() + 1 * -1);
      }
      return _dateRet;
    },
    getFirstDate(year: number, month: number): Date {
      return new Date(year, month - 1, 1);
    },
    getLastDate(year: number, month: number): Date {
      return new Date(year, month, 0);
    },
    getFirstDateByDate(referDate: Date): Date {
      const year = referDate.getFullYear();
      const month = referDate.getMonth() + 1;

      return new Date(year, month - 1, 1);
    },
    getLastDateByDate(referDate: Date): Date {
      const year = referDate.getFullYear();
      const month = referDate.getMonth() + 1;

      return new Date(year, month, 0);
    },
    isBetweenTwoDates(
      _referDate: Date,
      _firstDate: Date,
      _lastDate: Date
    ): boolean {
      const referDate = new Date(
        _referDate.getFullYear(),
        _referDate.getMonth(),
        _referDate.getDate(),
        23,
        59,
        0
      );
      const firstDate = new Date(
        _firstDate.getFullYear(),
        _firstDate.getMonth(),
        _firstDate.getDate(),
        23,
        59,
        0
      );
      const lastDate = new Date(
        _lastDate.getFullYear(),
        _lastDate.getMonth(),
        _lastDate.getDate(),
        23,
        59,
        0
      );
      return (
        referDate.getTime() >= firstDate.getTime() &&
        referDate.getTime() <= lastDate.getTime()
      );
    },
    isDateValidComparator(dateData: Date, dateRef: Date): boolean {
      const oDateData = new Date(
        dateData.getFullYear(),
        dateData.getMonth(),
        dateData.getDate(),
        23,
        59,
        0
      );
      const oDateRef = new Date(
        dateRef.getFullYear(),
        dateRef.getMonth(),
        dateRef.getDate(),
        23,
        59,
        0
      );

      return oDateData <= oDateRef;
    },
    hasSameDayDate: (datePrev: Date, dateNext: Date): boolean => {
      const format = "DD/MM/YYY";
      return (
        moment(datePrev).format(format) === moment(dateNext).format(format)
      );
    },
    //#endregion

    //#region number
    isNbrOdd: (oNb: number): boolean => {
      const oRes = oNb % 2;
      return oRes > 0;
    },
    formatNumbers(val: number): string {
      return Intl.NumberFormat("en-US").format(val);
    },
    numberOnly(str: string): boolean {
      if (typeof str !== "string") {
        return false;
      }

      if (str.trim() === "") {
        return false;
      }

      return !Number.isNaN(Number(str));
    },
    strictlyNbr(evt: any) {
      const event = evt ? evt : window.event;
      const charCode = event.which ? event.which : event.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    
    numberComma(evt: any, vValue: string): boolean {
      const isDot = evt.charCode === 46;
      const isNbr = (evt.charCode >= 48 && evt.charCode <= 57) || isDot;
      const hasDot = vValue.indexOf(".") !== -1;
      if (isDot) {
        if (hasDot) evt.preventDefault();
      } else {
        if (!isNbr) evt.preventDefault();
      }

      return true;
    },
    numberOnlyV2(evt: any): boolean {
      return evt.charCode >= 48 && evt.charCode <= 57;
    },
    floatNbr(event: any) {
      const input = event.target as HTMLInputElement;
      if (
        (event.which !== 46 || input.value.indexOf(".") !== -1) &&
        (event.which < 48 || event.which > 57)
      ) {
        event.preventDefault();
      }
    },
    floatNbrT(event: any) {
      const input = event.target as HTMLInputElement;
      if (
        event.which === 8 ||
        event.keyCode === 37 ||
        event.keyCode === 39 ||
        event.keyCode === 46
      )
        return true;
      else if (
        (event.which !== 46 || input.value.indexOf(".") !== -1) &&
        (event.which < 48 || event.which > 57)
      )
        return false;
    },
    formatNbrShorten(value: number, bCap: boolean = true): string {
      let newValue = value.toString();
      if (value >= 1000) {
        const suffixes = ["", "k", "m", "b", "t"];
        const suffixNum = Math.floor(("" + value).length / 3);
        let shortValue = 0;
        for (let precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat(
            (suffixNum !== 0
              ? value / Math.pow(1000, suffixNum)
              : value
            ).toPrecision(precision)
          );
          const dotLessShortValue = (shortValue + "").replace(
            /[^a-zA-Z 0-9]+/g,
            ""
          );
          if (dotLessShortValue.length <= 2) {
            break;
          }
        }
        /* let shortNum = "";
                if (shortValue % 1 !== 0)  shortNum = shortValue.toFixed(1); */
        newValue = shortValue + suffixes[suffixNum];
      }
      return bCap
        ? newValue
          ? newValue.toString().toUpperCase()
          : newValue
        : newValue;
    },
    //#endregion

    //#region objects
    getObjWithoutPerop(obj: any, propertyName: string) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [propertyName]: unused, ...rest } = obj;
      return rest;
    },
    mergeObjects(firstObject: any, lastObject: any) {
      const finalObject = { ...firstObject, ...lastObject };

      return finalObject;
    },
    getFormData(xObject?: any): FormData {
      const form_data = new FormData();

      if (xObject) {
        for (const key in xObject) {
          form_data.append(key, xObject[key]);
        }
      }
      return form_data;
    },
    getProxyData(proxyData: any): any {
      return JSON.parse(JSON.stringify(proxyData));
    },
    //#endregion

    //#region cart

    //#endregion

    //#region miscellaneous
    wait: (secs: number) => {
      return new Promise((resolve) => setTimeout(resolve, 1000 * secs));
    },
    //#endregion

    //#region Files
    shareFilesFromUrl: async (
      fileUrl: string,
      fileName?: string,
      bAjax: boolean = false
    ): Promise<ReturnInfo> => {
      const fName = fileName ? fileName : fileUrl.split("/").pop()!;

      if (bAjax) {
        showAjaxLoader();
      }

      try {
        const response = await fetch(fileUrl);
        const buffer = await response.arrayBuffer();

        const pdf = new File([buffer], fName, {
          type: "application/pdf",
        });
        const files = [pdf];

        if (navigator.canShare({ files })) {
          await navigator.share({ files });

          if (bAjax) {
            Swal.close();
          }
          return {
            bReturn: true,
            msgTitle: "",
            msgBody: "",
          };
        } else {
          if (bAjax) {
            Swal.close();
          }
          return {
            bReturn: false,
            msgTitle: GetLanguage().error,
            msgBody: GetLanguage().cantShareDev,
          };
        }
      } catch (error: any) {
        if (bAjax) {
          Swal.close();
        }
        return {
          bReturn: false,
          msgTitle: GetLanguage().error,
          msgBody: error.message,
        };
      }
    },
    convertBlobToFile: (blob: Blob): File => {
      const myFile = new File([blob], "tempoimage.png", {
        type: blob.type,
      });

      return myFile;
    },
    toDataUrl: (src: string, callback: (dataUrl: string) => void) => {
      var image = new Image();
      image.crossOrigin = "Anonymous";
      image.onload = function () {
        var canvas = document.createElement("canvas");
        var context = canvas.getContext("2d")!;
        canvas.height = image.height;
        canvas.width = image.width;
        context.drawImage(image, 0, 0);
        var dataURL = canvas.toDataURL("image/jpeg");
        callback(dataURL);
      };
      image.src = src;
    },
    getImageBlobFromURI: (dataURI: string): Blob => {
      const bAtob = dataURI!.split(",")[0].indexOf("base64") >= 0;
      const byteStr = bAtob
        ? atob(dataURI!.split(",")[1])
        : unescape(dataURI!.split(",")[1]);
      const mimeStr = dataURI!.split(",")[0].split(":")[1].split(";")[0];
      const arr = new Uint8Array(byteStr.length);
      for (let i = 0; i < byteStr.length; i++) {
        arr[i] = byteStr.charCodeAt(i);
      }
      const blob = new Blob([arr], { type: mimeStr });
      return blob;
    },
    openWindowUrl: (url: string, width?: number, height?: number) => {
      const fW = width ?? 320;
      const fH = height ?? 320;

      const windowFeatures = `left=100,top=100,width=${fW},height=${fH}`;
      window.open(url, "mozillaWindow", windowFeatures);
    },
    downloadFromFileUrl: async (
      file_url: string,
      file_name: string
    ): Promise<ReturnInfo> => {
      try {
        const res = await fetch(file_url);
        const blobData = await res.blob();

        const downLink = document.createElement("a");
        downLink.href = window.URL.createObjectURL(blobData);
        downLink.download = file_name;
        downLink.click();

        return {
          bReturn: true,
          msgBody: "",
          msgTitle: "",
        };
      } catch (error: any) {
        return {
          bReturn: false,
          msgBody: GetLanguage().error,
          msgTitle: error.message,
        };
      }
    },
    getFileBlobBuffer: async (
      file_url: string,
      bAjax: boolean = false
    ): Promise<ArrayBuffer | null> => {
      try {
        if (bAjax) {
          showAjaxLoader(GetLanguage().waitingforServerResp);
        }
        const fileData = await fetch(file_url, {
          method: "GET",
          headers: { Accept: "application/octet-stream" },
        });
        const blob = await fileData.arrayBuffer();

        if (bAjax) {
          Swal.close();
        }
        return blob;
      } catch (error) {
        if (bAjax) {
          Swal.close();
        }
        return null;
      }
    },
    isFileOrImageExt: (fileExtx: string) => {
      const fExt = fileExtx.toLowerCase();
      const isImg = fExt === "png" || fExt === "jpg" || fExt === "jpeg";
      const isVideo =
        fExt === "avi" ||
        fExt === "asf" ||
        fExt === "mov" ||
        fExt === "flv" ||
        fExt === "ogg" ||
        fExt === "wmv" ||
        fExt === "mp4" ||
        fExt === "webm" ||
        fExt === "3gp" ||
        fExt === "3gpp";
      return isImg || isVideo;
    },
    urlFileToDataUrl: async (file_url: string): Promise<Blob | null> => {
      try {
        const fileData = await fetch(file_url);
        const blob = await fileData.blob();

        return blob;
      } catch (error) {
        return null;
      }
    },
    isFileImageExt: (fileExtx: string) => {
      const fExt = fileExtx.toLowerCase();
      return fExt === "png" || fExt === "jpg" || fExt === "jpeg";
    },
    isFileImagePng: (file: File) => {
      const type = file.type;
      return type === "image/png";
    },
    isFileDigitalSign: (file: File) => {
      const type = file.name.split(".").pop();
      return type === "p12";
    },
    isFileImage: (file: File) => {
      const type = file.type;
      return type === "image/png" || type === "image/jpeg";
    },
    isFilePDF: (file: File) => {
      const type = file.type;
      return type === "application/pdf";
    },
    isFilePDFExt: (fileName: string) => {
      const ext = fileName.substring(fileName.lastIndexOf(".") + 1);
      return ext === "pdf";
    },
    isFileWord: (file: File) => {
      const type = file.type;
      return type === mimeTypes[2];
    },
    isFileVideoExt: (fileExt: string) => {
      const fExt = fileExt.toLowerCase();

      return (
        fExt === "avi" ||
        fExt === "asf" ||
        fExt === "mov" ||
        fExt === "flv" ||
        fExt === "ogg" ||
        fExt === "wmv" ||
        fExt === "mp4" ||
        fExt === "webm" ||
        fExt === "3gp" ||
        fExt === "3gpp"
      );
    },
    formatFileBytes: (bytes: number, decimals: number) => {
      if (0 === bytes) return "0 Bytes";
      var c = 1024,
        d = decimals || 2,
        e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        f = Math.floor(Math.log(bytes) / Math.log(c));

      return parseFloat((bytes / Math.pow(c, f)).toFixed(d)) + " " + e[f];
    },
    filleExtCss: (ext: string) => {
      let docType = "fa fa-file";

      switch (ext) {
        case ".doc":
        case ".docx":
          docType = "fa fa-file-word";
          break;
        case ".xls":
        case ".xlsx":
          docType = "fa fa-file-excel";
          break;
        case ".pdf":
          docType = "fa fa-file-pdf";
          break;
        case ".png":
        case ".jpg":
        case ".gif":
        case ".svg":
          docType = "fa fa-file-image";
          break;
        case ".avi":
        case ".asf":
        case ".mov":
        case ".flv":
        case ".ogg":
        case ".wmv":
        case ".mp4":
        case ".webm":
        case ".3gp":
        case ".3gpp":
          docType = "fa fa-file-video";
          break;
        case ".ppt":
        case ".pptx":
          docType = "fa fa-file-powerpoint";
          break;
        case ".txt":
          docType = "fa fa-file-text";
          break;
        default:
          docType = "fa fa-file";
      }

      return docType;
    },
    getFileSizeInMbs: (sizeBytes: number): number => {
      const sizeKb = sizeBytes / 1024;
      const sizeMb = sizeKb / 1024;
      return sizeMb;
    },
    //#endregion

    //#region arrays
    tempoKey: () => {
      const today = new Date();
      return today.getTime();
    },
    range: (start: number, end: number) => {
      let length = end - start + 1;
      /*
        Create an array of certain length and set the elements within it from
        start value to end value.
      */
      return Array.from({ length }, (_, idx) => idx + start);
    },
    rearrangeArrToPos,
    //#endregion
  };
}

export default generalFx;
