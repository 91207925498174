import { ReactNode } from "react";
import { ICompanyRecord, IMonalytUser } from "./general_models";
import { IInfluencer } from "./monalytics_models";

export interface IPexelsImageSrc {
  landscape?: string;
  large?: string;
  large2x?: string;
  medium?: string;
  original?: string;
  portrait?: string;
  small?: string;
  tiny?: string;
}
export interface IPexelsImage {
  alt: string;
  avg_color: string;
  height: number;
  id: number;
  liked: boolean;
  photographer: string;
  photographer_id: number;
  photographer_url: string;
  url: string;
  width: number;
  src: IPexelsImageSrc;
}
export interface IPexelsImageRequest {
  next_page: string;
  page: number;
  per_page: number;
  photos: IPexelsImage[];
}

export type HeaderType = {
  "user-access-key": string;
  lang: string;
  "company-code": string;
  "client-operation-date": string;
  "year-exercise-app": string;
  "default-sms-account": string;
  "current-devise-val": string;
  "current-branch-val": string;
  "user-email-value": string;
  "user-password-value": string;
  "app-type": string;
};
export enum DateOperation {
  Day,
  Month,
  Year,
}
export interface IPassworderChecker {
  capLetters: {
    len: number;
    status: "poor" | "medium" | "high";
  };
  smLetters: {
    len: number;
    status: "poor" | "medium" | "high";
  };
  specChars: {
    len: number;
    status: "poor" | "medium" | "high";
  };
  numChars: {
    len: number;
    status: "poor" | "medium" | "high";
  };
  lenChars: {
    len: number;
    status: "poor" | "medium" | "high";
  };
  percentVal: number;
}
export interface ReturnInfo {
  bReturn: boolean;
  msgTitle: string;
  msgBody: string;
}

export interface IFormError {
  email?: string;
  password?: string;
  formEmail?: string;
  adminEmail?: string;
  userEmail?: string;
  fullAddress?: string;
  title?: string;
  start?: string;
  end?: string;
  endDate?: string;
  registerCode1?: string;
  registerCode2?: string;
  hashtag?: string;
  description?: string;
  channels?: string;
  logo?: string;
  influencer?: string;
  budget?: string;
  hiredDate?: string;
  contractPrice?: string;
  influencerKey?: string;
  emailSubject?: string;
  callDetails?: string;
  otherRecips?: string;
  callDate?: string;
  todoTitle?: string;
  notes?: string;
  assignedTo?: string;
  dueDate?: string;
  eventName?: string;
  smsDate?: string;
  smsSettingName?: string;
  smsMessage?: string;
  eventLocation?: string;
  accountName?: string;
  accountDisplayName?: string;
  accountPassword?: string;
  country?: string;
  city?: string;
  poBox?: string;
  companyEmail?: string;
  companyName?: string;
  shortName?: string;
  htmlContent?: string;

  firstName?: string;
  lastName?: string;
  displayName?: string;

  confirmPassword?: string;
  phoneNumber?: string;
  sex?: string;
  accountPhoneNumber?: string;

  financeType?: string;
  operNumber?: string;
  operReference?: string;
  productItemName?: string;
  financeDate?: string;
  financeAmount?: string;
}
export interface IAcctFormRegister {
  adminEmail: string;

  registerCode1: string;
  registerCode2: string;

  companyEmail: string;
  companyName: string;
  shortName: string;

  firstName: string;
  lastName: string;
  displayName?: string;

  password: string;
  confirmPassword: string;

  country?: string;
  city?: string;
  poBox?: string;
}

export interface ICountry {
  code: string;
  dial_code: string;
  name: string;
}

export interface ICountyWithCtr {
  Countries: ICountry[];
}

export interface AppMenuElt {
  id: number;
  label: string;
  href: string;
  icon: any;
}

export type DropDownMenuAction =
  | "edit"
  | "delete"
  | "preview"
  | "placecall"
  | "send_sms"
  | "send_note"
  | "make_call"
  | "send_email"
  | "upload_file"
  | "upload_file"
  | "todo"
  | "event"
  | "create_expense"
  | "create_invoice"
  | "payt_ops";

export interface IMenuDropDownAct {
  label: string;
  subLabel?: string;
  actionType: DropDownMenuAction;
  destructive?: boolean;
  icon?: string;
  iconElt?: ReactNode;
  separator?: boolean;
}

export interface IInfluncChannel {
  label: string;
  value: SourceChannelType;
}

export type AppMenuType =
  | "myprofile"
  | "settings"
  | "langfr"
  | "langeng"
  | "signout";

export interface ConnectedInfoRecord {
  connectedUser: IMonalytUser;
  connectedCompany: ICompanyRecord;
}

export type SourceChannelType =
  | "facebook"
  | "instagram"
  | "linkedin"
  | "snapchat"
  | "twitter"
  | "tiktok";
export type PrevDel = "prev" | "del";

export interface ICurrency {
  code: string;
  decimal_digits: number;
  name: string;
  name_plural: string;
  rounding: number;
  symbol: string;
  symbol_native: string;
}
export type StatusType = "upcoming" | "pastdue" | "done";
export type FinanceOperType = "invoice" | "expense" | "payment";
export type CommTypeOper = "note" | "email" | "file" | "todo" | "event" | "phone" | "sms";


export interface IHiringCompVals {
  hiringOperationKey: string,
  influencer?: IInfluencer;
}

export interface IHiringActiActionsRef {
  onOpenDialog: () => void,
}

export interface ISocialSender {
  tag: string,
  startDate: Date,
  endDate: Date,
  country: string
}