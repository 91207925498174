import axios from 'axios'
import Swal from 'sweetalert2';

import { NetReturn, SenderData } from 'src/models/netreturn';
import generalFxs from 'src/utils/general_fx';
import appLanguages from 'src/utils/languages';
import appDeclarations from 'src/utils/declarations';
import { IPexelsImageRequest } from 'src/models/smarttypes';

const appFxs = generalFxs();
const languages = appLanguages();

async function requestGet(methodName: string, bAjax: boolean = false): Promise<NetReturn> {
    const url = `${appDeclarations.apiRootTest}${methodName}`;
    const headers = appFxs.getManagtDefaultHeaders();

    if(bAjax){
        appFxs.showAjaxLoader(languages.waitingforServerResp);
    }

    const response  = await window.fetch(url, {
        method: 'GET',
        headers
    });

    if(response.ok){
        const json = await response.json();
        const commits = json as NetReturn;
        
        if(bAjax){
            Swal.close();
        }
        return commits;
    }else{
        if(bAjax){
            Swal.close();
        }
        return {
            bReturn: false,
            msgTitle: languages.networkError,
            msgBody: languages.networkErrorMsg,
            typeReturn: 0,
        }
    }
}

async function requestPost(methodName: string, data: SenderData, bAjax: boolean = false): Promise<NetReturn> {
    const url = `${appDeclarations.apiRootTest}${methodName}`;
    const fheaders = appFxs.getManagtDefaultHeaders();
    const headers = {...fheaders, 'Content-Type': 'application/json',}

    if(bAjax){
        appFxs.showAjaxLoader(languages.waitingforServerResp);
    }

    const response  = await window.fetch(url, {
        method: 'POST',
        headers,
        body: JSON.stringify(data)
    });

    if(response.ok){
        const json = await response.json();
        const commits = json as NetReturn;
        if(bAjax){
            Swal.close();
        }
        return commits;
    }else{
        if(bAjax){
            Swal.close();
        }
        return {
            bReturn: false,
            msgTitle: languages.networkError,
            msgBody: languages.networkErrorMsg,
            typeReturn: 0,
        }
    }
}

async function requestPostFormData(methodName: string, data: FormData, bAjax: boolean = false): Promise<NetReturn> {
    const url = `${appDeclarations.apiRootTest}${methodName}`;
    const fheaders = appFxs.getManagtDefaultHeaders();
    const headers = {...fheaders, 'Content-Type': 'application/json',}

    if(bAjax){
        appFxs.showAjaxLoader(languages.waitingforServerResp);
    }

    const response  = await window.fetch(url, {
        method: 'POST',
        headers,
        body: data,
    });

    if(response.ok){
        const json = await response.json();
        const commits = json as NetReturn;
        if(bAjax){
            Swal.close();
        }
        return commits;
    }else{
        if(bAjax){
            Swal.close();
        }
        return {
            bReturn: false,
            msgTitle: languages.networkError,
            msgBody: languages.networkErrorMsg,
            typeReturn: 0,
        }
    }
}

async function requestPostWithFile(methodName: string, data: FormData, bAjax: boolean = false): Promise<NetReturn> {
    const url = `${appDeclarations.apiRootTest}${methodName}`;
    const fheaders = appFxs.getManagtDefaultHeaders();
    const headers = {...fheaders}

    if(bAjax){
        appFxs.showAjaxLoader(languages.waitingforServerResp);
    }

    const response  = await window.fetch(url, {
        method: 'POST',
        headers,
        body: data
    });

    if(response.ok){
        const json = await response.json();
        const commits = json as NetReturn;
        if(bAjax){
            Swal.close();
        } 
        return commits;
    }else{
        if(bAjax){
            Swal.close();
        } 
        return {
            bReturn: false,
            msgTitle: languages.networkError,
            msgBody: languages.networkErrorMsg,
            typeReturn: 0,
        }
    }
}
async function requestPostWithFileAxios(methodName: string, data: FormData, bAjax: boolean = false): Promise<NetReturn> {
    const url = `${appDeclarations.apiRootTest}${methodName}`;
    const fheaders = appFxs.getManagtDefaultHeaders();
    const headers = {...fheaders, 'Content-Type': 'multipart/form-data'}


    if(bAjax){
        appFxs.showAjaxLoader(languages.waitingforServerResp);
    }

    const response = await axios.post(url, data, {
        headers: headers,
    });

    if(bAjax){
        Swal.close();
    } 
    

    if(response.status === 200){
        const commits = response.data;
        if(bAjax){
            Swal.close();
        } 
        return commits;
    }else{
        if(bAjax){
            Swal.close();
        } 
        return {
            bReturn: false,
            msgTitle: languages.networkError,
            msgBody: languages.networkErrorMsg,
            typeReturn: 0,
        }
    }

}
async function requestPexelsImage(searchKey: string, page: number, bAjax: boolean = false): Promise<NetReturn> {
    const url = `https://api.pexels.com/v1/search?per_page=40&page=${page}&query=${searchKey}`;
    const headers = {
        'Authorization':
            '563492ad6f9170000100000139aeaf71df074759b90db49f8535787a'
    };

    if(bAjax){
        appFxs.showAjaxLoader(languages.waitingforPexelsServer);
    }

    const response  = await window.fetch(url, {
        method: 'GET',
        headers
    });

    if(response.ok){
        const json = await response.json();
        const commits = json as IPexelsImageRequest;

        if(bAjax){
            Swal.close();
        }        

        return {
            bReturn: true,
            msgTitle: '',
            msgBody: '',
            typeReturn: 0,
            pexelsRequest: commits,
        };
    }else{
        if(bAjax){
            Swal.close();
        }
        return {
            bReturn: false,
            msgTitle: languages.networkError,
            msgBody: languages.networkErrorMsg,
            typeReturn: 0,
        }
    }
}

const NetServices = {
    requestGet,
    requestPost,
    requestPostWithFile,
    requestPexelsImage,
    requestPostWithFileAxios,
    requestPostFormData,
}

export default NetServices;










