/* eslint-disable react-hooks/exhaustive-deps */

import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from "react";

import geLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import {
  CalendarViewerComp,
  ErrorMsgDisplayer,
  NoRecordMsgDisplayer,
  SmallButtonRounded,
  WiseCircularActionBtn,
} from "../small_comps";
import {
  IInfluencer,
  IMonalytCampaignInfl,
  IMonalytInflCampEmail,
} from "src/models/monalytics_models";
import NetServices from "src/utils/netservices";
import LoadinInterfaceComp from "../LoadinInterfaceComp";
import { ModalWiseEmail } from "../modal_hiring_tools";
import { IHiringActiActionsRef, IHiringCompVals } from "src/models/smarttypes";

const languages = geLang();
const appFxs = generalFxs();

const emptyEmailRec: IMonalytInflCampEmail = {
  campaignKey: "",
  companyKey: "",
  createdBy: "",
  hireOperKey: "",
  influencerKey: "",
  emailBody: "",
  emailKey: "",
  operDate: new Date(),
  emailSubject: "",
  influencerEmail: "",
  otherRecipients: [],
  status: 0,
};

const ListOfWiseEmails = forwardRef((
  props: IHiringCompVals,
  ref: Ref<IHiringActiActionsRef>
) => {
  const [dlgOpen, setDlgOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const [openReady, setOpenReady] = useState(false);
  
  const [dialogRecord, setDialogRecord] = useState<IMonalytInflCampEmail>({
    ...emptyEmailRec,
  });

  const [lstOfWiseEmails, setLstOfWiseEmails] = useState<
    IMonalytInflCampEmail[]
  >([]);
  const [baseHiringOps, setBaseHiringOps] =
    useState<IMonalytCampaignInfl | null>(null);

  const onAddNewEmail = () => {
    const oRec: IMonalytInflCampEmail = {
      ...emptyEmailRec,
      campaignKey: baseHiringOps!.campaignKey,
      companyKey: baseHiringOps!.companyKey,
      createdBy: "",
      hireOperKey: baseHiringOps!.recordKey,
      influencerKey: baseHiringOps!.influencerKey,
      emailBody: "",
      emailKey: "",
      operDate: new Date(),
      emailSubject: "",
      influencerEmail: props.influencer!.inflEmail,
      otherRecipients: [],
    };
    setDialogRecord(oRec);
    setDlgOpen(true);
  };
  const populateData = async () => {
    setLoading(true);
    setMsgError("");
    setHasError(false);

    const oOper = await NetServices.requestGet(
      `hiretools/getlistemails/${props.hiringOperationKey}`
    );

    if (!oOper.bReturn) {
      setMsgError(oOper.msgBody);
      setHasError(true);
      setLoading(false);
      return;
    }
    setLstOfWiseEmails(oOper.lstOfWiseEmails!);
    setBaseHiringOps(oOper.baseHiringOps!);
    setMsgError("");
    setHasError(false);
    setLoading(false);
  };

  const onCloseDialog = (o: any) => {
    if (o) {
      const lst = o as IMonalytInflCampEmail[];
      setLstOfWiseEmails(lst);
    }

    setDlgOpen(false);
  };

  const onEmailDelete = async (k: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "hiretools/deletehiringemail",
      {
        hireEmailKey: k,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setLstOfWiseEmails(oOper.lstOfWiseEmails!);
  };

  const onOpenDialog = () => {
    if(!baseHiringOps){
      setOpenReady(true);
    }else{
      onAddNewEmail();
    }
  }


  useImperativeHandle(ref, () => ({
    onOpenDialog
  }));


  useEffect(() => {
    populateData();
  }, []);

  

  useEffect(() => {
    if(openReady){
      onOpenDialog();
    };    
  }, [baseHiringOps]);

  return (
    <>
      <ModalWiseEmail
        isOpen={dlgOpen}
        dataRecord={dialogRecord}
        dlgTitle={languages.addNewEmail}
        onClose={onCloseDialog}
      />
      <div className="flex flex-col">
        {/* Header */}
        <div className="flex items-center space-x-2">
          <WiseCircularActionBtn type="add" onAction={onAddNewEmail} />
          <span
            onClick={onAddNewEmail}
            className="text-sm cursor-pointer text-blue-500 hover:text-blue-700"
          >
            {languages.sendEmail}
          </span>
        </div>
        {/* Body */}
        <div className="flex flex-col mt-4">
          {loading && <LoadinInterfaceComp />}
          {!loading && !hasError && lstOfWiseEmails.length === 0 && (
            <NoRecordMsgDisplayer
              msg={languages.noEmailPosted}
              onAction={onAddNewEmail}
              actionTitle={languages.addNewEmail}
              btnIcon={<i className="fa fa-plus"></i>}
            />
          )}
          {!loading && hasError && (
            <ErrorMsgDisplayer
              msg={msgError}
              onAction={() => populateData()}
              actionTitle={languages.refresh}
            />
          )}
          {!loading &&
            !hasError &&
            lstOfWiseEmails.length > 0 &&
            lstOfWiseEmails.map((o, idx) => (
              <WiseEmailElt
                key={idx}
                data={o}
                onDelete={(k) => onEmailDelete(k)}
              />
            ))}
        </div>
      </div>
    </>
  );
})

export default ListOfWiseEmails;

const WiseEmailElt = ({
  data,
  onDelete,
}: {
  data: IMonalytInflCampEmail;
  onDelete: (noteKey: string) => void;
}) => {
  return (
    <div className="flex items-start mb-3 border-b">
      <div className="mr-3">
        <CalendarViewerComp calDate={new Date(data.operDate)} />
      </div>
      <div className="flex-1 ">
        <div className="tbl-item-text-sub-item">
          <div dangerouslySetInnerHTML={{ __html: data.emailBody }} />
        </div>
        <div className="flex items-center justify-between py-3">
          <div className="text-sm">
            {appFxs.formatDateTimeAlt(new Date(data.operDate))}
          </div>
          <div>
            <SmallButtonRounded
              label={languages.delete}
              destructive={true}
              onAction={() => onDelete(data.emailKey)}
              child={<i className="fa fa-trash"></i>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
