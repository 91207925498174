/* eslint-disable react-hooks/exhaustive-deps */

import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from "react";

import geLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import {
  CalendarViewerComp,
  ErrorMsgDisplayer,
  NoRecordMsgDisplayer,
  WiseCircularActionBtn,
} from "../small_comps";
import {
  IMonalytCampaignInfl,
  IMonalytInflCampEvent,
} from "src/models/monalytics_models";
import NetServices from "src/utils/netservices";
import LoadinInterfaceComp from "../LoadinInterfaceComp";
import { ModalWiseEvent } from "../modal_hiring_tools";
import { IHiringActiActionsRef, IHiringCompVals } from "src/models/smarttypes";

const languages = geLang();
const appFxs = generalFxs();

const emptyEventRec: IMonalytInflCampEvent = {
  eventKey: "",
  hireOperKey: "",
  campaignKey: "",
  companyKey: "",
  influencerKey: "",
  startDate: new Date(),
  endDate: new Date(),
  operDate: new Date(),

  eventName: "",
  eventLocation: "",
  description: "",
  createdBy: "",

  allDay: false,
  isAnniversary: false,
};

const ListOfWiseEvents = forwardRef((
  props: IHiringCompVals,
  ref: Ref<IHiringActiActionsRef>
) => {
  const [dlgOpen, setDlgOpen] = useState(false);
  const [dlgStatus, setDlgStatus] = useState(0);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const [openReady, setOpenReady] = useState(false);

  const [dialogRecord, setDialogRecord] = useState<IMonalytInflCampEvent>({
    ...emptyEventRec,
  });

  const [lstOfWiseEvents, setLstOfWiseEvents] = useState<
    IMonalytInflCampEvent[]
  >([]);
  const [baseHiringOps, setBaseHiringOps] =
    useState<IMonalytCampaignInfl | null>(null);

  const onAddOrPreviewEvent = (event?: IMonalytInflCampEvent) => {
    const oRec: IMonalytInflCampEvent = event ? {
        ...event,
        endDate: new Date(event.endDate),
        startDate: new Date(event.startDate),
        operDate: new Date(event.operDate),
    }: {
      ...emptyEventRec,
      campaignKey: baseHiringOps!.campaignKey,
      companyKey: baseHiringOps!.companyKey,
      createdBy: "",
      hireOperKey: baseHiringOps!.recordKey,
      influencerKey: baseHiringOps!.influencerKey,
      operDate: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      allDay: false,
      description: "",
      eventKey: "",
      eventLocation: "",
      eventName: "",
      isAnniversary: false,
    };
    setDialogRecord(oRec);
    setDlgStatus(event ? 1 : 0);
    setDlgOpen(true);
  };
  const populateData = async () => {
    setLoading(true);
    setMsgError("");
    setHasError(false);

    const oOper = await NetServices.requestGet(
      `hiretools/getlistevents/${props.hiringOperationKey}`
    );

    if (!oOper.bReturn) {
      setMsgError(oOper.msgBody);
      setHasError(true);
      setLoading(false);
      return;
    }
    setLstOfWiseEvents(oOper.lstOfWiseEvents!);
    setBaseHiringOps(oOper.baseHiringOps!);
    setMsgError("");
    setHasError(false);
    setLoading(false);
  };

  const onCloseDialog = (o: any) => {
    if (o) {
      const lst = o as IMonalytInflCampEvent[];
      setLstOfWiseEvents(lst);
    }

    setDlgOpen(false);
  };

  const onEventDelete = async (k: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "hiretools/deletehiringeventrecord",
      {
        hireEventKey: k,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setLstOfWiseEvents(oOper.lstOfWiseEvents!);
  };

  const onEventPreview = async (k: IMonalytInflCampEvent) => {
    const oOper = await NetServices.requestGet(
      `hiretools/getoneeventrecord/${k.eventKey}`,true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    onAddOrPreviewEvent(oOper.eventOneRecord!);
  };

  const onOpenDialog = () => {
    if(!baseHiringOps){
      setOpenReady(true);
    }else{
      onAddOrPreviewEvent();
    }
  }

  useImperativeHandle(ref, () => ({
    onOpenDialog
  }));

  useEffect(() => {
    populateData();
  }, []);

  useEffect(() => {
    if(openReady){
      onOpenDialog();
    };    
  }, [baseHiringOps]);

  return (
    <>
        <ModalWiseEvent
        isOpen={dlgOpen}
        dataRecord={dialogRecord}
        dlgTitle={languages.addPhoneLog}
        onClose={onCloseDialog}
        dlgStatus={dlgStatus}
      />
      <div className="flex flex-col">
        {/* Header */}
        <div className="flex items-center space-x-2">
          <WiseCircularActionBtn type="add" onAction={() => onAddOrPreviewEvent()} />
          <span
            onClick={() => onAddOrPreviewEvent()}
            className="text-sm cursor-pointer text-blue-500 hover:text-blue-700"
          >
            {languages.addEvent}
          </span>
        </div>
        {/* Body */}
        <div className="flex flex-col mt-4">
        {loading && <LoadinInterfaceComp />}
          {!loading && !hasError && lstOfWiseEvents.length === 0 && (
            <NoRecordMsgDisplayer
              msg={languages.noEventPosted}
              onAction={() => onAddOrPreviewEvent()}
              actionTitle={languages.addEvent}
            />
          )}
          {(!loading && hasError) && (
            <ErrorMsgDisplayer
              msg={msgError}
              onAction={() => populateData()}
              actionTitle={languages.refresh}
            />
          )}
          {!loading &&
            !hasError &&
            lstOfWiseEvents.length > 0 &&
            lstOfWiseEvents.map((o, idx) => (
              <WiseEventElt
                key={idx}
                data={o}
                onDelete={(k) => onEventDelete(k)}
                onPreview={(k) => onEventPreview(k)}
              />
            ))}
        </div>
      </div>
    </>
  );
})

export default ListOfWiseEvents;


const WiseEventElt = ({
    data,
    onDelete,
    onPreview
  }: {
    data: IMonalytInflCampEvent;
    onDelete: (noteKey: string) => void;
    onPreview: (eventRec: IMonalytInflCampEvent) => void;
  }) => {   
  
    return (
      <div className="flex items-start mb-3 border-b">
        <div className="mr-3">
          <CalendarViewerComp calDate={new Date(data.operDate)} />
        </div>
        <div className="flex-1 ">
          <div className="tbl-item-text-sub-item">
            <div className="flex items-center space-x-3">              
              <div onClick={() => onPreview(data)} className="data-[status=done]:line-through text-blue-400 font-semibold cursor-pointer">{data.eventName}</div>
              <span onClick={() => onDelete(data.eventKey)} className="cursor-pointer" title={languages.delete}>
                <i className="fa fa-trash text-red-500"></i>
              </span>
            </div>
          </div>
          <div className="flex items-center justify-between py-3">
            <div className="flex items-center space-x-3">
              <span className="text-sm">
                {appFxs.formatDateTimeAlt(new Date(data.operDate))}
              </span>
              
            </div>
          </div>
        </div>        
      </div>
    );
  };
