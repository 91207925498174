import React, { useEffect } from 'react'
import { useNavigate } from "react-router-dom";
import appDeclarations from 'src/utils/declarations';


import generalFxs from 'src/utils/general_fx';
import appLanguages from 'src/utils/languages';
import NetServices from 'src/utils/netservices';


const appFxs = generalFxs();
const languages = appLanguages();


const HomePage = () => {
  document.title = `${languages.loading} | ${languages.appName}`;
  const navigate = useNavigate();

  const checkIfConn = async () => {
    const isConnected = appFxs.getLocalStorageByKey(appDeclarations.connectionStatus, '0') === '1';
    console.log('isConnected', isConnected);
    if (!isConnected) {
      navigate('/account/login');
      return;
    }

    const oRes = await NetServices.requestGet('account/checkifconnected');
    console.log('oRes', oRes);
    if (oRes.bReturn) {
      navigate('/app');
      return;
    }

    const oMsg = await appFxs.showAlertConfirm(oRes.msgTitle, oRes.msgBody);
    if(oMsg.isConfirmed){
      navigate('/account/login');
    }   
  }

  useEffect(() => {
    checkIfConn();
  }, []);


  return (
    <div className="h-screen flex justify-center items-center">
      <i className="fa fa-spin fa-spinner fa-3x"></i>
    </div>

  )
}

export default HomePage