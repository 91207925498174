import React, { useState } from "react";

import { Button, Heading } from "@radix-ui/themes";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";

import { IAcctFormRegister, IFormError } from "src/models/smarttypes";
import { useNavigate } from "react-router-dom";

import NetServices from "src/utils/netservices";
import {
  AdminRecordForm,
  CompanyAddressRecordForm,
  CompanyRecordForm,
  ConfirmRegisterCodeForm,
  ConfirmRegistrationDataForm,
  HeaderFormComp,
  PasswordRecordForm,
  RegisterEmailForm,
} from "src/components/account_comps";

const languages = getLang();
const appFxs = generalFxs();

const defData: IAcctFormRegister = {
  adminEmail: "",

  registerCode1: "",
  registerCode2: "",

  companyEmail: "",
  companyName: "",
  shortName: "",

  firstName: "",
  lastName: "",
  displayName: "",

  password: "",
  confirmPassword: "",

  country: "RW",
  city: "",
  poBox: "",
};

const AcctRegisterPage = () => {
  document.title = `${languages.register} | StockMan`;

  const [registerStep, setRegisterStep] = useState(0);
  const [formErrors, setFormErrors] = useState<IFormError>({});
  const [formRecord, setFormRecord] = useState<IAcctFormRegister>({
    ...defData,
  });
  const [serverInUse, setServerInUse] = useState(false);

  const navigate = useNavigate();

  const updateFormData = (fld: string, val: string) => {
    setFormRecord({ ...formRecord, [fld]: val });
  };

  const onSendRegister = () => {
    if (registerStep === 0) registerWithEmail();
    if (registerStep === 1) confirmRegisterCode();
    if (registerStep === 2) acceptCompanyData();
    if (registerStep === 3) acceptCompanyAdress();
    if (registerStep === 4) acceptAdminDetails();
    if (registerStep === 5) acceptAdminPassword();
    if (registerStep === 6) registerCompAdminRec();
  };

  const registerWithEmail = async () => {
    const errors: IFormError = {};

    if (!formRecord.adminEmail || formRecord.adminEmail.length === 0) {
      errors.adminEmail = languages.fldRequired;
    } else if (!appFxs.isEmailValid(formRecord.adminEmail)) {
      errors.adminEmail = languages.emailFormat;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);

    const oOper = await NetServices.requestPost("account/getstartedwithemail", {
      adminEmail: formRecord.adminEmail,
    });

    if (!oOper.bReturn) {
      const bDismiss = (
        await appFxs.showAlert(
          oOper.msgTitle,
          oOper.msgBody,
          languages.ok,
          true
        )
      ).isDismissed;

      if (bDismiss && oOper.typeReturn === 1200) {
        setServerInUse(false);
        setRegisterStep(1);
        return;
      }

      setServerInUse(false);
      return;
    }

    setServerInUse(false);
    setRegisterStep(1);
  };
  const confirmRegisterCode = async () => {
    const errors: IFormError = {};

    if (!formRecord.adminEmail) {
      errors.adminEmail = languages.fldRequired;
    } else if (!appFxs.isEmailValid(formRecord.adminEmail)) {
      errors.adminEmail = languages.emailFormat;
    }

    if (!formRecord.registerCode1 || formRecord.registerCode1.length === 0) {
      errors.registerCode1 = languages.fldRequired;
    }

    if (!formRecord.registerCode2 || formRecord.registerCode2.length === 0) {
      errors.registerCode2 = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);

    const oOper = await NetServices.requestPost(
      "account/getstartedcheckregisteremailcode",
      {
        adminEmail: formRecord.adminEmail,
        registerCodes: `${formRecord.registerCode1}${formRecord.registerCode2}`,
      }
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setServerInUse(false);
      return;
    }

    setServerInUse(false);
    setRegisterStep(2);
  };
  const acceptCompanyData = () => {
    const errors: IFormError = {};

    if (!formRecord.companyEmail) {
      errors.companyEmail = languages.fldRequired;
    } else if (!appFxs.isEmailValid(formRecord.companyEmail)) {
      errors.companyEmail = languages.emailFormat;
    }

    if (!formRecord.companyName || formRecord.companyName.length === 0) {
      errors.companyName = languages.fldRequired;
    }

    if (!formRecord.shortName || formRecord.shortName.length === 0) {
      errors.shortName = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);

    setTimeout(() => {
      setRegisterStep(3);
      setServerInUse(false);
    }, 300);
  };
  const acceptCompanyAdress = () => {
    const errors: IFormError = {};

    if (!formRecord.country) {
      errors.country = languages.fldRequired;
    }

    if (!formRecord.city || formRecord.city.length === 0) {
      errors.city = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);

    setTimeout(() => {
      setRegisterStep(4);
      setServerInUse(false);
    }, 300);
  };
  const acceptAdminDetails = () => {
    const errors: IFormError = {};

    if (!formRecord.firstName || formRecord.firstName.length === 0) {
      errors.firstName = languages.fldRequired;
    }
    if (!formRecord.lastName || formRecord.lastName.length === 0) {
      errors.lastName = languages.fldRequired;
    }

    if (!formRecord.shortName || formRecord.shortName.length === 0) {
      errors.shortName = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);

    setTimeout(() => {
      setRegisterStep(5);
      setServerInUse(false);
    }, 300);
  };
  const acceptAdminPassword = () => {
    const errors: IFormError = {};

    const fChecker = appFxs.getThePassStatus(formRecord.password);

    if (!formRecord.password || formRecord.password.length === 0) {
      errors.password = languages.fldRequired;
    } else if (
      fChecker.lenChars.status !== "high" ||
      fChecker.percentVal < 80
    ) {
      errors.password = languages.passValidMsg;
    }

    if (
      !formRecord.confirmPassword ||
      formRecord.confirmPassword.length === 0
    ) {
      errors.confirmPassword = languages.fldRequired;
    } else if (formRecord.confirmPassword !== formRecord.password) {
      errors.confirmPassword = languages.confirmPaswordError;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);

    setTimeout(() => {
      setRegisterStep(6);
      setServerInUse(false);
    }, 300);
  };
  const registerCompAdminRec = async () => {
    const bAsk = await appFxs.showConfirm(
      languages.register,
      languages.registerAcctMsg
    );
    if (!bAsk.isConfirmed) return;

    const data: IAcctFormRegister = {
      ...formRecord,
      confirmPassword: formRecord.password,
    };

    setServerInUse(true);
    const oOper = await NetServices.requestPost("account/registeraccount", {
      registerAccount: data,
    });

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setServerInUse(false);
      return;
    }

    navigate("/account/login");
  };

  return (
    <div className="w-full max-w-md shadow-lg py-3 px-5 bg-white rounded-md">
      <div className="w-full flex flex-col space-y-6">
        <Heading className="text-center text-base" size="1">
          {languages.register}
        </Heading>

        {registerStep === 0 && <HeaderFormComp />}

        <div>
          {registerStep === 0 && (
            <RegisterEmailForm
              value={formRecord.adminEmail}
              applyVal={updateFormData}
              errorMsg={formErrors.adminEmail}
              disabled={serverInUse}
            />
          )}

          {registerStep === 1 && (
            <ConfirmRegisterCodeForm
              formErrors={formErrors}
              formRecord={formRecord}
              serverInUse={serverInUse}
              updateFormData={updateFormData}
            />
          )}

          {registerStep === 2 && (
            <CompanyRecordForm
              formErrors={formErrors}
              formRecord={formRecord}
              serverInUse={serverInUse}
              updateFormData={updateFormData}
            />
          )}

          {registerStep === 3 && (
            <CompanyAddressRecordForm
              formErrors={formErrors}
              formRecord={formRecord}
              serverInUse={serverInUse}
              updateFormData={updateFormData}
            />
          )}

          {registerStep === 4 && (
            <AdminRecordForm
              formErrors={formErrors}
              formRecord={formRecord}
              serverInUse={serverInUse}
              updateFormData={updateFormData}
            />
          )}

          {registerStep === 5 && (
            <PasswordRecordForm
              formErrors={formErrors}
              formRecord={formRecord}
              serverInUse={serverInUse}
              updateFormData={updateFormData}
            />
          )}

          {registerStep === 6 && (
            <ConfirmRegistrationDataForm
              formErrors={formErrors}
              formRecord={formRecord}
              serverInUse={serverInUse}
              updateFormData={updateFormData}
            />
          )}
        </div>
      </div>

      <div className="w-full">
        <Button
          size="3"
          variant="soft"
          onClick={onSendRegister}
          className="cursor-pointer w-full flex items-center"
          disabled={serverInUse}
        >
          {serverInUse && <i className="fa fa-spin fa-spinner"></i>}
          <span className="text-sm font-semibold">
            {registerStep === 0
              ? languages.getStarted
              : registerStep === 1
              ? languages.confirm
              : registerStep === 2
              ? languages.next
              : registerStep === 6
              ? languages.register
              : languages.next}
          </span>
        </Button>
      </div>
    </div>
  );
};

export default AcctRegisterPage;
