/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import NetServices from "src/utils/netservices";
import GeneralFxs from "src/utils/general_fx";
import GetLangs from "src/utils/languages";
import appDecl from "src/utils/declarations";
import {
  IInfluencer,
  IMonalytCampaign,
  IMonalytCampaignInfl,
} from "src/models/monalytics_models";

import { ReactComponent as HorizMore } from "src/assets/images/svg/horizontal_more.svg";
import profileImg from "src/assets/images/user_profile.png";
import logoImg from "src/assets/images/campaign_logo.png";
import wallImg from "src/assets/images/wall_image.jpg";
import {
  DropDownMenuItemRadix,
  ErrorMsgDisplayer,
  HashtagEltComp,
  LabelWithData,
  NoRecordMsgDisplayer,
} from "src/components/small_comps";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";
import { IMonalytUser } from "src/models/general_models";
import { listOfTempoInfluencers } from "src/utils/tempo_data";
import { ModalCampHireInfluencer } from "src/components/modal_comps";
import { DropdownMenu } from "@radix-ui/themes";
import { DropDownMenuAction, IMenuDropDownAct } from "src/models/smarttypes";

const appFxs = GeneralFxs();
const languages = GetLangs();
const listMonths = appFxs.wdMonthsLng;

const defaultHireInfl: IMonalytCampaignInfl = {
  campaignKey: "",
  companyKey: "",
  contractPrice: 0,
  hiredDate: new Date(),
  influencerKey: "",
  paytStatus: "NotPaid",
  recordKey: "",
  createdBy: "",
  hireStatus: 0,
};

const currencyCode = appFxs.getLocalStorageByKey(appDecl.currentDevise, "RWF");

const CampaignEditorPage = () => {
  const { campaignKey } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [currentCampaign, setCurrentCampaign] =
    useState<IMonalytCampaign | null>(null);
  const [relatedUser, setRelatedUser] = useState<IMonalytUser | null>(null);

  const doctImageWall = useMemo(() => {
    if (!currentCampaign) return wallImg;
    if (!currentCampaign.campaignWall) return wallImg;
    const wallPath = `${appDecl.campaignRes}/${currentCampaign!.companyKey}/${
      currentCampaign!.campaignWall
    }`;
    return wallPath;
  }, [currentCampaign]);

  const doctImageLogo = useMemo(() => {
    if (!currentCampaign) return logoImg;
    if (!currentCampaign.campaignLogo) return logoImg;
    const logoPath = `${appDecl.campaignRes}/${currentCampaign!.companyKey}/${
      currentCampaign!.campaignLogo
    }`;
    return logoPath;
  }, [currentCampaign]);

  const campaignStatus = useMemo(() => {
    if (!currentCampaign) return languages.inactive;
    if (new Date().getTime() > new Date(currentCampaign.endDate).getTime())
      return languages.inactive;

    return currentCampaign.active ? languages.active : languages.inactive;
  }, [currentCampaign]);

  const prepareData = async () => {
    setLoading(true);

    const oOper = await NetServices.requestGet(
      `monalytics/getonecampaignrecord/${campaignKey}`
    );
    if (!oOper.bReturn) {
      await appFxs.showAlertConfirm(oOper.msgTitle, oOper.msgBody);
      navigate("/app/campaign");
      return;
    }

    setCurrentCampaign(oOper.campaignRecord!);
    setRelatedUser(oOper.relatedUser!);

    setLoading(false);
  };

  const printRecord = () => {};
  const deleteRecord = async () => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;
    const oOper = await NetServices.requestPost(
      "monalytics/deletecampaignwithresources",
      {
        campaignKey: currentCampaign?.campaignKey,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    navigate("/app/campaign");
  };

  useEffect(() => {
    prepareData();
  }, []);

  return (
    <div className="flex flex-col p-4">
      <div className="flex items-center justify-between py-2 border-b">
        <div>
          {loading ? (
            languages.loading
          ) : (
            <div className="flex flex-row items-center space-x-4">
              <div>
                <Link to="/app/campaign">
                  <i className="fa fa-arrow-left fa-2x"></i>
                </Link>
              </div>
              <div className="flex flex-col flex-1">
                <h3 className="font-bold text-lg">{currentCampaign!.title}</h3>
                <div className="text-sm flex items-center space-x-2">
                  <div className="flex items-center">
                    {languages.postedDate}: &nbsp;
                    <strong>
                      {appFxs.formatDate(currentCampaign!.postedDate)}
                    </strong>
                  </div>

                  <div className="flex items-center">
                    {languages.budget}: &nbsp;
                    <strong>
                      {currencyCode}&nbsp;
                      {appFxs.formatNumbers(currentCampaign!.budget)}
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="flex items-center gap-x-2">
          {!loading && (
            <button
              className="btn-wise"
              onClick={() => printRecord()}
              title={languages.print}
            >
              <i className="fa fa-print"></i>
            </button>
          )}
          {!loading && (
            <button
              className="btn-wise"
              onClick={() => deleteRecord()}
              title={languages.delete}
            >
              <i className="fa fa-trash text-red-600"></i>
            </button>
          )}
        </div>
      </div>
      {!currentCampaign && <LoadinInterfaceComp />}
      {currentCampaign && (
        <div className="flex flex-wrap">
          <div className="w-full md:w-2/3">
            <div className="p-2">
              <div className="flex flex-col">
                <div className="mt-3 mb-5 w-full h-[280px] md:w-[450px] mx-auto border rounded flex items-center justify-center relative">
                  <img
                    className="z-[1] w-full h-full absolute object-cover"
                    alt=""
                    src={doctImageWall}
                  />
                  <div className="shadow-lg border-8 rounded-full overflow-hidden w-[150px] h-[150px] z-10 bg-white relative">
                    <img
                      className="z-[1] w-full h-full absolute object-cover"
                      alt=""
                      src={doctImageLogo}
                    />
                  </div>
                </div>

                <div className="flex flex-row flex-wrap justify-between  md:mb-3">
                  <div className="w-full md:w-[49%] mb-3 md:mb-0">
                    <LabelWithData
                      orient="row"
                      caption={languages.postedBy}
                      data={`${relatedUser?.firstName} ${relatedUser?.lastName}`}
                    />
                  </div>
                  <div className="w-full md:w-[49%] mb-3 md:mb-0">
                    <LabelWithData
                      orient="row"
                      caption={languages.status}
                      data={campaignStatus}
                    />
                  </div>
                </div>
                <div className="flex flex-row flex-wrap justify-between md:mb-3">
                  <div className="w-full md:w-[49%] mb-3 md:mb-0">
                    <LabelWithData
                      orient="row"
                      caption={languages.start}
                      data={appFxs.formatDate(currentCampaign!.startDate)}
                    />
                  </div>
                  <div className="w-full md:w-[49%] mb-3 md:mb-0">
                    <LabelWithData
                      orient="row"
                      caption={languages.end}
                      data={appFxs.formatDate(currentCampaign!.endDate)}
                    />
                  </div>
                </div>
                <div className="flex flex-row flex-wrap justify-between md:mb-3">
                  <div className="w-full md:w-[49%] mb-3 md:mb-0">
                    <LabelWithData
                      orient="row"
                      caption={languages.period}
                      data={listMonths[currentCampaign!.fiscalPeriod - 1]}
                    />
                  </div>
                  <div className="w-full md:w-[49%] mb-3 md:mb-0">
                    <LabelWithData
                      orient="row"
                      caption={languages.year}
                      data={currentCampaign!.fiscalYear.toString()}
                    />
                  </div>
                </div>
                <div className="flex flex-row flex-wrap justify-between md:mb-3">
                  <div className="flex flex-col mb-3 space-y-1">
                    <span className="font-semibold text-sm">
                      {languages.hashtag}
                    </span>
                    <div className="flex flex-wrap items-center space-x-2 px-2 py-2 border rounded-lg">
                      {currentCampaign?.hashtag.map((t, i) => (
                        <HashtagEltComp
                          key={i}
                          data={currentCampaign}
                          tag={t}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                <div className="mb-3">
                  <LabelWithData
                    orient="col"
                    caption={languages.description}
                    data={currentCampaign!.description}
                    isArea={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/3  border-l">
            {currentCampaign && (
              <ListOfCampHiredInfluencers
                campaign={currentCampaign}
                publisher={relatedUser!}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CampaignEditorPage;

interface IListOfCampHiredInfluencers {
  campaign: IMonalytCampaign;
  publisher: IMonalytUser;
}

const ListOfCampHiredInfluencers = ({
  campaign,
  publisher,
}: IListOfCampHiredInfluencers) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [haseError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const [dlgHireOpen, setDlgHireOpen] = useState(false);
  const [dlgHireStatus, setDlgHireStatus] = useState(0);

  const [recordToHire, setRecordToHire] = useState<IMonalytCampaignInfl>({
    ...defaultHireInfl,
  });

  const [lstInfluencers, setLstInfluencers] = useState<IInfluencer[]>(
    listOfTempoInfluencers
  );
  const [lstHiredInflencers, setLstHiredInflencers] = useState<
    IMonalytCampaignInfl[]
  >([]);

  const prepareData = async () => {
    setLoading(true);
    setHasError(false);
    setMsgError("");
    const oOper = await NetServices.requestGet(
      `monalytics/getlisthiredinluencersbycampaign/${campaign.campaignKey}`
    );

    if (!oOper.bReturn) {
      setHasError(true);
      setMsgError(oOper.msgBody);
      setLoading(false);
      return;
    }
    setLstHiredInflencers(oOper.lstHiredInflencers!);

    setHasError(false);
    setMsgError("");
    setLoading(false);
  };

  const openInfluencerDlg = (
    data: IMonalytCampaignInfl | undefined = undefined
  ) => {
    const vData = data ?? { ...defaultHireInfl };
    const iStat = data ? 1 : 0;

    setRecordToHire({
      ...vData,
      companyKey: campaign.companyKey,
      campaignKey: campaign.campaignKey,
      createdBy: "",
    });
    setDlgHireOpen(true);
    setDlgHireStatus(iStat);
  };

  const onCloseDlg = (o: any) => {
    if (o) {
      const lst = o as IMonalytCampaignInfl[];
      setLstHiredInflencers(lst);
    }

    setDlgHireOpen(false);
  };

  const onMenuAct = (operKey: string, action: DropDownMenuAction) => {
    if (action === "delete") deleteHireOper(operKey);
    if (action === "edit") editHireOper(operKey);
    if (action === "preview") previewHiredOper(operKey);
  };

  const deleteHireOper = async (operKey: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;
    const oOper = await NetServices.requestPost(
      "monalytics/deletehireinfluencerbyoperkey",
      {
        hireInfluencerOperKey: operKey,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setLstHiredInflencers(oOper.lstHiredInflencers!);
  };
  const editHireOper = async (operKey: string) => {
    const editRec = lstHiredInflencers.find((o) => o.recordKey === operKey);
    if (!editRec) {
      appFxs.showAlert(languages.mismatchRecord, languages.mismatchRecordMsg);
      return;
    }

    setRecordToHire({
      ...editRec,
      hiredDate: new Date(editRec.hiredDate),
    });
    setDlgHireOpen(true);
    setDlgHireStatus(1);
  };
  const previewHiredOper = async (operKey: string) => {
    navigate(`/app/campaign/management/${operKey}`);
  };

  useEffect(() => {
    prepareData();
  }, []);

  return (
    <>
      <ModalCampHireInfluencer
        isOpen={dlgHireOpen}
        onClose={onCloseDlg}
        dataRecord={recordToHire}
        influencers={lstInfluencers}
        dialogStatus={dlgHireStatus}
      />
      <div className="flex flex-col">
        <div className="flex items-center justify-between px-1 py-2 border-b">
          <h3 className="font-semibold text-base">{languages.hiredInfl}</h3>
          <button
            className="btn-wise"
            title={languages.hireInfl}
            onClick={() => openInfluencerDlg()}
          >
            <i className="fa fa-plus"></i>
          </button>
        </div>
        <div className="">
          {loading && (
            <div className="flex items-center justify-center h-20">
              <i className="fa fa-spin fa-spinner fa-3x"></i>
            </div>
          )}
          {!loading && !haseError && lstHiredInflencers.length === 0 && (
            <NoRecordMsgDisplayer
              onAction={() => openInfluencerDlg()}
              msg={languages.noCampInfl}
              actionTitle={languages.hireInfl}
            />
          )}
          {!loading && !haseError && lstHiredInflencers.length > 0 && (
            <ul className="flex flex-col">
              {lstHiredInflencers.map((o, idx) => (
                <HiredInfluencerComp
                  data={o}
                  key={idx}
                  infl={lstInfluencers}
                  onMenuAct={onMenuAct}
                />
              ))}
            </ul>
          )}

          {!loading && haseError && (
            <ErrorMsgDisplayer msg={msgError} onAction={() => prepareData()} />
          )}
        </div>
      </div>
    </>
  );
};

interface IHiredInfluencerComp {
  data: IMonalytCampaignInfl;
  infl: IInfluencer[];
  onMenuAct: (operKey: string, action: DropDownMenuAction) => void;
}

const HiredInfluencerComp = ({
  data,
  infl,
  onMenuAct,
}: IHiredInfluencerComp) => {
  const inflName = infl.find((o) => o.influencerKey === data.influencerKey);
  const imgPath = !inflName?.photoProfile
    ? profileImg
    : `${appDecl.influencerProfilePath}/${inflName?.photoProfile}`;

  const appMenus: IMenuDropDownAct[] = [
    {
      actionType: "preview",
      label: languages.preview,
      icon: "fa fa-eye",
    },
    { actionType: "edit", label: languages.edit, icon: "fa fa-pencil" },
    {
      actionType: "delete",
      label: languages.delete,
      destructive: true,
      icon: "fa fa-trash",
      separator: true,
    },
  ];

  return (
    <li>
      <div className="flex items-start border-b py-2 px-2">
        <div className="flex-1 flex items-center space-x-2">
          <div className="w-9 h-9 rounded-full overflow-hidden">
            <img className="w-full h-full" alt="" src={imgPath} />
          </div>
          <div className="flex-1 flex flex-col">
            <div className="text-sm ">
              {`${inflName?.firstName} ${inflName?.lastName}`} &nbsp;(
              <strong>
                {data.hireStatus === 0
                  ? languages.inactive.toLowerCase()
                  : languages.active.toLowerCase()}
              </strong>
              )
            </div>
            <div className="flex items-center space-x-2 text-xs">
              <strong>{currencyCode}</strong>&nbsp;
              {appFxs.formatNumbers(data.contractPrice)}
              <strong>{languages.hired}</strong>:&nbsp;
              {appFxs.formatDate(data.hiredDate)}
            </div>
          </div>
        </div>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <button>
              <HorizMore className="w-5 h-5" />
            </button>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            {appMenus.map((o, idx) => (
              <DropDownMenuItemRadix
              key={idx}
                menu={o}
                onMenuAct={(act) => onMenuAct(data.recordKey, act)}
              />
            ))}
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </li>
  );
};
