/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useMemo, useState } from "react";

import { convertToHTML } from "draft-convert";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { WiseModal } from "./modal_comps";
import { WiseSmallButton } from "./ButtonLinkWithIcon";

import getLanguages from "src/utils/languages";
import getApps from "src/utils/general_fx";
import {
  IMonalytInflCampEmail,
  IMonalytInflCampEvent,
  IMonalytInflCampFinance,
  IMonalytInflCampNote,
  IMonalytInflCampPhoneCall,
  IMonalytInflCampSms,
  IMonalytInflCampToDo,
  ISMSSetting,
} from "src/models/monalytics_models";
import { IFormError } from "src/models/smarttypes";
import NetServices from "src/utils/netservices";
import WiseModalTextInput, {
  BootstrapInput,
  WiseSwitchFormElt,
} from "./WiseModalTextInput";
import WiseTagsInput from "./WiseTagsInput";
import { IMonalytUser } from "src/models/general_models";

const languages = getLanguages();
const appFxs = getApps();

interface IModalWiseNote {
  dlgTitle: string;
  isOpen: boolean;
  onClose: (retData: any) => void;
  dataRecord: IMonalytInflCampNote;
}
export const ModalWiseNote = ({
  dataRecord,
  isOpen,
  onClose,
  dlgTitle,
}: IModalWiseNote) => {
  const [saving, setSaving] = useState(false);
  /* const [dataForm, setDataForm] = useState<IMonalytInflCampNote>({
    ...dataRecord,
  }); */
  const [formErrors, setFormErrors] = useState<IFormError>({});
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [htmlContent, setHtmlContent] = useState("");

  const onEditorStateChange = (oState: EditorState) => {
    setEditorState(oState);
    let html = convertToHTML(oState.getCurrentContent());
    setHtmlContent(html);
  };

  const onSaveNoteRecord = async () => {
    const errors: IFormError = {};

    if (!htmlContent || htmlContent.length === 0) {
      errors.htmlContent = languages.fldRequired;
    } else {
      const f = appFxs.getTextFromHtml(htmlContent);
      if (!f || f.length === 0) {
        errors.htmlContent = languages.fldRequired;
      }
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    const finalRecord: IMonalytInflCampNote = {
      noteKey: appFxs.getRandomDigits(25),
      hireOperKey: dataRecord.hireOperKey,
      campaignKey: dataRecord.campaignKey,
      companyKey: dataRecord.companyKey,
      createdBy: dataRecord.createdBy,
      influencerKey: dataRecord.influencerKey,
      note: htmlContent,
      operDate: new Date(),
    };

    const oOper = await NetServices.requestPost("hiretools/savehiringnote", {
      wiseNoteRecord: finalRecord,
    });

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    onClose(oOper.lstOfWiseNotes!);
  };

  useEffect(() => {
    //setDataForm({ ...dataRecord });
    setSaving(false);
    setFormErrors({});
  }, [isOpen]);

  return (
    <WiseModal
      hasFooter={false}
      isOpen={isOpen}
      onClose={onClose}
      titleBox={dlgTitle}
    >
      <div className="flex flex-col py-2 space-y-2 px-2">
        <div className="flex flex-col">
          <div className="uppercase font-bold mb-3">{languages.note}</div>
          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            wrapperClassName="draft-wrapper-class"
            editorClassName="draft-editor-class"
            toolbarClassName="draft-toolbar-class"
          />
          {formErrors.htmlContent && (
            <p className="mb-1 text-sm text-red-500">
              {formErrors.htmlContent}
            </p>
          )}

          <div className="flex items-center justify-end space-x-3 py-3 px-2">
            <WiseSmallButton
              color="blue"
              label={languages.save}
              icon={
                <i
                  className={`fa ${saving ? "fa-spin fa-spinner" : "fa-save"} `}
                ></i>
              }
              onAction={() => onSaveNoteRecord()}
              disabled={saving}
            />
            <WiseSmallButton
              label={languages.cancel}
              onAction={() => onClose(null)}
              disabled={saving}
            />
          </div>
        </div>
      </div>
    </WiseModal>
  );
};

interface IModalWiseEmail {
  dlgTitle: string;
  isOpen: boolean;
  onClose: (retData: any) => void;
  dataRecord: IMonalytInflCampEmail;
}
export const ModalWiseEmail = ({
  dataRecord,
  isOpen,
  onClose,
  dlgTitle,
}: IModalWiseEmail) => {
  const [saving, setSaving] = useState(false);
  const [dataForm, setDataForm] = useState<IMonalytInflCampEmail>({
    ...dataRecord,
  });
  const [formErrors, setFormErrors] = useState<IFormError>({});
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [htmlContent, setHtmlContent] = useState("");

  const onEditorStateChange = (oState: EditorState) => {
    setEditorState(oState);
    let html = convertToHTML(oState.getCurrentContent());
    setHtmlContent(html);
  };

  const onSendEmailRecord = async () => {
    const errors: IFormError = {};

    if (!dataForm.emailSubject || dataForm.emailSubject.length === 0) {
      errors.emailSubject = languages.fldRequired;
    }

    if (!htmlContent || htmlContent.length === 0) {
      errors.htmlContent = languages.fldRequired;
    } else {
      const f = appFxs.getTextFromHtml(htmlContent);
      if (!f || f.length === 0) {
        errors.htmlContent = languages.fldRequired;
      }
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    const finalRecord: IMonalytInflCampEmail = {
      emailKey: appFxs.getRandomDigits(25),
      hireOperKey: dataForm.hireOperKey,
      campaignKey: dataForm.campaignKey,
      companyKey: dataForm.companyKey,
      createdBy: dataForm.createdBy,
      influencerKey: dataForm.influencerKey,
      emailBody: htmlContent,
      operDate: new Date(),
      influencerEmail: dataForm.influencerEmail,
      emailSubject: dataForm.emailSubject,
      otherRecipients: dataForm.otherRecipients,
      status: dataForm.status,
    };

    const oOper = await NetServices.requestPost("hiretools/sendhiringemail", {
      wiseEmailRecord: finalRecord,
    });

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    onClose(oOper.lstOfWiseEmails!);
  };

  const onChange = (f: string, v: string | number) => {
    setDataForm({ ...dataForm, [f]: v });
  };
  const onTagsChange = (f: string, v: string[]) => {
    setDataForm({ ...dataForm, [f]: v });
  };

  useEffect(() => {
    const fRecord: IMonalytInflCampEmail = {
      emailKey: appFxs.getRandomDigits(25),
      hireOperKey: dataRecord.hireOperKey,
      campaignKey: dataRecord.campaignKey,
      companyKey: dataRecord.companyKey,
      createdBy: dataRecord.createdBy,
      influencerKey: dataRecord.influencerKey,
      emailBody: htmlContent,
      operDate: new Date(),
      influencerEmail: dataRecord.influencerEmail,
      emailSubject: dataRecord.emailSubject,
      otherRecipients: dataRecord.otherRecipients,
      status: dataRecord.status,
    };
    setEditorState(EditorState.createEmpty());
    setHtmlContent("");
    setDataForm(fRecord);
    setSaving(false);
    setFormErrors({});
  }, [isOpen]);

  return (
    <WiseModal
      hasFooter={false}
      isOpen={isOpen}
      onClose={onClose}
      titleBox={dlgTitle}
    >
      <div className="flex flex-col py-2 space-y-2 px-2">
        <div className="flex flex-col">
          <div className="uppercase font-bold mb-3">{languages.email}</div>
          <div>
            <BootstrapInput
              fieldName="emailSubject"
              value={dataForm.emailSubject}
              label={languages.subject}
              fldType="text"
              errorText={formErrors.emailSubject}
              onChange={onChange}
              disabled={saving}
              showLabel={true}
              placeholder={languages.subject}
            />
          </div>

          <div className="mt-3">
            <WiseTagsInput
              fieldName="otherRecipients"
              label={languages.otherRecips}
              errorText={formErrors.otherRecips}
              value={dataForm.otherRecipients ?? []}
              disabled={saving}
              placeholder={languages.recipients}
              showIcon={true}
              onChange={onTagsChange}
              spaceNotAllowed={true}
              showLabel={true}
              checkEmailVerif={true}
              capsCaptions={true}
              smallFonts={true}
            />
          </div>

          <Editor
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
            wrapperClassName="draft-wrapper-class"
            editorClassName="draft-editor-class draft-small-min"
            toolbarClassName="draft-toolbar-class"
          />
          {formErrors.htmlContent && (
            <p className="mb-1 text-sm text-red-500">
              {formErrors.htmlContent}
            </p>
          )}

          <div className="flex items-center justify-end space-x-3 py-3 px-2">
            <WiseSmallButton
              color="blue"
              label={languages.send}
              icon={
                <i
                  className={`fa ${saving ? "fa-spin fa-spinner" : "fa-send"} `}
                ></i>
              }
              onAction={() => onSendEmailRecord()}
              disabled={saving}
            />
            <WiseSmallButton
              label={languages.cancel}
              onAction={() => onClose(null)}
              disabled={saving}
            />
          </div>
        </div>
      </div>
    </WiseModal>
  );
};

interface IModalWisePhoneCall {
  dlgTitle: string;
  isOpen: boolean;
  onClose: (retData: any) => void;
  dataRecord: IMonalytInflCampPhoneCall;
}

export const ModalWisePhoneCall = ({
  dataRecord,
  isOpen,
  onClose,
  dlgTitle,
}: IModalWisePhoneCall) => {
  const [saving, setSaving] = useState(false);
  const [dataForm, setDataForm] = useState<IMonalytInflCampPhoneCall>({
    ...dataRecord,
  });
  const [formErrors, setFormErrors] = useState<IFormError>({});

  const onSendPhoneRecord = async () => {
    const errors: IFormError = {};

    if (!dataForm.callDetails || dataForm.callDetails.length === 0) {
      errors.emailSubject = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    const oOper = await NetServices.requestPost(
      "hiretools/savephonecallrecord",
      {
        wisePhoneCallRecord: dataForm,
      }
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    onClose(oOper.lstOfWisePhoneCalls!);
  };

  const onChange = (f: string, v: string | number) => {
    setDataForm({ ...dataForm, [f]: v });
  };

  useEffect(() => {
    setDataForm({ ...dataRecord });
    setSaving(false);
    setFormErrors({});
  }, [isOpen]);

  return (
    <WiseModal
      hasFooter={false}
      isOpen={isOpen}
      onClose={onClose}
      titleBox={dlgTitle}
    >
      <div className="flex flex-col py-2 space-y-2 px-2">
        <div className="flex flex-col">
          <div>
            <WiseModalTextInput
              fieldName="callDetails"
              value={dataForm.callDetails}
              label={languages.callDetails}
              fldType="textarea"
              errorText={formErrors.callDetails}
              graphicType="note"
              onChange={onChange}
              disabled={saving}
              showLabel={true}
              placeholder={languages.description}
              capCaption={true}
              maxLength={5000}
            />
          </div>

          <div>
            <WiseModalTextInput
              fieldName="callDate"
              value={appFxs.getDateForInputLocal(dataForm.callDate)}
              label={languages.callDate}
              fldType="datetime-local"
              errorText={formErrors.callDetails}
              graphicType="calendar"
              onChange={onChange}
              disabled={saving}
              showLabel={true}
              placeholder={languages.description}
              capCaption={true}
            />
          </div>

          <div className="flex items-center justify-end space-x-3 py-3 px-2">
            <WiseSmallButton
              color="blue"
              label={languages.send}
              icon={
                <i
                  className={`fa ${saving ? "fa-spin fa-spinner" : "fa-send"} `}
                ></i>
              }
              onAction={() => onSendPhoneRecord()}
              disabled={saving}
            />
            <WiseSmallButton
              label={languages.cancel}
              onAction={() => onClose(null)}
              disabled={saving}
            />
          </div>
        </div>
      </div>
    </WiseModal>
  );
};

interface IModalWiseToDo {
  dlgTitle: string;
  isOpen: boolean;
  onClose: (retData: any) => void;
  dataRecord: IMonalytInflCampToDo;
  lstAllUsers: IMonalytUser[];
  dlgStatus: number;
}
export const ModalWiseToDo = ({
  dataRecord,
  dlgTitle,
  isOpen,
  onClose,
  lstAllUsers,
  dlgStatus,
}: IModalWiseToDo) => {
  const fUsers = lstAllUsers.map((o) => {
    return {
      label: `${o.firstName} ${o.lastName}`,
      value: o.userEmail,
    };
  });
  const users = [...fUsers, { value: "", label: "" }];

  const [saving, setSaving] = useState(false);
  const [dataForm, setDataForm] = useState<IMonalytInflCampToDo>({
    ...dataRecord,
  });
  const [formErrors, setFormErrors] = useState<IFormError>({});

  const onSaveToDo = async () => {
    const errors: IFormError = {};

    if (!dataForm.todoTitle || dataForm.todoTitle.length === 0) {
      errors.todoTitle = languages.fldRequired;
    }
    if (!dataForm.notes || dataForm.notes.length === 0) {
      errors.notes = languages.fldRequired;
    }
    if (!dataForm.assignedTo || dataForm.assignedTo.length === 0) {
      errors.assignedTo = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    const oOper = await NetServices.requestPost("hiretools/savetodorecord", {
      wiseToDoRecord: dataForm,
    });

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    onClose(oOper.lstOfWiseTodos!);
  };

  const onChange = (f: string, v: string | number) => {
    if (f === "dueDate") {
      setDataForm({ ...dataForm, [f]: new Date(v) });
    } else {
      setDataForm({ ...dataForm, [f]: v });
    }
  };

  useEffect(() => {
    setDataForm({ ...dataRecord });
    setSaving(false);
    setFormErrors({});
  }, [isOpen]);

  return (
    <WiseModal
      hasFooter={false}
      isOpen={isOpen}
      onClose={onClose}
      titleBox={dlgTitle}
    >
      <div className="flex flex-col py-2 space-y-2 px-2">
        <div className="flex flex-col space-y-3">
          {/* Body */}
          <BootstrapInput
            fieldName="todoTitle"
            label={languages.todo}
            fldType="text"
            value={dataForm.todoTitle}
            disabled={saving || dlgStatus !== 0}
            errorText={formErrors.todoTitle}
            placeholder={languages.todo}
            showLabel={true}
            onChange={onChange}
          />

          <div className="flex justify-between">
            <div className="w-full md:w-[49%]">
              <BootstrapInput
                fieldName="assignedTo"
                label={languages.assignedTo}
                fldType="select"
                value={dataForm.assignedTo}
                disabled={saving || dlgStatus !== 0}
                errorText={formErrors.assignedTo}
                showLabel={true}
                onChange={onChange}
                selectOptions={users}
              />
            </div>
            <div className="w-full md:w-[49%]">
              <BootstrapInput
                fieldName="dueDate"
                label={languages.dueDate}
                fldType="datetime-local"
                value={appFxs.getDateForInputLocal(dataForm.dueDate)}
                disabled={saving || dlgStatus !== 0}
                errorText={formErrors.dueDate}
                showLabel={true}
                onChange={onChange}
                selectOptions={users}
              />
            </div>
          </div>

          <BootstrapInput
            fieldName="notes"
            label={languages.notes}
            fldType="textarea"
            value={dataForm.notes}
            disabled={saving || dlgStatus !== 0}
            errorText={formErrors.notes}
            placeholder={languages.notes}
            showLabel={true}
            onChange={onChange}
            rows={4}
          />

          {/* Footer */}
          <div className="flex items-center justify-end space-x-3 py-3 px-2">
            {dlgStatus === 0 && (
              <WiseSmallButton
                color="blue"
                label={languages.send}
                icon={
                  <i
                    className={`fa ${
                      saving ? "fa-spin fa-spinner" : "fa-send"
                    } `}
                  ></i>
                }
                onAction={() => onSaveToDo()}
                disabled={saving}
              />
            )}
            <WiseSmallButton
              label={languages.cancel}
              onAction={() => onClose(null)}
              disabled={saving}
            />
          </div>
        </div>
      </div>
    </WiseModal>
  );
};

interface IModalWiseEvent {
  dlgTitle: string;
  isOpen: boolean;
  onClose: (retData: any) => void;
  dataRecord: IMonalytInflCampEvent;
  dlgStatus: number;
}
export const ModalWiseEvent = ({
  dataRecord,
  dlgTitle,
  isOpen,
  onClose,
  dlgStatus,
}: IModalWiseEvent) => {
  const [saving, setSaving] = useState(false);
  const [dataForm, setDataForm] = useState<IMonalytInflCampEvent>({
    ...dataRecord,
  });
  const [formErrors, setFormErrors] = useState<IFormError>({});

  const onSaveToDo = async () => {
    const errors: IFormError = {};

    if (!dataForm.eventName || dataForm.eventName.length === 0) {
      errors.eventName = languages.fldRequired;
    }
    if (!dataForm.eventLocation || dataForm.eventLocation.length === 0) {
      errors.eventLocation = languages.fldRequired;
    }

    if (dataForm.endDate.getTime() < dataForm.startDate.getTime()) {
      errors.endDate = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    const oOper = await NetServices.requestPost("hiretools/saveeventrecord", {
      wiseEventRecord: dataForm,
    });

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    onClose(oOper.lstOfWiseEvents!);
  };

  const onChange = (f: string, v: string | number) => {
    if (f === "startDate" || f === "endDate") {
      setDataForm({ ...dataForm, [f]: new Date(v) });
    } else {
      setDataForm({ ...dataForm, [f]: v });
    }
  };

  useEffect(() => {
    setDataForm({ ...dataRecord });
    setSaving(false);
    setFormErrors({});
  }, [isOpen]);

  return (
    <WiseModal
      hasFooter={false}
      isOpen={isOpen}
      onClose={onClose}
      titleBox={dlgTitle}
    >
      <div className="flex flex-col py-2 space-y-2 px-2">
        <div className="flex flex-col space-y-3">
          {/* Body */}
          <div className="flex justify-between flex-wrap">
            <div className="w-full md:w-[49%]">
              <BootstrapInput
                fieldName="eventName"
                label={languages.eventName}
                fldType="text"
                value={dataForm.eventName}
                disabled={saving || dlgStatus !== 0}
                errorText={formErrors.eventName}
                placeholder={languages.eventName}
                showLabel={true}
                onChange={onChange}
              />
            </div>
            <div className="w-full md:w-[49%]">
              <BootstrapInput
                fieldName="eventLocation"
                label={languages.location}
                fldType="text"
                value={dataForm.eventLocation}
                disabled={saving || dlgStatus !== 0}
                errorText={formErrors.eventLocation}
                placeholder={languages.location}
                showLabel={true}
                onChange={onChange}
              />
            </div>
          </div>
          <div>
            <BootstrapInput
              fieldName="description"
              label={languages.description}
              fldType="textarea"
              value={dataForm.description ?? ""}
              disabled={saving || dlgStatus !== 0}
              errorText={formErrors.description}
              placeholder={languages.description}
              showLabel={true}
              onChange={onChange}
            />
          </div>

          <div className="flex justify-between flex-wrap">
            <div className="w-full md:w-[49%]">
              <BootstrapInput
                fieldName="startDate"
                label={languages.start}
                fldType="datetime-local"
                value={appFxs.getDateForInputLocal(dataForm.startDate)}
                disabled={saving || dlgStatus !== 0}
                errorText={formErrors.start}
                showLabel={true}
                onChange={onChange}
              />
            </div>
            <div className="w-full md:w-[49%]">
              <BootstrapInput
                fieldName="endDate"
                label={languages.end}
                fldType="datetime-local"
                value={appFxs.getDateForInputLocal(dataForm.endDate)}
                disabled={saving || dlgStatus !== 0}
                errorText={formErrors.end}
                showLabel={true}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="flex justify-between flex-wrap">
            <div className="w-full md:w-[49%]">
              <WiseSwitchFormElt
                fieldName="allDay"
                label={languages.allDayEvt}
                value={dataForm.allDay}
                onCheckValue={(f, v) => setDataForm({ ...dataForm, [f]: v })}
              />
            </div>
            <div className="w-full md:w-[49%]">
              <WiseSwitchFormElt
                fieldName="isAnniversary"
                label={languages.anniversary}
                value={dataForm.isAnniversary}
                onCheckValue={(f, v) => setDataForm({ ...dataForm, [f]: v })}
              />
            </div>
          </div>

          {/* Footer */}
          <div className="flex items-center justify-end space-x-3 py-3 px-2">
            {dlgStatus === 0 && (
              <WiseSmallButton
                color="blue"
                label={languages.send}
                icon={
                  <i
                    className={`fa ${
                      saving ? "fa-spin fa-spinner" : "fa-send"
                    } `}
                  ></i>
                }
                onAction={() => onSaveToDo()}
                disabled={saving}
              />
            )}
            <WiseSmallButton
              label={languages.cancel}
              onAction={() => onClose(null)}
              disabled={saving}
            />
          </div>
        </div>
      </div>
    </WiseModal>
  );
};

interface IModalWiseSMS {
  dlgTitle: string;
  isOpen: boolean;
  onClose: (retData: any) => void;
  dataRecord: IMonalytInflCampSms;
  dlgStatus: number;
  lstAccts: ISMSSetting[];
}
export const ModalWiseSMS = ({
  dataRecord,
  dlgTitle,
  isOpen,
  onClose,
  dlgStatus,
  lstAccts,
}: IModalWiseSMS) => {
  const [saving, setSaving] = useState(false);
  const [dataForm, setDataForm] = useState<IMonalytInflCampSms>({
    ...dataRecord,
  });
  const [formErrors, setFormErrors] = useState<IFormError>({});

  const fL = lstAccts.map((o) => {
    return {
      label: o.accountName,
      value: o.accountName,
    };
  });
  const acctOpts = [...fL, { label: "", value: "" }];

  const onSaveToDo = async () => {
    const errors: IFormError = {};

    if (!dataForm.smsSettingName || dataForm.smsSettingName.length === 0) {
      errors.smsSettingName = languages.fldRequired;
    }
    if (!dataForm.smsMessage || dataForm.smsMessage.length === 0) {
      errors.smsMessage = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    const oOper = await NetServices.requestPost("hiretools/sendhiringsms", {
      wiseSmsRecord: dataForm,
    });

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    onClose(oOper.lstOfWiseSms!);
  };

  const onChange = (f: string, v: string | number) => {
    if (f === "msgSentDate") {
      setDataForm({ ...dataForm, [f]: new Date(v) });
    } else {
      setDataForm({ ...dataForm, [f]: v });
    }
  };

  useEffect(() => {
    setDataForm({ ...dataRecord });
    setSaving(false);
    setFormErrors({});
  }, [isOpen]);

  return (
    <WiseModal
      hasFooter={false}
      isOpen={isOpen}
      onClose={onClose}
      titleBox={dlgTitle}
    >
      <div className="flex flex-col py-2 space-y-2 px-2">
        <div className="flex flex-col space-y-3">
          {/* Body */}
          <div className="flex justify-between flex-wrap">
            <div className="w-full md:w-[49%]">
              <BootstrapInput
                fieldName="smsSettingName"
                label={languages.smsAcct}
                fldType="select"
                value={dataForm.smsSettingName}
                disabled={saving || dlgStatus !== 0}
                errorText={formErrors.smsSettingName}
                showLabel={true}
                onChange={onChange}
                selectOptions={acctOpts}
              />
            </div>
            <div className="w-full md:w-[49%]">
              <BootstrapInput
                fieldName="msgSentDate"
                label={languages.smsDate}
                fldType="datetime-local"
                value={appFxs.getDateForInputLocal(dataForm.msgSentDate)}
                disabled={saving || dlgStatus !== 0}
                errorText={formErrors.smsDate}
                showLabel={true}
                onChange={onChange}
              />
            </div>
          </div>
          <div>
            <BootstrapInput
              fieldName="smsMessage"
              label={languages.sms}
              fldType="textarea"
              value={dataForm.smsMessage}
              disabled={saving || dlgStatus !== 0}
              errorText={formErrors.smsMessage}
              placeholder={languages.message}
              showLabel={true}
              onChange={onChange}
              maxLength={800}
            />
          </div>

          {/* Footer */}
          <div className="flex items-center justify-end space-x-3 py-3 px-2">
            {dlgStatus === 0 && (
              <WiseSmallButton
                color="blue"
                label={saving ? languages.sending : languages.send}
                icon={
                  <i
                    className={`fa ${
                      saving ? "fa-spin fa-spinner" : "fa-send"
                    } `}
                  ></i>
                }
                onAction={() => onSaveToDo()}
                disabled={saving}
              />
            )}
            <WiseSmallButton
              label={languages.cancel}
              onAction={() => onClose(null)}
              disabled={saving}
            />
          </div>
        </div>
      </div>
    </WiseModal>
  );
};

interface IModalWiseFinance {
  dlgTitle: string;
  isOpen: boolean;
  onClose: (retData: any) => void;
  dataRecord: IMonalytInflCampFinance;
  dlgStatus: number;
}

export const ModalWiseFinance = ({
  dataRecord,
  dlgTitle,
  isOpen,
  onClose,
  dlgStatus,
}: IModalWiseFinance) => {
  const [saving, setSaving] = useState(false);
  const [dataForm, setDataForm] = useState<IMonalytInflCampFinance>({
    ...dataRecord,
  });

  const operTitle = useMemo(() => {
    const str =
      dataForm.financeType === "invoice"
        ? languages.invoiceNbr
        : dataForm.financeType === "expense"
        ? languages.expenseNbr
        : languages.paytNbr;
    return str;
  }, [dataForm]);

  const operReference = useMemo(() => {
    const str =
      dataForm.financeType === "invoice"
        ? languages.invoiceRef
        : dataForm.financeType === "expense"
        ? languages.expenseRef
        : languages.paytRef;
    return str;
  }, [dataForm]);

  const operDate = useMemo(() => {
    const str =
      dataForm.financeType === "invoice"
        ? languages.invoiceDate
        : dataForm.financeType === "expense"
        ? languages.expenseDate
        : languages.paytDate;
    return str;
  }, [dataForm]);

  const operAmt = useMemo(() => {
    const str =
      dataForm.financeType === "invoice"
        ? languages.invoiceAmt
        : dataForm.financeType === "expense"
        ? languages.expenseAmt
        : languages.paytAmt;
    return str;
  }, [dataForm]);

  const [formErrors, setFormErrors] = useState<IFormError>({});

  const onSaveToDo = async () => {
    const errors: IFormError = {};

    if (!dataForm.operNumber || dataForm.operNumber.length === 0) {
      errors.operNumber = languages.fldRequired;
    }
    if (dataForm.financeType !== "payment") {
      if (!dataForm.productItemName || dataForm.productItemName.length === 0) {
        errors.productItemName = languages.fldRequired;
      }
    }
    if (!dataForm.description || dataForm.description.length === 0) {
      errors.description = languages.fldRequired;
    }
    if (dataForm.financeAmount <= 0) {
      errors.financeAmount = languages.fldRequired;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setSaving(true);

    const finalData: IMonalytInflCampFinance = {
      ...dataForm,
      financeAmount: Number(
        dataForm.financeAmount.toString().replace(/,/g, "")
      ),
    };


    const oOper = await NetServices.requestPost("hiretools/savehiringfinance", {
      wiseFinanceRecord: finalData,
    });

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setSaving(false);
      return;
    }

    onClose(oOper.lstOfWiseFinances!);
  };

  const onChange = (f: string, v: string | number) => {
    if (f === "financeDate") {
      setDataForm({ ...dataForm, [f]: new Date(v) });
    } else {
      setDataForm({ ...dataForm, [f]: v });
    }
  };

  useEffect(() => {
    setDataForm({ ...dataRecord });
    setSaving(false);
    setFormErrors({});
  }, [isOpen]);

  return (
    <WiseModal
      hasFooter={false}
      isOpen={isOpen}
      onClose={onClose}
      titleBox={dlgTitle}
    >
      <div className="flex flex-col py-2 space-y-2 px-2">
        <div className="flex flex-col space-y-3">
          {/* Body */}
          <div className="flex justify-between flex-wrap">
            <div className="w-full md:w-[49%]">
              <BootstrapInput
                fieldName="operNumber"
                label={operTitle}
                fldType="text"
                value={dataForm.operNumber}
                disabled={saving || dlgStatus !== 0}
                errorText={formErrors.operNumber}
                showLabel={true}
                onChange={onChange}
                placeholder={operTitle}
              />
            </div>
            <div className="w-full md:w-[49%]">
              <BootstrapInput
                fieldName="operReference"
                label={operReference}
                fldType="text"
                value={dataForm.operReference ?? ""}
                disabled={saving || dlgStatus !== 0}
                errorText={formErrors.operReference}
                showLabel={true}
                onChange={onChange}
                placeholder={languages.invoiceRef}
              />
            </div>
          </div>
          {dataForm.financeType !== "payment" && (
            <div className="flex items-end">
              <div
                data-type={dataForm.financeType}
                className="flex-1 data-[type=invoice]:mr-3"
              >
                <BootstrapInput
                  fieldName="productItemName"
                  label={languages.prodItem}
                  fldType="text"
                  value={dataForm.productItemName ?? ""}
                  disabled={saving || dlgStatus !== 0}
                  errorText={formErrors.productItemName}
                  placeholder={languages.prodItem}
                  showLabel={true}
                  onChange={onChange}
                />
              </div>
              {dataForm.financeType === "invoice" && (
                <div>
                  <WiseSwitchFormElt
                    fieldName="billed"
                    label={languages.billed}
                    value={dataForm.billed}
                    onCheckValue={(f, v) =>
                      setDataForm({ ...dataForm, [f]: v })
                    }
                  />
                </div>
              )}
            </div>
          )}
          <div>
            <BootstrapInput
              fieldName="description"
              label={languages.description}
              fldType="textarea"
              value={dataForm.description}
              disabled={saving || dlgStatus !== 0}
              errorText={formErrors.description}
              placeholder={languages.description}
              showLabel={true}
              onChange={onChange}
            />
          </div>
          <div className="flex items-center flex-wrap justify-between">
            <div className="w-full md:w-[49%]">
              <BootstrapInput
                fieldName="financeDate"
                label={operDate}
                fldType="date"
                value={appFxs.getDateForInput(dataForm.financeDate)}
                disabled={saving || dlgStatus !== 0}
                errorText={formErrors.financeDate}
                placeholder={operDate}
                showLabel={true}
                onChange={onChange}
              />
            </div>
            <div className="w-full md:w-[49%]">
              <BootstrapInput
                fieldName="financeAmount"
                label={operAmt}
                fldType="number"
                value={dataForm.financeAmount}
                disabled={saving || dlgStatus !== 0}
                errorText={formErrors.financeAmount}
                placeholder={operAmt}
                showLabel={true}
                onChange={onChange}
              />
            </div>
          </div>

          {/* Footer */}
          <div className="flex items-center justify-end space-x-3 py-3 px-2">
            {dlgStatus === 0 && (
              <WiseSmallButton
                color="blue"
                label={saving ? languages.saving : languages.save}
                icon={
                  <i
                    className={`fa ${
                      saving ? "fa-spin fa-spinner" : "fa-save"
                    } `}
                  ></i>
                }
                onAction={() => onSaveToDo()}
                disabled={saving}
              />
            )}
            <WiseSmallButton
              label={languages.cancel}
              onAction={() => onClose(null)}
              disabled={saving}
            />
          </div>
        </div>
      </div>
    </WiseModal>
  );
};
