import React, { useEffect, useMemo, useState } from "react";

import { DropdownMenu } from "@radix-ui/themes";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

import { ReactComponent as UkFlag } from "src/assets/images/svg/icons/uk_flag.svg";
import { ReactComponent as FrFlag } from "src/assets/images/svg/icons/fr_flag.svg";
import { ReactComponent as MenuJustify } from "src/assets/images/svg/menujustify.svg";

import { CircularButtonIcon } from "src/components/small_comps";
import { AppMenuElt, AppMenuType, ConnectedInfoRecord } from "src/models/smarttypes";
import { IMAGES_REFS } from "src/utils/constants";
import { listOfActivePages, listOfAppMenus } from "src/utils/tempo_data";

import getLangs from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";
import { ICompanyRecord, IMonalytUser } from "src/models/general_models";

const languages = getLangs();
const appFxs = generalFxs();
const cLang = appFxs.getLocalLanguage();
const isEn = cLang === "en";

const AppLayout = () => {
  const navigate = useNavigate();
  const [appClientWidth, setAppClientWidth] = useState(0);
  const [sideMenuVis, setSideMenuVis] = useState(false);

  const changeLang = async (l: string) => {
    if (cLang === l) return;
    const lng = l === "en" ? languages.english_m : languages.french_m;
    const quest =
      cLang === "en"
        ? `Do you want to change the application language to ${lng} ?`
        : `Voudriez-vous changer la langue de l'application e ${lng}?`;

    const bAsk = await appFxs.showConfirm(languages.language, quest);
    if (!bAsk.isConfirmed) return;

    appFxs.setLocalStorageByKey(appDecl.currentLang, l);
    document.location.reload();
  };

  const onSelectMenu = async (e: AppMenuType) => {
    switch (e) {
      case "langeng":
        changeLang("en");
        break;
      case "langfr":
        changeLang("fr");
        break;
      case "myprofile":
        navigate('/app/settings/myprofile');
        break;
      case "settings":
        navigate('/app/settings/settings');
        break;
      case "signout":
        const bAsk = await appFxs.showConfirm(
          languages.signOut,
          languages.signOutMsg
        );
        if (!bAsk.isConfirmed) return;

        appFxs.setLocalStorageByKey(appDecl.connectedCompany, "");
        appFxs.setLocalStorageByKey(appDecl.connectedUser, "");
        appFxs.setLocalStorageByKey(appDecl.connectionStatus, "0");

        navigate("/");
        break;
    }
  };


  const onSelectMainMenu = (href: string) => {
    navigate(href);
    setSideMenuVis(false);
  }


  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const oW = entries[0].target.clientWidth;
      if(oW>1200) setSideMenuVis(false);
      setAppClientWidth(oW);
    });

    const resizeElt = document.getElementById("mainapplayout");
    if (resizeElt) {
      resizeObserver.observe(resizeElt);
    }
  }, []);

  return (
    <div className="flex h-screen relative" id="mainapplayout">
      <MainMenuApp  visible={appClientWidth > 1200}  />
      <div onClick={() => setSideMenuVis(false)} data-visible={sideMenuVis && (appClientWidth <= 1200)} className="cursor-pointer hidden data-[visible=true]:block fixed top-0 right-0 left-0 bottom-0 z-10 bg-black/15"></div>
      <MainSideMenuApp visible={sideMenuVis && (appClientWidth <= 1200)} onSelect={onSelectMainMenu}  />


      <div className="flex-1">
        <MainAppBar onSelectMenu={(e) => onSelectMenu(e)} onShowSideMEnu={() => setSideMenuVis(true)}/>
        <main>
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default AppLayout;

const MainMenuApp = ({visible}: {visible: boolean}) => {
  const location = useLocation();

  const pathElt = useMemo(() => {
    return location.pathname;
  }, [location]);

  if(!visible) return null;

  return (
    <div className="w-[280px] border-t border-b border-r rounded-t-[40px] rounded-b-[40px] rounded">
      <div className="flex flex-col">
        <Link to="/app" className="py-3 mb-3">
          <img src={IMAGES_REFS.monalyticsLogo} className="w-[220px]" alt="" />
        </Link>

        <ul className="ml-6 flex flex-col mr-6">
          {listOfAppMenus.map((o) => (
            <MenuEltComp
              data={o}
              key={o.id}
              active={pathElt.includes(o.href)}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

const MainSideMenuApp = ({visible,onSelect }: {visible: boolean, onSelect: (href: string) => void,}) => {
  const location = useLocation();

  const pathElt = useMemo(() => {
    return location.pathname;
  }, [location]);

  if(!visible) return null;

  return (
    <div data-visible={visible} className="data-[visible=true]:w-[280px] bg-white z-20 w-[0px] border-t border-b border-r rounded-t-[40px] rounded-b-[40px] rounded absolute bottom-0 top-0 left-0 transition-all">
      <div className="flex flex-col">
        <Link to="/app" className="py-3 mb-3">
          <img src={IMAGES_REFS.monalyticsLogo} className="w-[220px]" alt="" />
        </Link>

        <ul className="ml-6 flex flex-col mr-6">
          {listOfAppMenus.map((o) => (
            <MenuSideEltComp
              data={o}
              key={o.id}
              active={pathElt.includes(o.href)}
              onSelect={onSelect}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

const MenuEltComp = ({
  data,
  active,
}: {
  data: AppMenuElt;
  active: boolean;
}) => {
  return (
    <Link
      data-active={active}
      to={data.href}
      className="hover:bg-[#e8eefe] data-[active=true]:bg-[#e8eefe] flex items-center space-x-3 p-2 mb-2 border rounded-lg hover:font-semibold data-[active=true]:font-bold"
    >
      <data.icon />
      <span>{data.label}</span>
    </Link>
  );
};

const MenuSideEltComp = ({
  data,
  active,
  onSelect,
}: {
  data: AppMenuElt;
  active: boolean;
  onSelect: (href:string) => void;
}) => {


  return (
    <a
      onClick={() => onSelect(data.href)}
      data-active={active}
      className="cursor-pointer hover:bg-[#e8eefe] data-[active=true]:bg-[#e8eefe] flex items-center space-x-3 p-2 mb-2 border rounded-lg hover:font-semibold data-[active=true]:font-bold"
    >
      <data.icon />
      <span>{data.label}</span>
    </a>
  );
};

const ProfileAuthComp = ({connected}: {connected?: ConnectedInfoRecord}) => {

  const [firstName, setFirstName] = useState(`${languages.loading}...`)
  const [companyName, setCompanyName] = useState(`${languages.loading}...`)
  const [profileImg, setProfileImage] = useState(IMAGES_REFS.userProfile)

  useEffect(() => {
    if(!connected)return;
    setFirstName(connected.connectedUser.firstName);
    setCompanyName(connected.connectedCompany.companyName);

    if(!connected.connectedUser.photoProfile){
      setProfileImage(IMAGES_REFS.userProfile);
    }else{
      const profilePath = `${appDecl.profilesPath}/${connected.connectedUser.companyKey}/${connected.connectedUser.photoProfile}`
      setProfileImage(profilePath);
    }

  }, [connected])
  


  return (
    <div className="flex items-center space-x-2 cursor-pointer min-w-[180px]">
      <img
        src={profileImg}
        alt=""
        className="w-10 h-10 rounded-full"
      />
      <div className="flex flex-col flex-1">
        <span className="block font-bold">{firstName}</span>
        <span className="block text-xs">{companyName}</span>
      </div>
    </div>
  );
};

const MainAppBar = ({
  onSelectMenu,
  onShowSideMEnu,
}: {
  onSelectMenu: (e: AppMenuType) => void;
  onShowSideMEnu: () => void;
}) => {
  const location = useLocation();
  const [connRecs, setConnRecs] = useState<ConnectedInfoRecord | undefined>(undefined);

  const pathElt = useMemo(() => {
    const oMenu = listOfActivePages.find(o => location.pathname.includes(o.href));
    return  oMenu ? oMenu.label : languages.dashboard;
  }, [location]); 

  const prepareConnected = () => {
    const fUser = appFxs.getLocalStorageByKey(appDecl.connectedUser, "");
    const fComp = appFxs.getLocalStorageByKey(appDecl.connectedCompany, "");

    const finalUser = JSON.parse(fUser) as IMonalytUser;
    const finalComp = JSON.parse(fComp) as ICompanyRecord;

    const fFinal:ConnectedInfoRecord = {
      connectedCompany: finalComp,
      connectedUser: finalUser,
    }

    setConnRecs(fFinal);
  }


  


  useEffect(() => {
    prepareConnected();
  }, []);

  return (
    <header className="py-3 px-4 border-b">
      <div className="flex flex-row items-center justify-between">
        <div className="flex items-center">
          <span className="cursor-pointer min-[1200px]:hidden mr-3" onClick={() => onShowSideMEnu()}>
            <MenuJustify className="w-7 h-7" />
          </span>
          <span className="block font-extrabold text-lg">{pathElt}</span>
        </div>
        <div className="flex items-center space-x-4">
          <CircularButtonIcon btnType="envelope" />
          <CircularButtonIcon btnType="bell" />
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <div>
                <ProfileAuthComp connected={connRecs} />
              </div>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              <DropdownMenu.Item
                className="cursor-pointer"
                onClick={() => onSelectMenu("myprofile")}
              >
                {languages.profileSettings}
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className="cursor-pointer"
                onClick={() => onSelectMenu("settings")}
              >
                {languages.settings}
              </DropdownMenu.Item>
              <DropdownMenu.Separator />
              <DropdownMenu.Item
                className="cursor-pointer"
                onClick={() => onSelectMenu("langfr")}
              >
                <div
                  className={`flex items-center justify-between w-full ${
                    !isEn && "font-semibold"
                  }`}
                >
                  <span>{languages.french_m}</span>
                  <FrFlag className="w-6 h-6" />
                </div>
              </DropdownMenu.Item>
              <DropdownMenu.Item
                className="cursor-pointer"
                onClick={() => onSelectMenu("langeng")}
              >
                <div
                  className={`flex items-center justify-between w-full ${
                    isEn && "font-semibold"
                  }`}
                >
                  <span>{languages.english_m}</span>
                  <UkFlag className="w-6 h-6" />
                </div>
              </DropdownMenu.Item>
              <DropdownMenu.Separator />
              <DropdownMenu.Item
                className="cursor-pointer"
                onClick={() => onSelectMenu("signout")}
              >
                {languages.signOut}
              </DropdownMenu.Item>
            </DropdownMenu.Content>
          </DropdownMenu.Root> 
        </div>
      </div>
    </header>
  );
};


