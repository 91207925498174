import React, { useEffect, useMemo, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Button, DropdownMenu, Skeleton, TextField } from "@radix-ui/themes";
import {
  MagnifyingGlassIcon,
  CalendarIcon,
  DropdownMenuIcon,
} from "@radix-ui/react-icons";

import {
  ErrorTableRow,
  HashtagEltComp,
  LoadingTableRow,
  NoRecordTableRow,
} from "src/components/small_comps";

import getLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

import NetServices from "src/utils/netservices";
import { IMonalytCampaign } from "src/models/monalytics_models";

const languages = getLang();
const appFxs = generalFxs();
const lang = appFxs.getLocalLanguage();
const todayDate = new Date();
const isEn = lang === "en";


const lstMonths = appFxs.wdMonthsLng;
const currencyCode = appFxs.getLocalStorageByKey(appDecl.currentDevise, "RWF");


const CampaignPage = () => {
  document.title = `${languages.campaigns} | ${languages.appName}`;

  const [isLoading, setLoading] = useState(true);

  const [lstCampaigns, setLstCampaigns] = useState<IMonalytCampaign[]>([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState<
    IMonalytCampaign[]
  >([]);

  const [hastNetError, setNetError] = useState(false);

  const [selectedPeriod, setSelectedPeriod] = useState(
    todayDate.getMonth() + 1
  );
  const monthName = useMemo(() => {
    return lstMonths[selectedPeriod - 1];
  }, [selectedPeriod]);

  const navigate = useNavigate();

  const printListData = () => {};

  const populateData = async () => {
    setLoading(true);
    setNetError(false);

    const oper = await NetServices.requestGet(
      `monalytics/getlistofcampaignbyperiod/${selectedPeriod}`,
      false
    );

    if (!oper.bReturn) {
      setNetError(true);
      setLoading(false);
      return;
    }

    setLstCampaigns(oper.lstCampaigns!);

    setNetError(false);
    setLoading(false);
  };

  const onChangeFilterFld = (eTxt: string) => {
    if (!eTxt) {
      setFilteredCampaigns(lstCampaigns);
      return;
    }

    //setFilterMode(true);

    const filtered = lstCampaigns.filter((o) => {
      const ltTxt = eTxt.toLowerCase();
      const bTitle = o.title.toLowerCase().includes(ltTxt);
      const bTag = o.hashtag.includes(ltTxt);

      return bTitle || bTag;
    });

    setFilteredCampaigns(filtered);
  };

  const onDeleteCampaign = async (cKey: string) => {
    const bAsk = await appFxs.showConfirm(languages.delete, languages.deleteRecordQst);
    if(!bAsk.isConfirmed)return;
    const oOper = await NetServices.requestPost('monalytics/deletecampaignwithresources', {
      campaignKey: cKey,
    }, true);


    if(!oOper.bReturn){
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setLstCampaigns(oOper.lstCampaigns!);
  }
  const openHiredInfluencers = (v: string) => {
    navigate(`/app/campaign/influencers/${v}`);
  }

  useEffect(() => {
    populateData();
  }, [selectedPeriod]);
  return (
    <div className="flex flex-col p-4">
      <div className="flex items-center mb-3 gap-3">
        <div className="flex-1 flex items-center space-x-2">
          <h3 className="font-bold text-lg">{languages.campaigns}</h3>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>
              <Button>
                <CalendarIcon /> {monthName} <DropdownMenuIcon />
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Content>
              {lstMonths.map((o, idx) => (
                <DropdownMenu.Item
                  className="cursor-pointer"
                  onClick={() => setSelectedPeriod(idx + 1)}
                  key={idx}
                >
                  <span
                    data-active={idx === selectedPeriod - 1}
                    className="data-[active=true]:font-bold"
                  >
                    {o}
                  </span>
                </DropdownMenu.Item>
              ))}
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        </div>

        <div className="flex items-center space-x-2">
          <TextField.Root
            placeholder={languages.search}
            className="mr-3"
            onChange={(e) => onChangeFilterFld(e.target.value)}
          >
            <TextField.Slot>
              <MagnifyingGlassIcon height="16" width="16" />
            </TextField.Slot>
          </TextField.Root>
          <button
            className="btn-wise"
            title={languages.add}
            onClick={() =>
              navigate(
                `/app/campaign/newcampaign/${window.btoa(
                  selectedPeriod.toString()
                )}`
              )
            }
          >
            <i className="fa fa-plus"></i>
          </button>

          <button
            disabled={lstCampaigns.length === 0}
            className="btn-wise"
            onClick={() => printListData()}
            title={languages.print}
          >
            <i className="fa fa-print"></i>
          </button>
        </div>
      </div>

      <div>
        <table className="table table-zebra">
          <thead>
            <tr className="text-sm">
              <th>
                <span>{languages.title}</span>
                <div className="md:hidden">
                  <div className="border-b mb-1 py-1 flex items-center"></div>
                </div>
              </th>
              <th className="">{languages.budget}</th>
              <th className="hidden sm:table-cell">{languages.date}</th>
              <th className="hidden md:table-cell">{languages.start}</th>
              <th className="hidden md:table-cell">{languages.end}</th>
              <th className="">{languages.hashtag}</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {/* Loading */}
            {isLoading && <LoadingTableRow colSpan={7} />}

            {/* error */}
            {!isLoading && hastNetError && (
              <ErrorTableRow
                colSpan={7}
                messageDisplay={languages.errorLoadData}
                actionToTake={() => populateData()}
              />
            )}

            {/* No Record */}
            {!isLoading && !hastNetError && lstCampaigns.length === 0 && (
              <NoRecordTableRow
                colSpan={7}
                messageDisplay={languages.noRecFnd}
                buttonIconCss="fa fa-refresh"
                actionToTake={() => populateData()}
              />
            )}

            {/* list */}
            {!isLoading &&
              !hastNetError &&
              lstCampaigns.length > 0 &&
              lstCampaigns.map((oMsg, idx) => (
                <CampaignRecordRow
                  data={oMsg}
                  key={idx}
                  periodM={selectedPeriod}
                  deleteCampaign={v => onDeleteCampaign(v)}
                  openHiredInfluencers={openHiredInfluencers}
                />
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CampaignPage;

const CampaignRecordRow = ({
  data,
  periodM,
  deleteCampaign,
  openHiredInfluencers,
}: {
  data: IMonalytCampaign;
  periodM: number;
  deleteCampaign: (campaignKey: string) => void;
  openHiredInfluencers: (capmpaignKey: string) => void;
}) => {
  return (
    <tr className="text-sm">
      <td>
        <Link
          to={`/app/campaign/editor/${data.campaignKey}`}
          className="text-blue-500 hover:text-blue-700"
        >
          <span>{data.title}</span>
        </Link>
        <div className="md:hidden">
          <div className="border-b mb-1 py-1 flex flex-wrap items-center space-y-1">
            <div className="text-xs flex items-center p-1 border rounded-md">
              <i className="fa fa-calendar"></i>&nbsp;
              <span>{appFxs.formatDate(data.startDate)}</span>
            </div>
            <div className="text-xs flex items-center p-1 border rounded-md">
              <i className="fa fa-calendar"></i>&nbsp;
              <span>{appFxs.formatDate(data.startDate)}</span>
            </div>
          </div>
        </div>
      </td>

      <td className="text-right">
        <span>{currencyCode}</span>&nbsp;{appFxs.formatNumbers(data.budget)}<br/>
        <BudgetHiredComp campaign={data} />
      </td>
      <td className="hidden sm:table-cell">
        {appFxs.formatDateFr(data.postedDate)}
      </td>
      <td className="hidden md:table-cell">
        {appFxs.formatDateFr(data.startDate)}
      </td>
      <td className="hidden md:table-cell">
        {appFxs.formatDateFr(data.endDate)}
      </td>
      <td className="">
        <div className="flex flex-wrap items-center space-x-2">
          {data.hashtag.map((t, i) => (
            <HashtagEltComp key={i} data={data} tag={t} />
          ))}
        </div>
      </td>
      <td>
        <div className="flex items-center space-x-2" title={languages.delete}>
          <Button color="red" onClick={() => deleteCampaign(data.campaignKey)}>
            <i className="fa fa-trash"></i>
          </Button>
        </div>
      </td>
    </tr>
  );
};

interface IBudgetHiredComp{
  campaign: IMonalytCampaign,
}
const BudgetHiredComp = ({campaign}: IBudgetHiredComp) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [amtDisplay, setAmtDisplay] = useState(0);
  const [budgetStatus, setBudgetStatus] = useState(0);

  const prepareData = async () => {
    setLoading(true);
    setHasError(false);

    const oOper = await NetServices.requestGet(`monalytics/gethiredamountdata/${campaign.campaignKey}`);

    if(!oOper.bReturn){
      setHasError(true);
      setLoading(false);
      return;
    }

    const amtHired = oOper.influecAllHiredAmt!
    if(amtHired <= campaign.budget) {
      setBudgetStatus(0);
    }else{
      setBudgetStatus(1);
    }


    setAmtDisplay(amtHired)
    setHasError(false);
    setLoading(false);
  }

  useEffect(() => {
    prepareData();
  }, []);

  return (
    <div className="text-[10px]">
      {loading && <Skeleton>Loading</Skeleton>}
      {(!loading && hasError) && <div>
          <span>{languages.error}</span>&nbsp;<button onClick={() => prepareData()}><i className="fa fa-refresh"></i></button>
        </div>}
      {(!loading && !hasError) && <span data-status={budgetStatus} className="data-[status=1]:bg-red-500 data-[status=1]:text-white border px-2 py-1 rounded-lg">{currencyCode}&nbsp;{appFxs.formatNumbers(amtDisplay)}</span>}
    </div>
  );
}