/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";

import {
  IMonalytCampaignInfl,
  IMonalytInflCampFinance,
} from "src/models/monalytics_models";

import NetServices from "src/utils/netservices";
import GeneralFxs from "src/utils/general_fx";
import GetLangs from "src/utils/languages";
import appDecl from "src/utils/declarations";
import {
  DropDownMenuAction,
  FinanceOperType,
  IMenuDropDownAct,
} from "src/models/smarttypes";
import { ModalWiseFinance } from "../modal_hiring_tools";
import {
  DropDownFinanceMenu,
  ErrorMsgDisplayer,
  NoRecordMsgDisplayer,
} from "../small_comps";
import LoadinInterfaceComp from "../LoadinInterfaceComp";

const languages = GetLangs();
const appFxs = GeneralFxs();
const currencyCode = appFxs.getLocalStorageByKey(appDecl.currentDevise, "RWF");

const emptyFinanceRec: IMonalytInflCampFinance = {
  billed: false,
  campaignKey: "",
  companyKey: "",
  createdBy: "",
  description: "",
  financeAmount: 0,
  financeDate: new Date(),
  financeOperKey: "",
  financeType: "payment",
  hireOperKey: "",
  influencerKey: "",
  operDate: new Date(),
  operNumber: "",
  operReference: "",
  productItemName: "",
};

const FinanceDocumentsViewer = ({
  hiringOperationKey,
  updateTabsComp,
}: {
  hiringOperationKey: string;
  updateTabsComp: (action: DropDownMenuAction) => void;
}) => {
  const [dlgOpen, setDlgOpen] = useState(false);
  const [dlgStatus, setDlgStatus] = useState(0);
  const [dlgRecord, setDlgRecord] = useState<IMonalytInflCampFinance>({
    ...emptyFinanceRec,
  });
  const [dlgTitle, setDlgTitle] = useState(languages.newPayt);

  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const [lstOfWiseFinances, setLstOfWiseFinances] = useState<
    IMonalytInflCampFinance[]
  >([]);
  const [baseHiringOps, setBaseHiringOps] =
    useState<IMonalytCampaignInfl | null>(null);

  const lstMenus: IMenuDropDownAct[] = [
    {
      label: languages.note,
      subLabel: languages.sendNoteDet,
      actionType: "send_note",
      icon: "fa fa-sticky-note",
    },
    {
      label: languages.phone,
      subLabel: languages.makeCallDet,
      actionType: "make_call",
      icon: "fa fa-phone",
    },
    {
      label: languages.email,
      subLabel: languages.sendEmailDet,
      actionType: "send_email",
      icon: "fa fa-envelope",
    },
    {
      label: languages.sms,
      subLabel: languages.sendSmsDet,
      actionType: "send_sms",
      icon: "fa fa-commenting",
    },
    {
      label: languages.files,
      subLabel: languages.uploadFileDet,
      actionType: "upload_file",
      icon: "fa fa-file-pdf",
    },
    {
      label: languages.todo,
      subLabel: languages.todoDetail,
      actionType: "todo",
      icon: "fa fa-outdent",
    },
    {
      label: languages.events,
      subLabel: languages.eventDetail,
      actionType: "event",
      icon: "fa fa-calendar",
    },
    {
      label: languages.invoices,
      subLabel: languages.createIncomeG,
      actionType: "create_invoice",
      icon: "fa fa-file-invoice-dollar",
    },
    {
      label: languages.payments,
      subLabel: languages.paytsDetail,
      actionType: "payt_ops",
      icon: "fa fa-file-invoice",
    },
    {
      label: languages.expenses,
      subLabel: languages.expenseDetail,
      actionType: "create_expense",
      icon: "fa fa-shopping-basket",
    },
  ];

  const populateData = async () => {
    setLoading(true);
    setHasError(false);
    setMsgError("");

    const oOper = await NetServices.requestGet(
      `hiretools/getlistfinances/${hiringOperationKey}`
    );

    if (!oOper.bReturn) {
      setMsgError(oOper.msgBody);
      setHasError(true);
      setLoading(false);
      return;
    }

    setLstOfWiseFinances(oOper.lstOfWiseFinances!);
    setBaseHiringOps(oOper.baseHiringOps!);

    setHasError(false);
    setMsgError("");
    setLoading(false);
  };

  const onPreviewFinance = (data: IMonalytInflCampFinance) => {
    openDialogFinance(data.financeType, data);
  };
  const onDeleteFinance = async (data: IMonalytInflCampFinance) => {
    const bAsk = await appFxs.showConfirm(languages.delete, languages.deleteRecordQst);
    if(!bAsk.isConfirmed)return;

    const oOper = await NetServices.requestPost('hiretools/deletehiringfinancerecord', {
      hireFinanceKey: data.financeOperKey,
    }, true);

    if(!oOper.bReturn){
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody)
      return;
    }

    setLstOfWiseFinances(oOper.lstOfWiseFinances!);
  }

  const openDialogFinance = (
    type: FinanceOperType,
    data?: IMonalytInflCampFinance
  ) => {
    const doctNbr = data
      ? data!.operNumber
      : type === "expense"
      ? `EXP${appFxs.getRandomDigits(15)}`
      : type === "invoice"
      ? `INV${appFxs.getRandomDigits(15)}`
      : `PAYT${appFxs.getRandomDigits(15)}`;

    const dTitle = data
      ? type === "expense"
        ? languages.editExpense
        : type === "invoice"
        ? languages.editInvoice
        : languages.editPayt
      : type === "expense"
      ? languages.newExpense
      : type === "invoice"
      ? languages.newInvoice
      : languages.newPayt;

    const oRecord: IMonalytInflCampFinance = data
      ? {
          ...data,
          operDate: new Date(data.operDate),
          financeDate: new Date(data.financeDate),
        }
      : {
          ...emptyFinanceRec,
          billed: false,
          campaignKey: baseHiringOps!.campaignKey,
          companyKey: baseHiringOps!.companyKey,
          hireOperKey: baseHiringOps!.recordKey,
          influencerKey: baseHiringOps!.influencerKey,
          financeType: type,
          operNumber: doctNbr,
        };

    setDlgTitle(dTitle);
    setDlgRecord(oRecord);
    setDlgStatus(data ? 1 : 0);
    setDlgOpen(true);
  };
  const onCloseFinanceDlg = (v: any) => {
    if (v) {
      const lst = v as IMonalytInflCampFinance[];
      setLstOfWiseFinances(lst);
    }

    setDlgOpen(false);
  };

  const onMenuClick = (oMenu: IMenuDropDownAct) => {
    switch (oMenu.actionType) {
      case "create_expense":
        openDialogFinance("expense");
        break;
      case "create_invoice":
        openDialogFinance("invoice");
        break;
      case "payt_ops":
        openDialogFinance("payment");
        break;
      default:
        updateTabsComp(oMenu.actionType);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  return (
    <>
      <ModalWiseFinance
        isOpen={dlgOpen}
        dlgStatus={dlgStatus}
        dataRecord={dlgRecord}
        dlgTitle={dlgTitle}
        onClose={onCloseFinanceDlg}
      />
      <div className="bg-white p-3 rounded-md">
        <div className="flex flex-col">
          <div className="flex items-center justify-between">
            <h4 className="uppercase text-md font-semibold">
              {languages.financeDocts}
            </h4>
            <div className="dropdown btn-group br-grid-menu show">
              <button
                disabled={loading}
                className="btn-xs data-[destr=true]:bg-red-500 data-[destr=true]:text-white dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>{languages.add}</span>&nbsp;
              </button>

              <ul
                className="dropdown-menu dropdown-menu-end"
                style={{
                  position: "absolute",
                  willChange: "transform",
                  top: 0,
                  left: 0,
                  transform: "translate3d(50px, 26px, 0px)",
                }}
              >
                {lstMenus.map((o, idx) => (
                  <DropDownFinanceMenu
                    data={o}
                    key={idx}
                    onMenuClick={onMenuClick}
                  />
                ))}
              </ul>
            </div>
          </div>
          <div className="py-3 flex flex-col">
            {loading && <LoadinInterfaceComp />}
            {!loading && hasError && <ErrorMsgDisplayer msg={msgError} />}
            {!loading && !hasError && lstOfWiseFinances.length === 0 && (
              <NoRecordMsgDisplayer msg={languages.noFinanceDoctMsg} />
            )}
            {!loading &&
              !hasError &&
              lstOfWiseFinances.length > 0 &&
              lstOfWiseFinances.map((o, idx) => (
                <FinanceDocumentElt
                  key={idx}
                  data={o}
                  onPreview={onPreviewFinance}
                  onDelete={onDeleteFinance}
                />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceDocumentsViewer;

const FinanceDocumentElt = ({
  data,
  onPreview,
  onDelete,
}: {
  data: IMonalytInflCampFinance;
  onPreview: (d: IMonalytInflCampFinance) => void;
  onDelete: (d: IMonalytInflCampFinance) => void;
}) => {
  const iconCss =
    data.financeType === "payment"
      ? "fa fa-file-invoice text-secondary"
      : data.financeType === "invoice"
      ? "fa fa-file-invoice-dollar text-primary"
      : "fas fa-shopping-basket text-danger";

  const labelTxt =
    data.financeType === "payment"
      ? languages.payment
      : data.financeType === "invoice"
      ? languages.invoice
      : languages.expense;


  return (
    <div className="flex flex-row">
      <div className="flex items-center justify-center w-12 h-12 mt-2">
        <i className={`imp-doct-icon ${iconCss}`}></i>
      </div>
      <div className="flex-1 tbl-doc-act-empty-row-data-text">
        <span
          className="flex items-center space-x-3"
          
        >
          <strong className="text-xs text-[#1b9cd2] cursor-pointer" onClick={() => onPreview(data)}>
            {labelTxt}: #<span>{data.operNumber}</span>&nbsp;-&nbsp;
            <span>
              {currencyCode}&nbsp;{appFxs.formatNumbers(data.financeAmount)}
            </span>
          </strong>
          <button onClick={() => onDelete(data)} title={languages.delete} type="button"><i className="fa fa-trash text-danger"></i></button>
        </span>
        <div className="tbl-item-text-sub-item">
          {languages.date}:&nbsp;
          {appFxs.formatDate(data.financeDate, "MMMM D,  YYYY")}
        </div>
      </div>
      {data.financeType === "invoice" && (
        <div>
          <span
            className={`status ${
              data.billed ? "bg-success" : "bg-warning"
            } text-white`}
          >
            {data.billed ? languages.billed : languages.notBilled}
          </span>
        </div>
      )}
    </div>
  );
};
