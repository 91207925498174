import React, { useRef, useState } from "react";

import generalFx from "src/utils/general_fx";

const appFxs = generalFx();

type IWiseTagsInput = {
  fieldName: string;
  label: string;
  value: string[];
  disabled?: boolean;
  errorText?: string;
  placeholder?: string;
  showLabel?: boolean;
  showIcon?: boolean;
  onChange?: (f: string, v: string[]) => void;
  spaceNotAllowed?: boolean;
  checkEmailVerif?: boolean;
  capsCaptions?: boolean;
  smallFonts?: boolean;
};
export default function WiseTagsInput({
  fieldName,
  label,
  value,
  disabled,
  errorText,
  placeholder,
  showLabel,
  showIcon,
  onChange,
  spaceNotAllowed,
  checkEmailVerif,
  capsCaptions,
  smallFonts,
}: IWiseTagsInput) {
  const bShowLabel = showLabel ?? false;
  const bShowIcon = showIcon ?? false;
  const bDisabled = disabled ?? false;
  const elements = [...value];
  const bStrNotAllowed = spaceNotAllowed ?? false;
  const bCheckEmail = checkEmailVerif ?? false;

  const txtInput = useRef<HTMLInputElement>(null);

  const onInputKeyup = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const v = txtInput!.current!.value;

    if (e.key === "Enter") {
      if (!txtInput!.current!.value) return;
      if (bStrNotAllowed) {
        if (/\s/g.test(v)) return;
      }
      if (bCheckEmail) {
        if (!appFxs.isEmailValid(v)) return;
      }
      if (elements.includes(txtInput!.current!.value)) return;
      onAddElt();
    }
  };

  const onDeleteElt = (pos: number) => {
    if (!onChange) return;

    const fFinal = elements.filter((o) => o !== elements[pos]);
    onChange!(fieldName, fFinal);
  };
  const onAddElt = () => {
    const f = [...elements, txtInput!.current?.value!];
    onChange!(fieldName, f);

    setTimeout(() => {
      txtInput!.current!.value! = "";
    }, 200);
  };

  return (
    <div className="flex flex-col mb-2">
      {bShowLabel && (
        <div className="label">
          <span data-smallfonts={smallFonts} data-cap={capsCaptions ?? false} className="data-[smallfonts=true]:text-[11px] data-[cap=true]:uppercase label-text text-sm font-semibold">{label}</span>
        </div>
      )}

      <div
        className={`relative border border-solid ${
          errorText ? "border-red-400" : "border-gray-400"
        } rounded-md px-2 ${
          errorText
            ? "focus-within:border-red-600"
            : "focus-within:border-gray-600"
        }`}
      >
        {bDisabled && (
          <div className="absolute top-0 left-0 w-full h-full z-[1] bg-neutral-200/20"></div>
        )}
        <div className="flex flex-row items-start gap-2 relative z-0">
          {bShowIcon && (
            <i
              className={`bi bi-tags-fill mt-2 ${
                errorText ? "text-red-500" : "text-gray-500"
              }`}
            ></i>
          )}
          <div className="flex-1 flex flex-row space-x-2 flex-wrap py-1">
            {value.map((o, idx) => (
              <TagElement
                pos={idx}
                deleteElt={onDeleteElt}
                text={o}
                key={idx}
              />
            ))}
            <input
              ref={txtInput}
              type="text"
              className={`font-light focus:border-none focus-visible:border-none focus-visible:outline-none py-3 disabled:bg-transparent ${
                errorText
                  ? "placeholder:text-red-400/60"
                  : "placeholder:text-gray-400/80"
              }`}
              placeholder={placeholder}
              onKeyUp={onInputKeyup}
            />
          </div>
        </div>
      </div>

      {errorText && (
        <div className="label">
          <span className="label-text-alt text-red-500">{errorText}</span>
        </div>
      )}
    </div>
  );
}
interface ITagElement {
  text: string;
  pos: number;
  deleteElt: (pos: number) => void;
}

const TagElement = ({ text, deleteElt, pos }: ITagElement) => {
  return (
    <div className="flex flex-row items-center px-2 py-1 mb-2 border border-solid border-neutral-300 rounded-md hover:bg-neutral-200/40">
      <span>{text}</span>
      <button className="ml-2" onClick={() => deleteElt(pos)}>
        <i className="bi bi-x-square-fill"></i>
      </button>
    </div>
  );
};

export const BootstrapTagInput = () => {
  const [input, setInput] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const [isKeyReleased, setIsKeyReleased] = useState(false);

  const onKeyDown = (e:React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const trimmedInput = input.trim();
  
    if (key === ',' && trimmedInput.length && !tags.includes(trimmedInput)) {
      e.preventDefault();
      setTags(prevState => [...prevState, trimmedInput]);
      setInput('');
    }
  
    if (key === "Backspace" && !input.length && tags.length && isKeyReleased) {
      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();
      e.preventDefault();
      setTags(tagsCopy);
      setInput(poppedTag!);
    }
  
    setIsKeyReleased(false);
  };
  const onKeyUp = () => {
    setIsKeyReleased(true);
  }
  const onChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInput(value);
  };

  const deleteTag = (idx: number) => {
    setTags((prevState) => prevState.filter((tag, i) => i !== idx));
  };

  return (
    <div className="wtag-container">
      {tags.map((tag, idx) => (
        <div className="tag" key={idx}>
          {tag}
          <button onClick={() => deleteTag(idx)}>x</button>
        </div>
      ))}
      <input
        value={input}
        placeholder="Enter a tag"
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onChange={onChange}
      />
    </div>
  );
};
