import React from 'react'

import { Outlet } from "react-router-dom";


const AccountLayout = () => {
  return (
    <div className='h-screen bg-gray-200 flex justify-center items-center'>
        <Outlet/>
    </div>
  )
}

export default AccountLayout