import React, { useMemo } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import { AppMenuElt } from "src/models/smarttypes";
import { listOfSettsMenus } from "src/utils/tempo_data";

const SettingsLayout = () => {
    const location = useLocation();
  
    const pathElt = useMemo(() => {
      return location.pathname;
    }, [location]);

  return (
    <div className="flex w-full">
      <aside className="hidden md:block w-1/4">
        <ul className="pt-2 bg-base-100 min-h-full text-base-content flex flex-col gap-2">
          {listOfSettsMenus.map((o) => (
            <SettingsMenuItem
              key={o.id}
              active={pathElt.includes(o.href)}
              visible={true}
              data={o}
            />
          ))}
        </ul>
      </aside>
      <main className="p-2 flex-1">
        <Outlet />
      </main>
    </div>
  );
};

export default SettingsLayout;

const SettingsMenuItem = ({
  active,
  data,
  visible,
}: {
  data: AppMenuElt;
  active: boolean;
  visible: boolean;
}) => {


  if (!visible) return null;

  return (
    <li className="flex items-center mr-3">
      <Link data-active={active} to={data.href} className="data-[active=true]:bg-[#f0f6f9] hover:bg-[#f0f6f9] hover:font-semibold data-[active=true]:font-semibold transition-all px-2 py-2 mb-3 border block w-full rounded-e-3xl">
        <span>{data.label}</span>
      </Link>
    </li>
  );
};
