/* eslint-disable react-hooks/exhaustive-deps */

import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from "react";

import geLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import {
  CalendarViewerComp,
  ErrorMsgDisplayer,
  NoRecordMsgDisplayer,
  SmallButtonRounded,
  WiseCircularActionBtn,
} from "../small_comps";
import {
  IMonalytCampaignInfl,
  IMonalytInflCampNote,
} from "src/models/monalytics_models";
import NetServices from "src/utils/netservices";
import LoadinInterfaceComp from "../LoadinInterfaceComp";
import { ModalWiseNote } from "../modal_hiring_tools";
import { IHiringActiActionsRef, IHiringCompVals } from "src/models/smarttypes";

const languages = geLang();
const appFxs = generalFxs();


const emptyNoteRec: IMonalytInflCampNote = {
  campaignKey: "",
  companyKey: "",
  createdBy: "",
  hireOperKey: "",
  influencerKey: "",
  note: "",
  noteKey: "",
  operDate: new Date(),
};



const ListOfWiseNotes = forwardRef((props: IHiringCompVals, ref:Ref<IHiringActiActionsRef>) => {
  const [dlgOpen, setDlgOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const [openReady, setOpenReady] = useState(false);

  const [dialogRecord, setDialogRecord] = useState<IMonalytInflCampNote>({
    ...emptyNoteRec,
  });

  const [lstOfWiseNotes, setLstOfWiseNotes] = useState<IMonalytInflCampNote[]>(
    []
  );
  const [baseHiringOps, setBaseHiringOps] =
    useState<IMonalytCampaignInfl | null>(null);

  const onAddNewNote = () => {
    const oRec: IMonalytInflCampNote = {
      ...emptyNoteRec,
      campaignKey: baseHiringOps!.campaignKey,
      companyKey: baseHiringOps!.companyKey,
      createdBy: "",
      hireOperKey: baseHiringOps!.recordKey,
      influencerKey: baseHiringOps!.influencerKey,
      note: "",
      noteKey: "",
      operDate: new Date(),
    };
    setDialogRecord(oRec);
    setDlgOpen(true);
  };
  const populateData = async () => {
    setLoading(true);
    setMsgError("");
    setHasError(false);

    const oOper = await NetServices.requestGet(
      `hiretools/getlistnotes/${props.hiringOperationKey}`
    );

    if (!oOper.bReturn) {
      setMsgError(oOper.msgBody);
      setHasError(true);
      setLoading(false);
      return;
    }
    setLstOfWiseNotes(oOper.lstOfWiseNotes!);
    setBaseHiringOps(oOper.baseHiringOps!);
    setMsgError("");
    setHasError(false);
    setLoading(false);
  };

  const onCloseDialog = (o: any) => {
    if (o) {
      const lst = o as IMonalytInflCampNote[];
      setLstOfWiseNotes(lst);
    }

    setDlgOpen(false);
  };

  const onNoteDelete = async (k: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "hiretools/deletehiringnote",
      {
        hireNoteKey: k,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setLstOfWiseNotes(oOper.lstOfWiseNotes!);
  };

  const onOpenDialog = () => {
    if(!baseHiringOps){
      setOpenReady(true);
    }else{
      onAddNewNote();
    }
  }


  useImperativeHandle(ref, () => ({
    onOpenDialog
  }));






  useEffect(() => {
    populateData();
  }, []);

  useEffect(() => {
    if(openReady){
      onOpenDialog();
    };    
  }, [baseHiringOps]);

  return(
    <>
      <ModalWiseNote
        isOpen={dlgOpen}
        dataRecord={dialogRecord}
        dlgTitle={languages.addNewNote}
        onClose={onCloseDialog}
      />
      <div className="flex flex-col">
        {/* Header */}
        <div className="flex items-center space-x-2">
          <WiseCircularActionBtn type="add" onAction={onAddNewNote} />
          <span
            onClick={onAddNewNote}
            className="text-sm cursor-pointer text-blue-500 hover:text-blue-700"
          >
            {languages.addNewNote}
          </span>
        </div>
        {/* Body */}
        <div className="flex flex-col mt-4">
          {loading && <LoadinInterfaceComp />}
          {!loading && !hasError && lstOfWiseNotes.length === 0 && (
            <NoRecordMsgDisplayer
              msg={languages.noNotePosted}
              onAction={onAddNewNote}
              actionTitle={languages.addNewNote}
btnIcon={<i className="fa fa-plus"></i>}
            />
          )}
          {!loading && hasError && (
            <ErrorMsgDisplayer
              msg={msgError}
              onAction={() => populateData()}
              actionTitle={languages.refresh}              
            />
          )}
          {!loading &&
            !hasError &&
            lstOfWiseNotes.length > 0 &&
            lstOfWiseNotes.map((o, idx) => (
              <WiseNoteElt
                key={idx}
                data={o}
                onDelete={(k) => onNoteDelete(k)}
              />
            ))}
        </div>
      </div>
    </>
  );
})



export default ListOfWiseNotes;

const WiseNoteElt = ({
  data,
  onDelete,
}: {
  data: IMonalytInflCampNote;
  onDelete: (noteKey: string) => void;
}) => {
  return (
    <div className="flex items-start mb-3 border-b">
      <div className="mr-3">
        <CalendarViewerComp calDate={new Date(data.operDate)} />
      </div>
      <div className="flex-1 ">
        <div className="tbl-item-text-sub-item">
          <div dangerouslySetInnerHTML={{ __html: data.note }} />
        </div>
        <div className="flex items-center justify-between py-3">
          <div className="text-sm">
            {appFxs.formatDateTimeAlt(new Date(data.operDate))}
          </div>
          <div>
            <SmallButtonRounded
              label={languages.delete}
              destructive={true}
              onAction={() => onDelete(data.noteKey)}
              child={<i className="fa fa-trash"></i>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
