/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import appDecl from "src/utils/declarations";
import getLanguages from "src/utils/languages";
import getFxs from "src/utils/general_fx";

import { ReactComponent as ArrowLeftIconSvg } from "src/assets/images/svg/arrow-left.svg";

import NetServices from "src/utils/netservices";
import { Link, useNavigate, useParams } from "react-router-dom";
import WiseTextInput from "src/components/WiseTextInput";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";

import { IMonalytUser } from "src/models/general_models";
import { IMAGES_REFS } from "src/utils/constants";

const languages = getLanguages();
const appFxs = getFxs();
const defUser: IMonalytUser = {
  userKey: "",
  companyKey: "",
  firstName: "",
  isAdmin: false,
  lastName: "",
  mainAdmin: false,
  registerDate: new Date(),
  userActive: false,
  userEmail: "",
  phoneNumber: "",
  photoProfile: "",
  sex: "0",
  userPassword: "",
};

const PreviewUserPage = () => {
  document.title = `${languages.preview} | ${languages.appName}`;

  const [dataForm, setDataForm] = useState<IMonalytUser>({ ...defUser });
  const [loading, setLoading] = useState<boolean>(true);
  const [userProfile, setUserProfile] = useState(IMAGES_REFS.userProfile);

  const { userKey } = useParams();

  const navigate = useNavigate();
  const updateFormData = (fldName: string, fldValue: string) => {
    setDataForm({ ...dataForm, [fldName]: fldValue });
  };

  const prepareUser = async () => {
    setLoading(true);
    const oOper = await NetServices.requestGet(
      `general/getuserbykey/${userKey}`,
      false
    );

    if (!oOper.bReturn) {
      appFxs.showAlertConfirm(oOper.msgTitle, oOper.msgBody);
      navigate("/app/settings/users");
      setLoading(false);
      return;
    }

    const oPrev = oOper.previewUser!;

    setUserProfile(
      oPrev.photoProfile
        ? `${appDecl.profilesPath}/${oPrev.companyKey}/${oPrev.photoProfile}`
        : IMAGES_REFS.userProfile
    );

    setDataForm(oPrev);
    setLoading(false);
  };

  useEffect(() => {
    prepareUser();
  }, []);

  if (loading) return <LoadinInterfaceComp />;

  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-3 gap-3">
        <Link to={"/app/settings/users"}>
          <ArrowLeftIconSvg width={24} height={24} />
        </Link>
        <h3 className="flex-1 font-bold text-lg">{languages.preview}</h3>
      </div>
      <p className="mt-1 text-gray-800 dark:text-gray-400">
        {languages.previewUserMsg}
      </p>

      <div className="flex flex-col space-y-2 w-full max-w-[450px]">
        <h3 className="text-lg font-semibold my-3">{languages.userDetails}</h3>
        <div className="flex items-center justify-center">
          <div className="h-[160px] w-[120px] border border-solid border-slate-400 rounded-lg overflow-hidden">
            <img
              alt=""
              src={userProfile}
              className="w-full h-full object-cover object-top"
            />
          </div>
        </div>
        <WiseTextInput
          fldName="userEmail"
          fldType="email"
          label={languages.email}
          placeholder={languages.emailAddress.toLowerCase()}
          graphicType="email"
          value={dataForm.userEmail}
          onChange={updateFormData}
          tabIndex={1}
          disabled={true}
        />
        <WiseTextInput
          fldName="firstName"
          fldType="text"
          label={languages.firstName}
          placeholder={languages.typeFirstNameH.toLowerCase()}
          graphicType="person"
          value={dataForm.firstName}
          onChange={updateFormData}
          tabIndex={2}
          disabled={true}
        />
        <WiseTextInput
          fldName="lastName"
          fldType="text"
          label={languages.lastName}
          placeholder={languages.typeLastNameH.toLowerCase()}
          graphicType="person"
          value={dataForm.lastName}
          onChange={updateFormData}
          tabIndex={3}
          disabled={true}
        />
        <WiseTextInput
          fldName="phoneNumber"
          fldType="text"
          label={languages.phoneNbr}
          placeholder={languages.phoneNbr.toLowerCase()}
          graphicType="phone"
          value={dataForm.phoneNumber}
          onChange={updateFormData}
          tabIndex={4}
          disabled={true}
          maxLength={12}
          isNumeric={true}
        />

        <div className="pt-6">
          <Link
            className="text-sm text-sky-500 hover:text-sky-700"
            to={"/app/settings/users"}
          >
            {languages.listUsers}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PreviewUserPage;
