/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import appDecl from "src/utils/declarations";
import getLanguages from "src/utils/languages";
import getFxs from "src/utils/general_fx";
import { ISMSSetting } from "src/models/monalytics_models";
import { ModalSmsSetting } from "src/components/modal_settings";
import NetServices from "src/utils/netservices";
import LoadinInterfaceComp from "src/components/LoadinInterfaceComp";
import {
  ErrorMsgDisplayer,
  NoRecordMsgDisplayer,
  SmallButtonRounded,
} from "src/components/small_comps";
import { WiseSmallButton } from "src/components/ButtonLinkWithIcon";
import { DropdownMenu } from "@radix-ui/themes";
import { PrevDel } from "src/models/smarttypes";

const languages = getLanguages();
const appFxs = getFxs();

const emptySmsRec: ISMSSetting = {
  accountDisplayName: "",
  accountName: "",
  accountPassword: "",
  accountPhoneNumber: "",
  companyKey: "",
  createdBy: "",
  registeredDate: new Date(),
  settingKey: "",
};

const SmsSettingsPage = () => {
  const [dlgOpen, setDlgOpen] = useState(false);
  const [dlgStatus, setDlgStatus] = useState(0);
  const [dlgTitle, setDlgTitle] = useState(languages.newSmsAcct);

  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const [dialogRecord, setDialogRecord] = useState<ISMSSetting>({
    ...emptySmsRec,
  });

  const [lstOfCompSmsSettings, setLstOfCompSmsSettings] = useState<
    ISMSSetting[]
  >([]);

  const populateData = async () => {
    setLoading(true);
    setMsgError("");
    setHasError(false);

    const oOper = await NetServices.requestGet("general/getlistofcompsms");

    if (!oOper.bReturn) {
      setMsgError(oOper.msgBody);
      setHasError(true);
      setLoading(false);
      return;
    }
    setLstOfCompSmsSettings(oOper.lstOfCompSmsSettings!);
    setMsgError("");
    setHasError(false);
    setLoading(false);
  };

  const onCloseDialog = (v: any) => {
    if (v) {
      const l = v as ISMSSetting[];
      setLstOfCompSmsSettings(l);
    }
    setDlgOpen(false);
  };

  const addOrPreviewAccount = (acct?: ISMSSetting) => {
    const f: ISMSSetting = acct
      ? {
          ...acct,
          registeredDate: new Date(acct.registeredDate),
        }
      : {
          ...emptySmsRec,
        };
    setDlgTitle(acct ? languages.newSmsAcct : languages.editSmsAcct);
    setDialogRecord(f);
    setDlgStatus(acct ? 1 : 0);
    setDlgOpen(true);
  };
  const onChangeRec = (state: PrevDel, d: ISMSSetting) => {
    if(state === 'prev'){
        oPreviewRecord(d);
    }else{
        deleteSetting(d);
    }
  }
  const oPreviewRecord = async (d: ISMSSetting) => {
    addOrPreviewAccount(d);
  }
  const deleteSetting = async ( d: ISMSSetting) => {
    const bAsk = await appFxs.showConfirm(languages.delete, languages.deleteRecordQst);

    if(!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost('general/deletesmssetting', {
        smsSettKey: d.settingKey,
    }, true);

    if(!oOper.bReturn){
        appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
        return;
    }

    setLstOfCompSmsSettings(oOper.lstOfCompSmsSettings!);
  }
  

  useEffect(() => {
    populateData();
  }, []);

  return (
    <>
      <ModalSmsSetting
        dlgTitle={dlgTitle}
        isOpen={dlgOpen}
        onClose={onCloseDialog}
        dlgStatus={dlgStatus}
        recordData={dialogRecord}
      />
      <div className="flex flex-col">
        {/* Hearder */}
        <div className="flex items-center py-2 space-x-3">
          <i className="fa fa-commenting fa-2x"></i>
          <h3 className="font-bold text-xl">{languages.smsSetts}</h3>
        </div>
        {/* Body */}
        <div className="flex flex-col px-3">
          <p className="py-2 text-base">{languages.smsSettConnectMsg}</p>
          <div className="flex flex-wrap mt-5">
            <div className="w-full md:w-1/4">
              <h3 className="md:text-right font-semibold">
                {languages.smsAccts}
              </h3>
            </div>
            <div className="w-full md:w-3/4">
              <div className="mx-3 px-2">
                <ul className="px-2 flex flex-col">
                  {loading && (
                    <li className="flex flex-col justify-center items-center space-y-2 py-3">
                      <LoadinInterfaceComp />
                    </li>
                  )}
                  {!loading && hasError && (
                    <li className="flex flex-col justify-center items-center space-y-2 py-3">
                      <ErrorMsgDisplayer
                        msg={msgError}
                        onAction={() => populateData()}
                        actionTitle={languages.refresh}
                      />
                    </li>
                  )}
                  {!loading &&
                    !hasError &&
                    lstOfCompSmsSettings.length === 0 && (
                      <li className="flex flex-col justify-center items-center space-y-2 py-3">
                        <NoRecordMsgDisplayer
                          onAction={() => addOrPreviewAccount()}
                          actionTitle={languages.newSmsAcct}
                          msg={languages.noAcctSmsSettRecord}
                          btnIcon={<i className="fa fa-plus"></i>}
                        />
                      </li>
                    )}
                  {!loading &&
                    !hasError &&
                    lstOfCompSmsSettings.length > 0 &&
                    lstOfCompSmsSettings.map((o, idx) => (
                      <SmsSettingsEltComp data={o} key={idx} onChangeRec={onChangeRec} />
                    ))}

                  {!loading && !hasError && lstOfCompSmsSettings.length > 0 && (
                    <li className="mt-3">
                      <div className="flex items-center">
                        <SmallButtonRounded
                          label={languages.addAccount}
                          onAction={() => addOrPreviewAccount()}
                        />
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmsSettingsPage;

const SmsSettingsEltComp = ({ data, onChangeRec }: { data: ISMSSetting, onChangeRec: (state:PrevDel, d: ISMSSetting) => void, }) => {
  return (
    <li className="flex items-center justify-between">
      <div className="text-sm">
        {languages.lastSaved}&nbsp;<strong>"{data.accountName}"</strong>&nbsp;
        {data.accountPhoneNumber}&nbsp;
        <span>{appFxs.formatDateTimeAlt(new Date(data.registeredDate))}</span>
      </div>
      <div>
        <DropdownMenu.Root>
          <DropdownMenu.Trigger>
            <span className="discl-menu">
              <i className="discl-menu-caret"></i>
            </span>
          </DropdownMenu.Trigger>
          <DropdownMenu.Content>
            <DropdownMenu.Item className="min-w-[80px] cursor-pointer" onClick={() => onChangeRec('prev', data)}>
            <i className="fa fa-pencil"></i>&nbsp;{languages.edit}
            </DropdownMenu.Item>
            <DropdownMenu.Item className="min-w-[80px] text-red-500 cursor-pointer" onClick={() => onChangeRec('del', data)}>
              <i className="fa fa-trash"></i>&nbsp;{languages.delete}
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Root>
      </div>
    </li>
  );
};
