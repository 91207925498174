import appLogo from 'src/assets/images/company_logo.png';
import monalyticsLogo from 'src/assets/images/monalytics-logo.png';
import userProfile from 'src/assets/images/user_profile.png';


export const PDF_VIEWER_CONTAINER_SELECTOR = '.react-pdf__Document';
export const PDF_VIEWER_PAGE_SELECTOR = '.react-pdf__Page';

export const DEFAULT_HEIGHT_PERCENT = 5;
export const DEFAULT_WIDTH_PERCENT = 15;

export const MIN_HEIGHT_PX = 60;
export const MIN_WIDTH_PX = 200;
export const FILE_MB_SIZE = 1048576;




export const IMAGES_REFS = {
    appLogo,
    monalyticsLogo,
    userProfile,
}