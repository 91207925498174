import React, { useState } from "react";

import { ReactComponent as ArrowLeftIconSvg } from "src/assets/images/svg/arrow-left.svg";

import getLanguages from "src/utils/languages";
import getFxs from "src/utils/general_fx";

import NetServices from "src/utils/netservices";
import { Link, useNavigate } from "react-router-dom";
import WiseTextInput from "src/components/WiseTextInput";
import { WiseSimpleButton } from "src/components/small_comps";
import { IMonalytUser } from "src/models/general_models";
import { IFormError } from "src/models/smarttypes";

const languages = getLanguages();
const appFxs = getFxs();

const defUser: IMonalytUser = {
  userKey: "",
  companyKey: "",
  firstName: "",
  isAdmin: false,
  lastName: "",
  mainAdmin: false,
  registerDate: new Date(),
  userActive: false,
  userEmail: "",
  phoneNumber: "",
  photoProfile: "",
  sex: "0",
  userPassword: "",
};

const NewUserPage = () => {
  document.title = `${languages.newUser} | ${languages.appName}`;


  const [dataForm, setDataForm] = useState<IMonalytUser>({ ...defUser });
  const [formErrors, setFormErrors] = useState<IFormError>({});
  const [serverInUse, setServerInUse] = useState<boolean>(false);

  const navigate = useNavigate();

  const updateFormData = (fldName: string, fldValue: string) => {
    setDataForm({ ...dataForm, [fldName]: fldValue });
  };

  const onSaveOper = async () => {
    const errors: IFormError = {};

    if (!dataForm.userEmail || dataForm.userEmail.length === 0) {
      errors.userEmail = languages.fldRequired;
    } else if (!appFxs.isEmailValid(dataForm.userEmail)) {
      errors.userEmail = languages.emailFormat;
    }

    if (!dataForm.firstName || dataForm.firstName.length === 0) {
      errors.firstName = languages.fldRequired;
    }
    if (!dataForm.lastName || dataForm.lastName.length === 0) {
      errors.lastName = languages.fldRequired;
    }
    if (dataForm.phoneNumber || dataForm.phoneNumber!.length > 0) {
      if (dataForm.phoneNumber!.length < 12) {
        errors.lastName = languages.phonenbrLengthMsg;
      }
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);

    const oOper = await NetServices.requestPost("general/sendinvitetousers", {
      recordUser: dataForm,
      recordStatus: 0,
    });

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setServerInUse(false);
      return;
    }

    navigate("/app/settings/users");
  };


  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-3 gap-3">
        <Link to={"/app/settings/users"}>
          <ArrowLeftIconSvg width={24} height={24} />
        </Link>
        <h3 className="flex-1 font-bold text-lg">{languages.newUser}</h3>
        <button
          className="btn"
          title={languages.save}
          onClick={() => onSaveOper()}
          disabled={serverInUse}
        >
          {serverInUse ? (
            <i className="fa fa-spin fa-spinner"></i>
          ) : (
            <i className="fa fa-save"></i>
          )}
        </button>
      </div>
      <p className="mt-1 text-gray-800 dark:text-gray-400">
        {languages.newUserMsg}
      </p>
      <div className="flex flex-col space-y-2 w-full max-w-[450px]">
        <h3 className="text-lg font-semibold my-3">{languages.userDetails}</h3>
        <WiseTextInput
          fldName="userEmail"
          fldType="email"
          label={languages.email}
          placeholder={languages.emailAddress.toLowerCase()}
          graphicType="email"
          value={dataForm.userEmail}
          onChange={updateFormData}
          errorText={formErrors.userEmail}
          tabIndex={1}
          disabled={serverInUse}
        />
        <WiseTextInput
          fldName="firstName"
          fldType="text"
          label={languages.firstName}
          placeholder={languages.typeFirstNameH.toLowerCase()}
          graphicType="person"
          value={dataForm.firstName}
          onChange={updateFormData}
          errorText={formErrors.firstName}
          tabIndex={2}
          disabled={serverInUse}
        />
        <WiseTextInput
          fldName="lastName"
          fldType="text"
          label={languages.lastName}
          placeholder={languages.typeLastNameH.toLowerCase()}
          graphicType="person"
          value={dataForm.lastName}
          onChange={updateFormData}
          errorText={formErrors.lastName}
          tabIndex={3}
          disabled={serverInUse}
        />
        <WiseTextInput
          fldName="phoneNumber"
          fldType="text"
          label={languages.phoneNbr}
          placeholder={languages.phoneNbr.toLowerCase()}
          graphicType="phone"
          value={dataForm.phoneNumber}
          onChange={updateFormData}
          errorText={formErrors.phoneNumber}
          tabIndex={4}
          disabled={serverInUse}
          maxLength={12}
          isNumeric={true}
        />
        <div className="pt-6">
          <WiseSimpleButton
            label={languages.save}
            disabled={serverInUse}
            working={serverInUse}
            btnType="default"
            onClick={() => onSaveOper()}
          />
        </div>
      </div>
    </div>
  )
}

export default NewUserPage