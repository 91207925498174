/* eslint-disable react-hooks/exhaustive-deps */

import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from "react";

import geLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import {
  CalendarViewerComp,
  ErrorMsgDisplayer,
  NoRecordMsgDisplayer,
  WiseCheckBox,
  WiseCircularActionBtn,
} from "../small_comps";
import {
  IMonalytCampaignInfl,
  IMonalytInflCampToDo,
} from "src/models/monalytics_models";
import NetServices from "src/utils/netservices";
import LoadinInterfaceComp from "../LoadinInterfaceComp";
import { ModalWiseToDo } from "../modal_hiring_tools";
import { IMonalytUser } from "src/models/general_models";
import { IHiringActiActionsRef, IHiringCompVals, StatusType } from "src/models/smarttypes";

const languages = geLang();
const appFxs = generalFxs();

const emptyToDoRec: IMonalytInflCampToDo = {
  todoKey: "",
  companyKey: "",
  createdBy: "",
  hireOperKey: "",
  influencerKey: "",
  assignedTo: "",
  operDate: new Date(),
  dueDate: new Date(),
  campaignKey: "",
  done: false,
  notes: "",
  todoTitle: "",
};

const ListOfWiseTodos = forwardRef((
  props: IHiringCompVals,
  ref: Ref<IHiringActiActionsRef>
) => {
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState("");
  const [dlgOpen, setDlgOpen] = useState(false);
  const [dlgStatus, setDlgStatus] = useState(0);

  const [openReady, setOpenReady] = useState(false);

  const [dialogRecord, setDialogRecord] = useState<IMonalytInflCampToDo>({
    ...emptyToDoRec,
  });

  const [lstOfWiseTodos, setLstOfWiseTodos] = useState<IMonalytInflCampToDo[]>(
    []
  );
  const [lstAllUsers, setLstAllUsers] = useState<IMonalytUser[]>([]);

  const [baseHiringOps, setBaseHiringOps] =
    useState<IMonalytCampaignInfl | null>(null);

  const onAddOrPreviewTodo = (todo?:IMonalytInflCampToDo) => {
    const oRec: IMonalytInflCampToDo = todo ? {
      ...todo,
      dueDate: new Date(todo.dueDate),
      operDate: new Date(todo.operDate)
    }: {
      ...emptyToDoRec,
      campaignKey: baseHiringOps!.campaignKey,
      companyKey: baseHiringOps!.companyKey,
      createdBy: "",
      hireOperKey: baseHiringOps!.recordKey,
      influencerKey: baseHiringOps!.influencerKey,
      notes: "",
      todoKey: "",
      operDate: new Date(),
      dueDate: new Date(),
      assignedTo: "",
      done: false,
      todoTitle: "",
    };
    setDialogRecord(oRec);
    setDlgStatus(todo ? 1: 0);
    setDlgOpen(true); 
  };

  const populateData = async () => {
    setLoading(true);
    setMsgError("");
    setHasError(false);

    const oOper = await NetServices.requestGet(
      `hiretools/getlisttodos/${props.hiringOperationKey}`
    );

    if (!oOper.bReturn) {
      setMsgError(oOper.msgBody);
      setHasError(true);
      setLoading(false);
      return;
    }
    setLstAllUsers(oOper.lstAllUsers!);
    setLstOfWiseTodos(oOper.lstOfWiseTodos!);
    setBaseHiringOps(oOper.baseHiringOps!);
    setMsgError("");
    setHasError(false);
    setLoading(false);
  };

  const onCloseDialog = (o: any) => {
    if (o) {
      const lst = o as IMonalytInflCampToDo[];
      setLstOfWiseTodos(lst);
    }

    setDlgOpen(false);
  };

  const onToDoDelete = async (k: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "hiretools/deletehiringtodorecord",
      {
        hireToDoKey: k,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setLstOfWiseTodos(oOper.lstOfWiseTodos!);
  };
  const onToDoUpdate = async (k: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "hiretools/updatetodorecord",
      {
        hireToDoKey: k,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setLstOfWiseTodos(oOper.lstOfWiseTodos!);
  };

  const onToDoPreview = async (k: IMonalytInflCampToDo) => {
    const oOper = await NetServices.requestGet(
      `hiretools/getonetodorecord/${k.todoKey}`,true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    onAddOrPreviewTodo(oOper.todoOneRecord!);
  };

  const onOpenDialog = () => {
    if(!baseHiringOps){
      setOpenReady(true);
    }else{
      onAddOrPreviewTodo();
    }
  }

  useImperativeHandle(ref, () => ({
    onOpenDialog
  }));

  useEffect(() => {
    populateData();
  }, []);

  useEffect(() => {
    if(openReady){
      onOpenDialog();
    };    
  }, [baseHiringOps]);

  return (
    <>
      <ModalWiseToDo
        isOpen={dlgOpen}
        dataRecord={dialogRecord}
        dlgTitle={languages.addPhoneLog}
        onClose={onCloseDialog}
        lstAllUsers={lstAllUsers}
        dlgStatus={dlgStatus}
      />
      <div className="flex flex-col">
        {/* Header */}
        <div className="flex items-center space-x-2">
          <WiseCircularActionBtn type="add" onAction={() => onAddOrPreviewTodo()} />
          <span
            onClick={() => onAddOrPreviewTodo()}
            className="text-sm cursor-pointer text-blue-500 hover:text-blue-700"
          >
            {languages.addTodo}
          </span>
        </div>
        {/* Body */}
        <div className="flex flex-col mt-4">
          {loading && <LoadinInterfaceComp />}
          {!loading && !hasError && lstOfWiseTodos.length === 0 && (
            <NoRecordMsgDisplayer
              msg={languages.noTodoPosted}
              onAction={() => onAddOrPreviewTodo()}
              actionTitle={languages.addEvent}
            />
          )}
          {(!loading && hasError) && (
            <ErrorMsgDisplayer
              msg={msgError}
              onAction={() => populateData()}
              actionTitle={languages.refresh}
            />
          )}
          {!loading &&
            !hasError &&
            lstOfWiseTodos.length > 0 &&
            lstOfWiseTodos.map((o, idx) => (
              <WiseToDoElt
                key={idx}
                data={o}
                onDelete={(k) => onToDoDelete(k)}
                onUpdate={(k) => onToDoUpdate(k)}
                onPreview={(k) => onToDoPreview(k)}
              />
            ))}
        </div>
      </div>
    </>
  );
})

export default ListOfWiseTodos;

const WiseToDoElt = ({
  data,
  onDelete,
  onUpdate,
  onPreview
}: {
  data: IMonalytInflCampToDo;
  onDelete: (noteKey: string) => void;
  onUpdate: (noteKey: string) => void;
  onPreview: (todoRec: IMonalytInflCampToDo) => void;
}) => {
  const today = new Date(),
    dueDate = new Date(data.dueDate),
    diff = dueDate.getTime() - today.getTime();

  const status: StatusType = data.done
    ? "done"
    : diff > 0
    ? "pastdue"
    : "upcoming";

  const statusTxt: string = data.done
    ? languages.done
    : diff > 0
    ? languages.pastdue
    : languages.upcoming;

  return (
    <div className="flex items-start mb-3 border-b">
      <div className="mr-3">
        <CalendarViewerComp calDate={new Date(data.operDate)} />
      </div>
      <div className="flex-1 ">
        <div className="tbl-item-text-sub-item">
          <div className="flex items-center space-x-3">
            <div className="mt-0">
              <WiseCheckBox value={data.done} onChangeValue={() => onUpdate(data.todoKey)} />
            </div>
            <div data-status={status} onClick={() => onPreview(data)} className="data-[status=done]:line-through text-blue-400 font-semibold cursor-pointer">{data.todoTitle}</div>
            {!data.done && <span onClick={() => onDelete(data.todoKey)} className="cursor-pointer" title={languages.delete}>
              <i className="fa fa-trash text-red-500"></i>
            </span>}
          </div>
        </div>
        <div className="flex items-center justify-between py-3">
          <div className="flex items-center space-x-3">
            <span className="text-sm">
              {appFxs.formatDateTimeAlt(new Date(data.operDate))}
            </span>
            
          </div>
        </div>
      </div>
      <div>
        <span
          data-status={status}
          className="status task-status text-white data-[status=done]:bg-[#75b52a] data-[status=upcoming]:bg-[#fda91e] data-[status=pastdue]:bg-[#be141f]"
        >
          {statusTxt}
        </span>
      </div>
    </div>
  );
};
