import { useMemo, useState } from "react";

import { Button, Table, TextField } from "@radix-ui/themes";

import geLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import appDecl from "src/utils/declarations";

import fcountries from "src/assets/resources/country_tel_codes.json";
import { ICountyWithCtr } from "src/models/smarttypes";
import { listOfTempoInfluencers } from "src/utils/tempo_data";
import { WisePagination } from "src/components/wpagination/WisePagination";
import { IInfluencer } from "src/models/monalytics_models";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";
import PrintingSystem from "src/utils/printing_system";
import { ICompanyRecord, IMonalytUser } from "src/models/general_models";

const languages = geLang();
const appFxs = generalFxs();

const countries = fcountries as ICountyWithCtr;
const PageSize = Number(appFxs.getLocalStorageByKey(appDecl.itemsPerPage, "9"));

const stringConnUser = appFxs.getLocalStorageByKey(appDecl.connectedUser, '');
const connectedUser:IMonalytUser|null = stringConnUser === '' ? null: JSON.parse(stringConnUser) as IMonalytUser;


const stringConnComp = appFxs.getLocalStorageByKey(appDecl.connectedCompany, '');
const connectedComp:ICompanyRecord|null = stringConnComp===''? null: JSON.parse(stringConnComp) as ICompanyRecord;


const InfluencersPage = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const [filteredDocs, setFilteredDocs] =
    useState<IInfluencer[]>(listOfTempoInfluencers);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return filteredDocs.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, filteredDocs]);

  const onChangeFilterFld = (eTxt: string) => {
    if (!eTxt) {
      setFilteredDocs(listOfTempoInfluencers);
      //setFilterMode(false);
      return;
    }

    //setFilterMode(true);

    const filtered = listOfTempoInfluencers.filter((o) => {
      const ltTxt = eTxt.toLowerCase();
      const bFName = o.firstName.toLowerCase().includes(ltTxt);
      const bLName = o.lastName.toLowerCase().includes(ltTxt);

      return bFName || bLName;
    });

    setFilteredDocs(filtered);
  };

  const printListInfluencers = () => {
    PrintingSystem.PrintListOfInfluencers(connectedUser!, connectedComp!, listOfTempoInfluencers);
  }

  return (
    <div className="p-4">
      <div className="flex justify-end mb-4">
        <TextField.Root
          placeholder={languages.search}
          className="mr-3"
          onChange={(e) => onChangeFilterFld(e.target.value)}
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
        </TextField.Root>

        <Button disabled={listOfTempoInfluencers.length === 0} className="" onClick={() => printListInfluencers()}>
          <i className="fa fa-print"></i>
        </Button>
      </div>

      <div className="mx-auto bg-white border">
        <Table.Root>
          <Table.Header>
            <Table.Row>
              <Table.ColumnHeaderCell>
                <span className="block">{languages.name}</span>
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>{languages.email}</Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell>
                {languages.phoneNbr}
              </Table.ColumnHeaderCell>
              <Table.ColumnHeaderCell className="hidden md:table-cell">
                {languages.country}
              </Table.ColumnHeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {currentTableData.map((o) => (
              <Table.Row key={o.influencerKey}>
                <Table.Cell>
                  <span className="block">
                    {o.firstName + " " + o.lastName}
                  </span>
                  <span className="block md:hidden">
                    <i className="fa fa-flag text-xs"></i>&nbsp;
                    <span className="text-xs">
                      {countries.Countries.find((f) => f.code === o.country)
                        ? countries.Countries.find((f) => f.code === o.country)
                            ?.name
                        : languages.notSpecified}
                    </span>
                  </span>
                </Table.Cell>
                <Table.Cell>
                  <a
                    className="text-blue-500 hover:text-blue-700"
                    href={`mailto:${o.inflEmail}`}
                  >
                    {o.inflEmail}
                  </a>
                </Table.Cell>
                <Table.Cell>
                  <a
                    className="text-blue-500 hover:text-blue-700"
                    href={`tel:${o.mobilePhone}`}
                  >
                    {o.mobilePhone}
                  </a>
                </Table.Cell>
                <Table.Cell className="hidden md:table-cell">
                  {countries.Countries.find((f) => f.code === o.country)
                    ? countries.Countries.find((f) => f.code === o.country)
                        ?.name
                    : languages.notSpecified}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table.Root>

        <div className="flex py-3 justify-end">
          <WisePagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={filteredDocs.length}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </div>
    </div>
  );
};

export default InfluencersPage;
