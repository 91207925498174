import appDecl from "src/utils/declarations";
import appLanguages from "src/utils/languages";
import { ICompanyRecord } from "src/models/general_models";

import company_logo from "src/assets/images/company_logo.png";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { Button } from "@radix-ui/themes";

const languages = appLanguages();

interface ICompaniesLstVign {
  companies: ICompanyRecord[];
  onSelected: (e: ICompanyRecord) => void;
  onGoBack: () => void;
}
interface ICompanyVignette {
  company: ICompanyRecord;
  onSelected: (e: ICompanyRecord) => void;
}

export default function CompaniesLstVign({
  companies,
  onSelected,
  onGoBack,
}: ICompaniesLstVign) {
  return (
    <div className="flex flex-col gap-4">
      <h3 className="py-1 px-2 text-black font-semibold">
        {languages.relatedCompanies}
      </h3>
      <div className="flex flex-wrap gap-3 px-3 py-3">
        {companies.map((o, idx) => (
          <CompanyVignette company={o} key={idx} onSelected={onSelected} />
        ))}
      </div>
      <div className="text-right py-3">
        <Button onClick={onGoBack} className="cursor-pointer">
          <ArrowLeftIcon /> {languages.goBack}
        </Button>
      </div>
    </div>
  );
}

const CompanyVignette = ({ company, onSelected }: ICompanyVignette) => {
  const logo: string =
    !company.companyLogo || company.companyLogo.length === 0
      ? company_logo
      : `${appDecl.companyResourcesPath}/${
          company.companyKey
        }/logos/${company.companyLogo!}`;

  return (
    <div
      className="group flex flex-col overflow-hidden border border-solid border-slate-400 rounded-md w-[120px] cursor-pointer"
      title={company.shortName}
      onClick={() => onSelected(company)}
    >
      <img
        src={logo}
        alt=""
        className="w-full h-[90px] object-cover object-top max-w-full"
      />
      <h3 className="py-2 pl-2 font-bold whitespace-nowrap overflow-hidden text-slate-700 group-hover:text-slate-900">
        {company.companyName}
      </h3>
    </div>
  );
};
