import React, { useState } from 'react'

import generalFxs from 'src/utils/general_fx';
import appLanguages from 'src/utils/languages';
import NetServices from 'src/utils/netservices';

import WiseTextInput from 'src/components/WiseTextInput';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IFormError } from 'src/models/smarttypes';


const appFxs = generalFxs();
const languages = appLanguages();


const AcctJoinTeamPage = () => {
  document.title = `${languages.joinTeam} | Archivix`;
  const [formRecord, setFormRecord] = useState({password:'', confirmPassword:''});
  const [serverInUse, setServerInUse] = useState(false);
  const [formErrors, setFormErrors] = useState<IFormError>({});

  const {joinKey} = useParams();
  const navigate = useNavigate();

  

  
  const updateFormData = (fld: string, val: string) => {
    setFormRecord({...formRecord, [fld]: val});
  }

  const onJoinTeam = async () => {
    const errors:IFormError =  {};

    if(!formRecord.password || formRecord.password.length === 0){
      errors.password = languages.fldRequired;
    }else if(appFxs.passwordStrengthCheck(formRecord.password) < 70){
      errors.password = languages.passValidMsg;
    }

    if(!formRecord.confirmPassword || formRecord.confirmPassword.length === 0){
      errors.confirmPassword = languages.fldRequired;
    }else if(formRecord.confirmPassword !== formRecord.password){
      errors.confirmPassword = languages.confirmPaswordError;
    }
    
    setFormErrors(errors);
    if(Object.keys(errors).length > 0) return;



    const bAsk = await appFxs.showConfirm(languages.joinTeam, languages.joinTeamMsg);
    if(!bAsk.isConfirmed)return;

    
    setServerInUse(true);

    const oOper = await NetServices.requestPost('account/jointeamwithaccount', {
      joinAccountKey: joinKey,
      password: formRecord.password,
      confirmPassword: formRecord.confirmPassword,
    });


    if(!oOper.bReturn){
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setServerInUse(false);
      return;
    }

    navigate('/account/login');
  }


  
  return (
    <div className="w-full max-w-md shadow-lg py-3 px-5 bg-white rounded-md">
      <div className="w-full flex flex-col space-y-6">
      <h3 className="font-bold tracking-wide text-3xl mt-4 w-text-primary">
        {languages.joinTeam}
      </h3><strong className="block mt-4 mb-10">
        {languages.haveAcct}&nbsp;
        <Link
          className="font-bold text-blue-600 hover:text-blue-800"
          to={"/account/login"}
        >
          {languages.logInNow}
        </Link>
      </strong>


      <div className="flex flex-col space-y-6">
            <WiseTextInput
              fldName="password"
              fldType="password"
              label={languages.password}
              placeholder={languages.typePwHere.toLowerCase()}
              graphicType="lock"
              value={formRecord.password}
              onChange={updateFormData}
              errorText={formErrors.password}
              tabIndex={0}
              disabled={serverInUse}
              checkPassword={true}
            />

            <WiseTextInput
              fldName="confirmPassword"
              fldType="password"
              label={languages.confirmPassword}
              placeholder={languages.confPwHere.toLowerCase()}
              graphicType="lock"
              value={formRecord.confirmPassword}
              errorText={formErrors.confirmPassword}
              onChange={updateFormData}
              tabIndex={1}
              disabled={serverInUse}
            />            
          </div>


          <div className="mt-6">
        <button
          className={`btn btn-sm text-white px-[1.2rem] rounded-lg bg-green-400 hover:bg-green-700 disabled:bg-gray-400 disabled:text-gray-200 disabled:cursor-not-allowed`}
          onClick={() => onJoinTeam()}
          disabled={serverInUse}
        >
          <span className="flex flex-row items-center gap-2">
            {serverInUse && <i className="fa fa-spin fa-spinner"></i>}
            <span>
              {serverInUse && <span>{`${languages.loading}...`}</span>}
              {!serverInUse && (
                <span>{languages.join}</span>
              )}
            </span>
          </span>
        </button>
      </div>
    </div>
    </div>

  )
}

export default AcctJoinTeamPage