/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, Heading } from "@radix-ui/themes";

import generalFxs from "src/utils/general_fx";
import appLanguages from "src/utils/languages";
import NetServices from "src/utils/netservices";
import WiseTextInput from "src/components/WiseTextInput";
import CompaniesLstVign from "src/components/CompaniesLstVign";
import { ICompanyRecord } from "src/models/general_models";
import { IFormError } from "src/models/smarttypes";

const appFxs = generalFxs();
const languages = appLanguages();

const AcctForgotPw = () => {
  document.title = `${languages.resetPassword} | StockMan`;

  const { forgotPwEmail } = useParams();

  const userEmail = window.atob(forgotPwEmail!);

  const [formEmail, setFormEmail] = useState(userEmail);
  const [formErrors, setFormErrors] = useState<IFormError>({});
  const [serverInUse, setServerInUse] = useState(false);
  const [pageStep, setPageStep] = useState(0);

  const [lstCompanies, setlstCompanies] = useState<ICompanyRecord[]>([]);

  const onCheckReset = async () => {
    const errors: IFormError = {};
    if (!formEmail || formEmail.length === 0) {
      errors.formEmail = languages.fldRequired;
    } else if (!appFxs.isEmailValid(formEmail)) {
      errors.formEmail = languages.emailFormat;
    }

    setFormErrors(errors);
    if (Object.keys(errors).length > 0) return;

    setServerInUse(true);
    const oOper = await NetServices.requestPost(
      "account/requestresetpasswortstart",
      {
        userEmail: formEmail,
      }
    );

    if (!oOper.bReturn) {
      setFormErrors({ ...formErrors, formEmail: oOper.msgBody });
      setServerInUse(false);
      return;
    }

    setPageStep(oOper.isMultiple ? 1 : 2);
    setlstCompanies(oOper.isMultiple ? oOper.userCompanies! : []);
    setServerInUse(false);
  };

  const onSelectedDomain = async (e: ICompanyRecord) => {
    setServerInUse(true);
    const oOper = await NetServices.requestPost(
      "account/requestresetpasswortemailcomp",
      {
        userEmail: formEmail,
        clientDomainKey: e.companyKey,
      }
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      setServerInUse(false);
      return;
    }

    setPageStep(2);
    setlstCompanies([]);
    setServerInUse(false);
  };

  return (
    <div className="w-full max-w-md shadow-lg py-3 px-5 bg-white rounded-md">
      <div className="w-full flex flex-col space-y-6">
        <Heading className="text-center text-base py-3" size="1">
          {languages.resetPassword}
        </Heading>
        {pageStep === 0 && (
          <p className="text-sm">{languages.resetPwMessage}</p>
        )}

        {pageStep === 0 && (
          <WiseTextInput
            fldName="email"
            fldType="email"
            label={languages.emailAddress}
            placeholder={languages.yourEmailHere}
            graphicType="email"
            value={formEmail}
            onChange={(f, v) => setFormEmail(v)}
            errorText={formErrors.formEmail}
            disabled={true}
          />
        )}

        {pageStep === 0 && (
          <div className="mt-6">
            <Button
              size="3"
              variant="soft"
              onClick={() => onCheckReset()}
              className="cursor-pointer w-full flex items-center"
              disabled={serverInUse}
            >
              {serverInUse && <i className="fa fa-spin fa-spinner"></i>}
              <span className="text-sm font-semibold">
                {languages.resetPassword}
              </span>
            </Button>
          </div>
        )}

        {/* 1- Domains */}
        {pageStep === 1 && (
          <div className="relative">
            {serverInUse && (
              <div className="absolute top-0 left-0 w-full h-full bg-black/30 z-10"></div>
            )}
            {serverInUse && (
              <div className="absolute top-0 left-0 w-full h-full bg-black/30 z-10 flex justify-center items-center">
                <i className="fa fa-spinner fa-spin fa-3x text-white"></i>
              </div>
            )}
            <CompaniesLstVign
              companies={lstCompanies}
              onSelected={(e) => onSelectedDomain(e)}
              onGoBack={() => setPageStep(0)}
            />
          </div>
        )}

        {/* 2- Message */}
        {pageStep === 2 && (
          <div className="flex flex-col gap-4">
            <p className="w-full">{languages.resetReqFinalMsg}</p>
            <div className="w-fit px-3 py-2 border border-solid border-[#9e9e9e] font-semibold bg-[#f1f1f1] text-black text-center rounded-md">
              {formEmail}
            </div>
          </div>
        )}

        {!serverInUse && (
          <div className="flex justify-end items-center">
            <Link
              className="text-blue-600 hover:text-blue-800 mb-6"
              to={"/account/login"}
            >
              {languages.signInAnyway}
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default AcctForgotPw;
