import getLang from 'src/utils/languages';
import WiseTextInput from './WiseTextInput';
import { IAcctFormRegister, ICountyWithCtr, IFormError } from 'src/models/smarttypes';
import fcountries from 'src/assets/resources/country_tel_codes.json';
import generalFxs from 'src/utils/general_fx';
import { AnchorLink, LinkRouter } from './small_comps';

const languages = getLang();
const countries = fcountries as ICountyWithCtr;

const appFxs = generalFxs();

const isEn = appFxs.getLocalLanguage() === 'en';

export const RegisterEmailForm = (
    { value, applyVal, errorMsg, disabled }:
      {
        disabled?: boolean,
        errorMsg?: string,
        value: string,
        applyVal: (f: string, v: string) => void
      }) => {
    return (
      <div className='mb-6'>
        <WiseTextInput fldName='adminEmail'
          fldType='email'
          placeholder={languages.email}
          value={value}
          onChange={applyVal}
          errorText={errorMsg}
          disabled={disabled ?? false}
          graphicType='email'
        />
      </div>
    );
  }
  
  export const ConfirmRegisterCodeForm = ({ formRecord, updateFormData, formErrors, serverInUse }: {
    formErrors: IFormError,
    formRecord: IAcctFormRegister,
    serverInUse: boolean,
    updateFormData: (fldName: string, fldValue: string) => void,
  }) => {
    return (
      <div className="flex flex-col gap-3">
        <h3 className="text-lg font-semibold">
          {languages.confirmRegistercodes}
        </h3>
        <div>
          {languages.codeBeenSent} ({formRecord.adminEmail})
        </div>
  
        <div className="flex flex-row flex-wrap md:justify-between mb-6">
          <div className="w-full md:w-[49%]">
            <WiseTextInput
              fldName="registerCode1"
              fldType="text"
              label={`${languages.code} 1`}
              placeholder="000"
              graphicType="key"
              value={formRecord.registerCode1}
              onChange={updateFormData}
              errorText={formErrors.registerCode1}
              maxLength={3}
              isNumeric={true}
              moveToNext={true}
              tabIndex={1}
              disabled={serverInUse}
            />
          </div>
          <div className="w-full md:w-[49%]">
            <WiseTextInput
              fldName="registerCode2"
              fldType="text"
              label={`${languages.code} 2`}
              placeholder="000"
              graphicType="key"
              value={formRecord.registerCode2}
              onChange={updateFormData}
              errorText={formErrors.registerCode2}
              maxLength={3}
              isNumeric={true}
              moveToNext={true}
              tabIndex={2}
              disabled={serverInUse}
            />
          </div>
        </div>
      </div>
  
    );
  }
  
  export const CompanyRecordForm = ({ formRecord, updateFormData, formErrors, serverInUse }: {
    formErrors: IFormError,
    formRecord: IAcctFormRegister,
    serverInUse: boolean,
    updateFormData: (fldName: string, fldValue: string) => void,
  }) => {
    return (
      <div className="flex flex-col space-y-6 mb-6">
        <h3 className="text-base font-semibold">
          {languages.companyDetails}
        </h3>
        <WiseTextInput
          fldName="companyEmail"
          fldType="email"
          label={languages.email}
          placeholder={languages.companyEmailHere.toLowerCase()}
          graphicType="email"
          value={formRecord.companyEmail}
          onChange={updateFormData}
          errorText={formErrors.companyEmail}
          tabIndex={3}
          disabled={serverInUse}
        />
  
        <WiseTextInput
          fldName="companyName"
          fldType="text"
          label={languages.companyName}
          placeholder={languages.companyNameHere.toLowerCase()}
          graphicType="office"
          value={formRecord.companyName}
          onChange={updateFormData}
          errorText={formErrors.companyName}
          tabIndex={4}
          disabled={serverInUse}
        />
  
        <WiseTextInput
          fldName="shortName"
          fldType="text"
          label={languages.shortName}
          placeholder={languages.shortNameEntr.toLowerCase()}
          graphicType="office"
          value={formRecord.shortName}
          onChange={updateFormData}
          errorText={formErrors.shortName}
          tabIndex={5}
          disabled={serverInUse}
        />
      </div>
  
    );
  }
  
  export const AdminRecordForm = ({ formRecord, updateFormData, formErrors, serverInUse }: {
    formErrors: IFormError,
    formRecord: IAcctFormRegister,
    serverInUse: boolean,
    updateFormData: (fldName: string, fldValue: string) => void,
  }) => {
    return (
      <div className="flex flex-col gap-6 mb-6">
        <h3 className="text-lg font-semibold">
          {languages.adminUserDetails}
        </h3>
        <WiseTextInput
          fldName="firstName"
          fldType="text"
          label={languages.firstName}
          placeholder={languages.typeFirstName.toLowerCase()}
          graphicType="person"
          value={formRecord.firstName}
          onChange={updateFormData}
          errorText={formErrors.firstName}
          tabIndex={6}
          disabled={serverInUse}
        />
  
        <WiseTextInput
          fldName="lastName"
          fldType="text"
          label={languages.lastName}
          placeholder={languages.typeLastName.toLowerCase()}
          graphicType="person"
          value={formRecord.lastName}
          onChange={updateFormData}
          errorText={formErrors.lastName}
          tabIndex={7}
        />
  
        <WiseTextInput
          fldName="displayName"
          fldType="text"
          label={languages.displayName}
          placeholder={languages.typeDisplNameFac.toLowerCase()}
          graphicType="person"
          value={formRecord.displayName}
          onChange={updateFormData}
          errorText={formErrors.displayName}
          tabIndex={8}
          disabled={serverInUse}
        />
      </div>
  
    );
  }
  
  export const PasswordRecordForm = ({ formRecord, updateFormData, formErrors, serverInUse }: {
    formErrors: IFormError,
    formRecord: IAcctFormRegister,
    serverInUse: boolean,
    updateFormData: (fldName: string, fldValue: string) => void,
  }) => {
    return (
      <div className="flex flex-col gap-y-6 mb-6">
        <h3 className="text-lg font-semibold">{languages.password}</h3>
        <WiseTextInput
          fldName="password"
          fldType="password"
          label={languages.password}
          placeholder={languages.typePwHere.toLowerCase()}
          graphicType="lock"
          value={formRecord.password}
          onChange={updateFormData}
          errorText={formErrors.password}
          tabIndex={9}
          disabled={serverInUse}
          checkPassword={true}
        />
  
        <WiseTextInput
          fldName="confirmPassword"
          fldType="password"
          label={languages.confirmPassword}
          placeholder={languages.confPwHere.toLowerCase()}
          graphicType="lock"
          value={formRecord.confirmPassword}
          errorText={formErrors.confirmPassword}
          onChange={updateFormData}
          tabIndex={10}
          disabled={serverInUse}
        />
      </div>
    )
  
  }
  
  export const CompanyAddressRecordForm = ({ formRecord, updateFormData, formErrors, serverInUse }: {
    formErrors: IFormError,
    formRecord: IAcctFormRegister,
    serverInUse: boolean,
    updateFormData: (fldName: string, fldValue: string) => void,
  }) => {
    return (
      <div className="flex flex-col space-y-3 mb-6">
        <h3 className="text-base font-semibold">
          {languages.address}
        </h3>
  
        <WiseTextInput
          fldName="country"
          fldType="select"
          label={languages.country}
          graphicType="office"
          value={formRecord.country}
          onChange={updateFormData}
          errorText={formErrors.country}
          disabled={serverInUse}
          selectOptions={countries.Countries.map(o => {
            return { label: o.name, value: o.code }
          })}
        />
  
        <WiseTextInput
          fldName="city"
          fldType="text"
          label={languages.city}
          placeholder={languages.city.toLowerCase()}
          graphicType="office"
          value={formRecord.city}
          onChange={updateFormData}
          errorText={formErrors.city}
          disabled={serverInUse}
        />
  
        <WiseTextInput
          fldName="poBox"
          fldType="text"
          label={languages.poBox}
          placeholder={languages.city.toLowerCase()}
          graphicType="office"
          value={formRecord.poBox}
          onChange={updateFormData}
          errorText={formErrors.poBox}
          disabled={serverInUse}
          isNumeric={true}        
        />
      </div>
    );
  }
  
  export const ConfirmRegistrationDataForm = ({ formRecord, updateFormData, formErrors, serverInUse }: {
    formErrors: IFormError,
    formRecord: IAcctFormRegister,
    serverInUse: boolean,
    updateFormData: (fldName: string, fldValue: string) => void,
  }) => {
    return (
      <div className="flex flex-col space-y-3 mb-6">
              <div className="py-4 px-2 border border-solid border-emerald-300 rounded-lg ">
                {isEn && (
                  <div className="font-semibold">
                    By clicking <strong>Register</strong>, you accept and comply
                    with&nbsp;
                    <a
                      className="text-blue-600 hover:text-blue-900 font-bold"
                      target="_blank"
                      href="https://archivix.net/legal/terms"
                      rel="noreferrer"
                    >
                      Terms
                    </a>
                    &nbsp; of &nbsp;
                    <a
                      className="text-blue-600 hover:text-blue-900 font-bold"
                      target="_blank"
                      href="https://archivix.net"
                      rel="noreferrer"
                    >
                      Archivix
                    </a>
                    .
                  </div>
                )}
                {!isEn && (
                  <div>
                    Ecliquant sur <strong>S'inscrire</strong>, vous acceptez de
                    vous conformer aux
                    <a
                      className="text-blue-600 hover:text-blue-900 font-bold"
                      target="_blank"
                      href="https://archivix.net/legal/terms"
                      rel="noreferrer"
                    >
                      Conditions d'utilisation
                    </a>&nbsp;
                    <a
                      className="text-blue-600 hover:text-blue-900 font-bold"
                      target="_blank"
                      href="https://archivix.net"
                      rel="noreferrer"
                    >
                      Archivix
                    </a>
                    .
                  </div>
                )}
              </div>
            </div>
  
    );
  }
  
  
  export const HeaderFormComp = () => {
    return (
      <div className='flex flex-col space-y-3'>
        <strong>{languages.haveAcct}&nbsp;<LinkRouter href='/account/login' label={languages.signIn} /></strong>
        <div className='text-sm'>
          {isEn && <p>
            You agree to comply with the WiseManager <AnchorLink href='https://archivix.net/legal/privacy' label='Privacy Policy' />  and by
            registering you agree that you have sufficient rights to
            represent your business.
          </p>}
          {!isEn && <p>
            Vous acceptez de vous conformer à la <AnchorLink href='https://archivix.net/legal/privacy' label='Politique de confidentialité' />
            de WiseManager et en vous inscrivant, vous acceptez que vous disposez de droits suffisants pour représenter votre entreprise.
          </p>}
        </div>
      </div>
    );
  }