/* eslint-disable react-hooks/exhaustive-deps */

import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from "react";

import geLang from "src/utils/languages";
import generalFxs from "src/utils/general_fx";
import {
  CalendarViewerComp,
  ErrorMsgDisplayer,
  NoRecordMsgDisplayer,
  SmallButtonRounded,
  WiseCircularActionBtn,
} from "../small_comps";
import {
  IMonalytCampaignInfl,
  IMonalytInflCampPhoneCall,
} from "src/models/monalytics_models";
import NetServices from "src/utils/netservices";
import LoadinInterfaceComp from "../LoadinInterfaceComp";
import { ModalWisePhoneCall } from "../modal_hiring_tools";
import { IHiringActiActionsRef, IHiringCompVals } from "src/models/smarttypes";

const languages = geLang();
const appFxs = generalFxs();

const emptyPhoneCallRec: IMonalytInflCampPhoneCall = {
  phoneCallKey: "",
  companyKey: "",
  createdBy: "",
  hireOperKey: "",
  influencerKey: "",
  callDetails: "",
  operDate: new Date(),
  callDate: new Date(),
  campaignKey: "",
};

const ListOfWisePhoneCalls = forwardRef((
  props: IHiringCompVals,
  ref: Ref<IHiringActiActionsRef>
) => {
  const [dlgOpen, setDlgOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState("");

  const [openReady, setOpenReady] = useState(false);

  const [dialogRecord, setDialogRecord] = useState<IMonalytInflCampPhoneCall>({
    ...emptyPhoneCallRec,
  });

  const [lstOfWisePhoneCalls, setLstOfWisePhoneCalls] = useState<
    IMonalytInflCampPhoneCall[]
  >([]);
  const [baseHiringOps, setBaseHiringOps] =
    useState<IMonalytCampaignInfl | null>(null);

  const onAddNewPhoneCall = () => {
    const oRec: IMonalytInflCampPhoneCall = {
      ...emptyPhoneCallRec,
      campaignKey: baseHiringOps!.campaignKey,
      companyKey: baseHiringOps!.companyKey,
      createdBy: "",
      hireOperKey: baseHiringOps!.recordKey,
      influencerKey: baseHiringOps!.influencerKey,
      callDetails: "",
      phoneCallKey: "",
      operDate: new Date(),
      callDate: new Date(),
    };
    setDialogRecord(oRec);
    setDlgOpen(true);
  };
  const populateData = async () => {
    setLoading(true);
    setMsgError("");
    setHasError(false);

    const oOper = await NetServices.requestGet(
      `hiretools/getlistcalls/${props.hiringOperationKey}`
    );

    if (!oOper.bReturn) {
      setMsgError(oOper.msgBody);
      setHasError(true);
      setLoading(false);
      return;
    }
    setLstOfWisePhoneCalls(oOper.lstOfWisePhoneCalls!);
    setBaseHiringOps(oOper.baseHiringOps!);
    setMsgError("");
    setHasError(false);
    setLoading(false);
  };

  const onCloseDialog = (o: any) => {
    if (o) {
      const lst = o as IMonalytInflCampPhoneCall[];
      setLstOfWisePhoneCalls(lst);
    }

    setDlgOpen(false);
  };

  const onPhoneCallDelete = async (k: string) => {
    const bAsk = await appFxs.showConfirm(
      languages.delete,
      languages.deleteRecordQst
    );
    if (!bAsk.isConfirmed) return;

    const oOper = await NetServices.requestPost(
      "hiretools/deletehiringphonecall",
      {
        hirePhoneCallKey: k,
      },
      true
    );

    if (!oOper.bReturn) {
      appFxs.showAlert(oOper.msgTitle, oOper.msgBody);
      return;
    }

    setLstOfWisePhoneCalls(oOper.lstOfWisePhoneCalls!);
  };

  const onOpenDialog = () => {
    if(!baseHiringOps){
      setOpenReady(true);
    }else{
      onAddNewPhoneCall();
    }
  }

  useImperativeHandle(ref, () => ({
    onOpenDialog
  }));

  useEffect(() => {
    populateData();
  }, []);

  useEffect(() => {
    if(openReady){
      onOpenDialog();
    };    
  }, [baseHiringOps]);

  return (
    <>
      <ModalWisePhoneCall
        isOpen={dlgOpen}
        dataRecord={dialogRecord}
        dlgTitle={languages.addPhoneLog}
        onClose={onCloseDialog}
      />
      <div className="flex flex-col">
        {/* Header */}
        <div className="flex items-center space-x-2">
          <WiseCircularActionBtn type="add" onAction={onAddNewPhoneCall} />
          <span
            onClick={onAddNewPhoneCall}
            className="text-sm cursor-pointer text-blue-500 hover:text-blue-700"
          >
            {languages.addPhoneLog}
          </span>
        </div>
        {/* Body */}
        <div className="flex flex-col mt-4">
          {loading && <LoadinInterfaceComp />}
          {!loading && !hasError && lstOfWisePhoneCalls.length === 0 && (
            <NoRecordMsgDisplayer
              msg={languages.noCallPosted}
              onAction={onAddNewPhoneCall}
              actionTitle={languages.addNewPhoneLog}
            />
          )}
          {(!loading && hasError) && (
            <ErrorMsgDisplayer
              msg={msgError}
              onAction={populateData}
              actionTitle={languages.refresh}
              btnIcon={<i className="fa fa-refresh"></i>}
            />
          )}
          {!loading &&
            !hasError &&
            lstOfWisePhoneCalls.length > 0 &&
            lstOfWisePhoneCalls.map((o, idx) => (
              <WisePhoneCallElt
                key={idx}
                data={o}
                onDelete={(k) => onPhoneCallDelete(k)}
              />
            ))}
        </div>
      </div>
    </>
  );
})

export default ListOfWisePhoneCalls;

const WisePhoneCallElt = ({
  data,
  onDelete,
}: {
  data: IMonalytInflCampPhoneCall;
  onDelete: (noteKey: string) => void;
}) => {
  return (
    <div className="flex items-start mb-3 border-b">
      <div className="mr-3">
        <CalendarViewerComp calDate={new Date(data.callDate)} />
      </div>
      <div className="flex-1 ">
        <div className="tbl-item-text-sub-item">
          <div>{data.callDetails}</div>
        </div>
        <div className="flex items-center justify-between py-3">
          <div className="text-sm">
            {appFxs.formatDateTimeAlt(new Date(data.operDate))}
          </div>
          <div>
            <SmallButtonRounded
              label={languages.delete}
              destructive={true}
              onAction={() => onDelete(data.phoneCallKey)}
              child={<i className="fa fa-trash"></i>}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
